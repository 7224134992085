export const breakpoints = (slidesPerView: number) => {
  return {
    576: {
      slidesPerView: 1,
    },
    768: {
      slidesPerView: 2,
    },
    992: {
      slidesPerView: 3,
    },
    1200: {
      slidesPerView: slidesPerView,
    },
    1400: {
      slidesPerView: slidesPerView,
    },
  };
};
