import React, { createContext, useContext, useState } from "react";
import OpportunityGammaModel from "../models/OpportunityGammaModel";

const OpportunityContext = createContext<any>({});
const OpportunityItemContext = createContext<any>({});


export const ThemeProvider = ({children}: any) => {
    const [opportunittyByGamma, setOpporunittyByGamma] = useState<any>();
 
    return (
        <OpportunityContext.Provider value={{opportunittyByGamma, setOpporunittyByGamma}}>
            {children}
        </OpportunityContext.Provider>
    );
}

export {
    OpportunityContext,    
}