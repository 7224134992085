import { ChangeEvent, useEffect, useState } from "react";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Container, Row, Col, Button, Form, FormCheck } from "react-bootstrap";
import jsonToFormData from "@ajoelp/json-to-formdata";
import { Link } from "react-router-dom";
import { useTranslation, Trans } from "react-i18next";

import { SpontaneousApplicationFormModel } from "../../models/SpontaneousApplicationFormModel";
import { TypeModel } from "../../models/TypeModel";

import SpontaneousApplicationFormSchema from "../../schemas/SpontaneousApplicationFormSchema";

import {
  NameSpace,
  spontaneousForm,
  contactForm,
  privacyPolicy,
  buttons,
} from "../../data/AppLanguage";

import Util from "../../utils/Util";

import RecruitmentService from "../../services/api/Recruitment";

import PhoneNumberForm from "../PhoneNumberForm/PhoneNumberForm";
import SpinnerAnimation from "../SpinnerAnimation/SpinnerAnimation";

interface Props {
  className?: string;
}

const SpontaneousApplicationForm = (props: Props) => {
  const [submitClicked, setSubmitClicked] = useState(0);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [jobApplicationAreasOfInterest, setJobApplicationAreasOfInterest] =
    useState<TypeModel[]>([]);
  const [jobApplicationDegrees, setJobApplicationDegrees] = useState<
    TypeModel[]
  >([]);
  const [currentFile, setCurrentFile] = useState(null);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    control,
  } = useForm<SpontaneousApplicationFormModel>({
    resolver: yupResolver(
      SpontaneousApplicationFormSchema(
        jobApplicationAreasOfInterest,
        jobApplicationDegrees
      )
    ),
  });

  const { t, i18n } = useTranslation([
    NameSpace.COMMON,
    NameSpace.GLOSSARY,
    NameSpace.ROUTES,
  ]);
  const language = Util.splitLanguage(i18n.language, 0);

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      RecruitmentService.getJobApplicationAreasOfInterest().then((res) => {
        setJobApplicationAreasOfInterest(res.data);
      });

      RecruitmentService.getJobApplicationDegrees().then((res) => {
        setJobApplicationDegrees(res.data);
      });

      if (formSubmitted) {
        setTimeout(() => {
          setFormSubmitted(false);
          setSubmitClicked(0);
          reset({
            phoneNumber: Util.getCountryCode(
              Util.splitLanguage(i18n.language, 1).toLowerCase()
            ),
          });
        }, 1500);
      }
    }

    return () => {
      mounted = false;
    };
  }, [formSubmitted, i18n.language, reset]);

  const onSubmit: SubmitHandler<SpontaneousApplicationFormModel> = (data) => {
    setSubmitting(true);
    data.phoneNumber = `+${data.phoneNumber}`;

    RecruitmentService.postSpontaneousApplication(setFormData(data))
      .then(() => {
        setSubmitting(false);
        setFormSubmitted(true);
      })
      .catch(() => {
        setSubmitting(false);
      });
  };

  const setFormData = (data: SpontaneousApplicationFormModel) => {
    var formData = new FormData();

    jsonToFormData(data, {}, formData);

    if (data.curriculumVitae)
      formData.append("curriculumVitae", data.curriculumVitae[0]);

    return formData;
  };

  const handleFile = (event: any) => {
    if (event.target.files[0]) setCurrentFile(event.target.files[0].name);
    else setCurrentFile(null);
  };

  return (
    <article className={`contact-form pt-5 pb-10 ${props.className}`}>
      <Container className="contact-form-wrapper">
        <Row>
          <Col>
            <h2 className="contact-form-wrapper-title">
              <Trans
                i18nKey={`${spontaneousForm}title`}
                ns={NameSpace.CONTACTFORMS}
              >
                Preencha a sua <br /> Candidatura Espontânea.
              </Trans>
            </h2>
          </Col>
        </Row>
        <Form noValidate onSubmit={handleSubmit(onSubmit)}>
          <Row className="mt-4">
            <Col xs={12} md={6}>
              <Form.Row>
                <Form.Group as={Col} controlId="nameInput" xs={12} md={11}>
                  <Form.Label>
                    {t(`${contactForm}name`, { ns: NameSpace.COMMON })}*
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder={t(`${contactForm}name`, {
                      ns: NameSpace.COMMON,
                    })}
                    isValid={submitClicked > 0 && !errors.name}
                    isInvalid={!!errors.name}
                    {...register("name")}
                  />
                  {errors.name && (
                    <Form.Control.Feedback type="invalid">
                      {errors.name.message}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} controlId="emailInput" xs={12} md={11}>
                  <Form.Label>
                    {t(`${contactForm}email`, { ns: NameSpace.COMMON })}*
                  </Form.Label>
                  <Form.Control
                    type="email"
                    placeholder={t(`${contactForm}email`, {
                      ns: NameSpace.COMMON,
                    })}
                    isValid={submitClicked > 0 && !errors.email}
                    isInvalid={!!errors.email}
                    {...register("email")}
                  />
                  {errors.email && (
                    <Form.Control.Feedback type="invalid">
                      {errors.email.message}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group
                  as={Col}
                  controlId="phoneNumberInput"
                  xs={12}
                  md={11}
                >
                  <Form.Label>
                    {t(`${contactForm}phoneNumber`, { ns: NameSpace.COMMON })}*
                  </Form.Label>
                  <PhoneNumberForm
                    control={control}
                    submitClicked={submitClicked}
                    errors={errors.phoneNumber}
                    errorsMessage={errors.phoneNumber?.message}
                  />
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} controlId="locationInput" xs={12} md={11}>
                  <Form.Label>
                    {t(`${contactForm}location`, { ns: NameSpace.COMMON })}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder={t(`${contactForm}location`, {
                      ns: NameSpace.COMMON,
                    })}
                    isValid={submitClicked > 0 && !errors.location}
                    isInvalid={!!errors.location}
                    {...register("location")}
                  />
                  {errors.location && (
                    <Form.Control.Feedback type="invalid">
                      {errors.location.message}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group
                  as={Col}
                  controlId="dateOfBirthInput"
                  xs={12}
                  md={11}
                >
                  <Form.Label>
                    {t(`${contactForm}dateOfBirth`, { ns: NameSpace.COMMON })}*
                  </Form.Label>
                  <Form.Control
                    type="date"
                    placeholder={t(`${contactForm}dateOfBirth`, {
                      ns: NameSpace.COMMON,
                    })}
                    isValid={submitClicked > 0 && !errors.dateOfBirth}
                    isInvalid={!!errors.dateOfBirth}
                    {...register("dateOfBirth")}
                  />
                  {errors.dateOfBirth && (
                    <Form.Control.Feedback type="invalid">
                      {errors.dateOfBirth.message}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group
                  as={Col}
                  controlId="jobApplicationAreaIdInput"
                  xs={12}
                  md={11}
                >
                  <Form.Label>
                    {t(`${contactForm}jobApplicationArea`, {
                      ns: NameSpace.COMMON,
                    })}
                    *
                  </Form.Label>
                  <Form.Control
                    as="select"
                    defaultValue=""
                    isValid={submitClicked > 0 && !errors.jobApplicationAreaId}
                    isInvalid={!!errors.jobApplicationAreaId}
                    {...register("jobApplicationAreaId")}
                  >
                    <option value="" disabled>
                      {t(`${contactForm}jobApplicationArea`, {
                        ns: NameSpace.COMMON,
                      })}
                    </option>
                    {jobApplicationAreasOfInterest.map((item, index) => {
                      return (
                        <option key={index} value={item.id}>
                          {item.name}
                        </option>
                      );
                    })}
                  </Form.Control>
                  {errors.jobApplicationAreaId && (
                    <Form.Control.Feedback type="invalid">
                      {errors.jobApplicationAreaId.message}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group
                  as={Col}
                  controlId="jobApplicationDegreeIdInput"
                  xs={12}
                  md={11}
                >
                  <Form.Label>
                    {t(`${contactForm}jobApplicationDegree`, {
                      ns: NameSpace.COMMON,
                    })}
                    *
                  </Form.Label>
                  <Form.Control
                    as="select"
                    defaultValue=""
                    isValid={
                      submitClicked > 0 && !errors.jobApplicationDegreeId
                    }
                    isInvalid={!!errors.jobApplicationDegreeId}
                    {...register("jobApplicationDegreeId")}
                  >
                    <option value="" disabled>
                      {t(`${contactForm}jobApplicationDegree`, {
                        ns: NameSpace.COMMON,
                      })}
                    </option>
                    {jobApplicationDegrees.map((item, index) => {
                      return (
                        <option key={index} value={item.id}>
                          {item.name}
                        </option>
                      );
                    })}
                  </Form.Control>
                  {errors.jobApplicationDegreeId && (
                    <Form.Control.Feedback type="invalid">
                      {errors.jobApplicationDegreeId.message}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} controlId="educationInput" xs={12} md={11}>
                  <Form.Label>
                    {t(`${contactForm}education`, { ns: NameSpace.COMMON })}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder={t(`${contactForm}education`, {
                      ns: NameSpace.COMMON,
                    })}
                    isValid={submitClicked > 0 && !errors.education}
                    isInvalid={!!errors.education}
                    {...register("education")}
                  />
                  {errors.education && (
                    <Form.Control.Feedback type="invalid">
                      {errors.education.message}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Form.Row>
            </Col>
            <Col xs={12} md={6}>
              <Controller
                control={control}
                name="curriculumVitae"
                render={({ field: { onChange, onBlur, name, ref } }) => (
                  <Form.Row>
                    <Form.Group
                      as={Col}
                      controlId="curriculumVitaeInput"
                      xs={12}
                      md={11}
                    >
                      <Form.Label>
                        {t(`${contactForm}curriculumVitae`, {
                          ns: NameSpace.COMMON,
                        })}
                        *
                      </Form.Label>
                      <div className="custom-file">
                        <Form.Control
                          type="file"
                          accept="application/pdf"
                          name={name}
                          isValid={submitClicked > 0 && !errors.curriculumVitae}
                          isInvalid={!!errors.curriculumVitae}
                          className="custom-file-input"
                          onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            onChange(e.target.files);
                            handleFile(e);
                          }}
                          onBlur={onBlur}
                          ref={ref}
                        />
                        <Form.Label className="custom-file-label">
                          {!currentFile
                            ? t(`${contactForm}selectFile`, {
                                ns: NameSpace.COMMON,
                              })
                            : currentFile}
                        </Form.Label>
                        {errors.curriculumVitae && (
                          <Form.Control.Feedback type="invalid">
                            {errors.curriculumVitae.message}
                          </Form.Control.Feedback>
                        )}
                      </div>
                    </Form.Group>
                  </Form.Row>
                )}
              />
              <Form.Row className="d-flex justify-content-end">
                <Form.Group as={Col} controlId="messageInput">
                  <Form.Label>
                    {t(`${contactForm}message`, { ns: NameSpace.COMMON })}*
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    placeholder={t(`${contactForm}message`, {
                      ns: NameSpace.COMMON,
                    })}
                    rows={21}
                    isValid={submitClicked > 0 && !errors.message}
                    isInvalid={!!errors.message}
                    {...register("message")}
                  />
                  {errors.message && (
                    <Form.Control.Feedback type="invalid">
                      {errors.message.message}
                    </Form.Control.Feedback>
                  )}
                  <small className="contact-form-wrapper-required">
                    {t(`${contactForm}required`, { ns: NameSpace.COMMON })}
                  </small>
                </Form.Group>
              </Form.Row>
            </Col>
          </Row>
          <Form.Row className="align-items-center justify-content-end">
            <Col
              xs={12}
              md={8}
              className="contact-form-wrapper-privacy-policy-col"
            >
              <FormCheck>
                <FormCheck.Input
                  isValid={submitClicked > 0 && !errors.privacyPolicy}
                  isInvalid={!!errors.privacyPolicy}
                  {...register("privacyPolicy")}
                />

                <FormCheck.Label>
                  {t(`${privacyPolicy}clickApplication`)}{" "}
                  <Link
                    className="contact-form-wrapper-privacy-policy"
                    to={`/${language}/${t("privacyPolicy", {
                      ns: NameSpace.ROUTES,
                    })}`}
                  >
                    {t("privacyPolicy", { ns: NameSpace.GLOSSARY })}
                  </Link>{" "}
                  {t(`${privacyPolicy}responsibility`)}*
                </FormCheck.Label>
                {errors.privacyPolicy && (
                  <Form.Control.Feedback type="invalid">
                    {errors.privacyPolicy.message}
                  </Form.Control.Feedback>
                )}
              </FormCheck>
            </Col>
          </Form.Row>
          <Form.Row className="align-items-center justify-content-end mt-3">
            <Col xs={12} md="auto" className="contact-form-wrapper-submit-col">
              {submitting ? (
                <SpinnerAnimation />
              ) : formSubmitted ? (
                <p className="contact-form-wrapper-submit-success">
                  {t(`${contactForm}messageSuccess`, {
                    ns: NameSpace.COMMON,
                  })}
                </p>
              ) : (
                <Button
                  type="submit"
                  variant="outline-dark"
                  className="contact-form-wrapper-submit"
                  onClick={() => {
                    setSubmitClicked(submitClicked + 1);
                  }}
                >
                  {t(`${buttons}submit`, { ns: NameSpace.COMMON })}
                </Button>
              )}
            </Col>
          </Form.Row>
        </Form>
      </Container>
    </article>
  );
};

export default SpontaneousApplicationForm;
