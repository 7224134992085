import { useState } from "react";
import { Row, Col, Collapse } from "react-bootstrap";

import { DocumentClickedModel } from "../../models/DocumentClickedModel";
import { DocumentLineModel } from "../../models/DocumentLineModel";
import { DocumentTypeModel } from "../../models/DocumentTypeModel";

import DownloadAreaItem from "./DownloadAreaItem";

interface Props {
  clickedLine: boolean;
  documentClicked: DocumentClickedModel;
  line: DocumentLineModel;
  documentType: DocumentTypeModel;
  setItem?: (typeId: string, lineId: string, familyId?: string) => void;
}

const DownloadAreaSubItem = (props: Props) => {
  const [subNav, setSubNav] = useState(false);

  return (
    <>
      <DownloadAreaItem
        label={props.line.name}
        itemId={props.line.id}
        setItem={props.setItem}
        setSubNav={setSubNav}
        subNav={subNav}
        subMenu={props.line.families.length ? true : false}
        currentSubMenu
        depth={9}
        documentClicked={props.documentClicked}
        documentType={props.documentType}
      />
      <Collapse in={subNav}>
        <div>
          {props.line.families?.map((item, index) => {
            return (
              <Row className="justify-content-md-center" key={index}>
                <Col
                  className="download-area-item-text-left download-area-subitem"
                  md={6}
                  onClick={() => {
                    if (props.documentClicked.setClickedDocumentType) {
                      props.documentClicked.setClickedDocumentType(
                        props.documentType.type.name
                      );
                    }
                    props.documentClicked.setSearched(false);
                    props.documentClicked.setClickedDownloadList(false);
                    props.documentClicked.setClickedLine(false);
                    props.documentClicked.setClickedFamily(true);
                    if (props.setItem)
                      props.setItem(
                        props.documentType.type.id,
                        props.line.id,
                        item.id
                      );
                  }}
                >
                  {item.name}
                </Col>
              </Row>
            );
          })}
        </div>
      </Collapse>
    </>
  );
};

export default DownloadAreaSubItem;
