import { LanguageModel } from "../../models/LanguageModel";

import { get } from "./Api";

const endpoint = "/languages";

const Language = {
  getLanguages: async () => get<LanguageModel[]>(endpoint),
};

export default Language;
