

import ComplaintsFormModel from "../../models/Complaints";
import { get, post } from "./Api";

const endpoint = "/complaint/";

const Complaint = {
 
    // addComplaint: async (complaint: FormData ) =>
    //     post<ComplaintsFormModel>("complaint/addComplaint", complaint, {
    //         headers: {"Content-Type": "multipart/form-data"}    ,     
    //     }),
    addComplaint: async (complaint: FormData ) =>
        post<ComplaintsFormModel>("complaint/addComplaint", complaint, {
            headers: {"Content-Type": "multipart/form-data"}    ,     
        }),
};

export default Complaint;
