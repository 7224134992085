import {
  Col,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { NameSpace } from "../../data/AppLanguage";

import Util from "../../utils/Util";

import styles from  "./EventItem.module.css";
import { EventModel } from "../../models/EventModel";
import MediaCover from "../MediaCover/MediaCover";
import { MediaType } from "../../enums/MediaType";
import '../../../node_modules/video-react/dist/video-react.css';
import { ControlBar, Player } from  'video-react';




interface Props {
    event: EventModel;
    path: string;
}

interface HtmlTagsRemoverProps {
  text: string;
}

const EventItem = (props: Props) => {  
    const {t, i18n } = useTranslation([NameSpace.COMMON]);
    const language = Util.splitLanguage(i18n.language, 0);

    const removeHtmlTags = ({ text }: HtmlTagsRemoverProps) => {
      let temp = document.createElement("div");
      temp.innerHTML = text;
      return temp.textContent || temp.innerText || "";
    }

    return(    
        <div className={styles.grid_container01}>
        <Link to={`/${language}/${props.path}/${props.event.path}`}>   
          <div className={styles.card01}>
            {
              props.event?.mediaMain.path && props.event?.mediaMain.type === MediaType.IMAGE && (
              <div className={styles.custom_player_wrapper}>
              <Player
              playsInline={true}
              poster={props.event?.mediaMain.path}
              autoPlay
              muted          
              fluid        
               />
               </div>
            )
            
            }
            {
              props.event?.mediaMain.path && props.event?.mediaMain.type === MediaType.VIDEO && (
              <Player
              playsInline
              src={props.event?.mediaMain.path}
              autoPlay
              muted                     
            />
            )                
            }
              <div className={styles.card_body01}>
                  <h2 className={styles.card_title01}>{props.event.subtitle}</h2>
                  <p className={styles.card_text01}> {removeHtmlTags({ text: props.event.section1 })}</p>
              </div>
          </div>
        </Link>
    </div>        
    );
}

export default EventItem;
