import { SocialMediaModel } from "../../../models/SocialMediaModel";

import "../SocialMediaCollection.css";

interface Props {
  social?: SocialMediaModel;
}

const Youtube = (props: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 29.24 29.24"
      className={`social-media-collection ${props.social?.wrapperClassName}`}
    >
      <title>{"Youtube"}</title>
      <g data-name="Camada 2">
        <rect
          width={29.24}
          height={29.24}
          rx={7.65}
          transform="rotate(90 14.62 14.62)"
        />
        <path
          d="M22.45 12.5A3.52 3.52 0 0018.94 9H10a3.52 3.52 0 00-3.55 3.5v4.17A3.51 3.51 0 0010 20.18h9a3.51 3.51 0 003.51-3.51zm-5.28 2.4l-4 2c-.15.08-.69 0-.69-.21v-4.1c0-.18.54-.29.7-.2L17 14.48c.16.1.33.33.17.42z"
          className={props.social?.overlayClassName}
        />
      </g>
    </svg>
  );
};

export default Youtube;
