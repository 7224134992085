import { Container, Row, Col } from "react-bootstrap";
import SwiperCore, { Navigation, Mousewheel } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import { MultiCarouselItemModel } from "../../models/MultiCarouselItemModel";

import { breakpoints } from "../../data/SwiperData";

import MultiCarouselItem from "../MultiCarouselItem/MultiCarouselItem";

import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import "./MultiCarousel.css";

SwiperCore.use([Navigation, Mousewheel]);

interface Props {
  title: string;
  items: MultiCarouselItemModel[];
  overlay?: boolean;
  scaleImage?: boolean;
  className?: string;
}

const MultiCarousel = (props: Props) => {
  const slidesPerView = 4;

  return (
    <article className={`multicarousel ${props.className}`}>
      <Container className="multicarousel-wrapper">
        <Row>
          <Col>
            <h5 className="multicarousel-wrapper-heading">{props.title}</h5>
          </Col>
        </Row>
        <Row className="mt-5">
          <Col>
            <Swiper
              mousewheel={props.items.length > slidesPerView ? true : false}
              navigation
              centerInsufficientSlides
              breakpoints={breakpoints(slidesPerView)}
            >
              {props.items.map((item, index) => (
                <SwiperSlide className="multicarousel-wrapper-item" key={index}>
                  <MultiCarouselItem
                    item={item}
                    overlay={props.overlay}
                    scaleImage={props.scaleImage}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </Col>
        </Row>
      </Container>
    </article>
  );
};

export default MultiCarousel;
