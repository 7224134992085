import { useState, useEffect, Dispatch, SetStateAction } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { IoLocationSharp } from "react-icons/io5";
/* Commented temporarily */
/* import { FiUser } from "react-icons/fi"; */
import Hamburger from "hamburger-react";

import { GammaModel } from "../../models/GammaModel";
import { MenuSubNavModel } from "../../models/MenuSubNavModel";

import Util from "../../utils/Util";

import { mediumWidth } from "../../data/BreakpointsWidth";
import { menuLeftData } from "../../data/MenuLeftData";
import { NameSpace } from "../../data/AppLanguage";

import gammaService from "../../services/api/Gamma";

import SocialMedia from "../../components/SocialMedia/SocialMedia";
import Disclaimer from "../../components/Disclaimer/Disclaimer";
import MenuLeftItem from "../MenuItem/MenuLeftItem";
import MenuRightItem from "../MenuItem/MenuRightItem";
import MobileMenuSubitem from "../MobileMenuSubitem/MobileMenuSubitem";

import "./Menu.css";

interface Props {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  windowWidth: number;
  mobileSubNav: MenuSubNavModel | null;
  setMobileSubNav: (mobileSubNav: MenuSubNavModel | null) => void;
}

const Menu = (props: Props) => {
  const [handledGamma, setHandledGamma] = useState<GammaModel>();
  const [gammas, setGammas] = useState<GammaModel[]>([]);
  const [hoverMenuItem, setHoverMenuItem] = useState(false);

  const { t, i18n } = useTranslation([NameSpace.NAVIGATION, NameSpace.ROUTES]);
  const language = Util.splitLanguage(i18n.language, 0);

  const setImage = (gamma: GammaModel) => {
    setHoverMenuItem(!hoverMenuItem);
    setHandledGamma(gamma);
  };

  const menuLeftItem = (item: any, index: number) => (
    <MenuLeftItem
      key={index}
      item={item}
      setOpen={props.setOpen}
      setMobileSubNav={props.setMobileSubNav}
      gammas={gammas}
    />
  );

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      gammaService.getGammas(language).then((res) => {
        setGammas(res.data);
      });
    }

    return () => {
      mounted = false;
    };
  }, [language]);

  return (
    <Container
      fluid
      className={`menu ${props.open ? "menu-open" : ""} ${
        props.mobileSubNav ? "menu-bg-color" : ""
      }`}
    >
      <div
        className={`navigation-bar-hamburger ${
          props.mobileSubNav
            ? "navigation-bar-hamburger-dark"
            : "navigation-bar-hamburger-light"
        }`}
      >
        <Hamburger toggled={props.open} toggle={props.setOpen} />
      </div>
      {props.windowWidth >= mediumWidth ? (
        <div className="menu-gammas">
          {gammas.map((item, index) => {
            return (
              <div
                className={`menu-gammas-media ${
                  hoverMenuItem && item === handledGamma
                    ? "menu-gammas-media-hover"
                    : ""
                }`}
                style={{
                  backgroundImage: `url("${item.mediaBig.path}")`,
                }}
                key={index}
              ></div>
            );
          })}
        </div>
      ) : null}
      <Container
        className={`menu-section ${
          props.windowWidth < mediumWidth
            ? props.mobileSubNav
              ? "menu-section-mobile-subnav"
              : "menu-section-mobile"
            : "menu-section-desktop"
        }`}
      >
        <Row>
          <Col>
            <Container>
              {props.windowWidth < mediumWidth ? (
                props.mobileSubNav ? (
                  <MobileMenuSubitem
                    subitem={props.mobileSubNav}
                    setOpen={props.setOpen}
                    setMobileSubNav={props.setMobileSubNav}
                  />
                ) : (
                  menuLeftData(true).map((item, index) => {
                    return menuLeftItem(item, index);
                  })
                )
              ) : (
                menuLeftData().map((item, index) => {
                  return menuLeftItem(item, index);
                })
              )}
            </Container>
          </Col>
          <Col className="menu-right">
            <Container>
              {gammas.map((item, index) => {
                return (
                  <MenuRightItem
                    key={index}
                    item={item}
                    handledGamma={handledGamma}
                    hover={hoverMenuItem}
                    setImage={setImage}
                    setOpen={props.setOpen}
                  />
                );
              })}
            </Container>
          </Col>
        </Row>
      </Container>
      <Container
        fluid={props.windowWidth < mediumWidth ? true : false}
        className="menu-footer"
      >
        <Row className="menu-footer-desktop align-items-end">
          <Col>
            <SocialMedia
              className="social-media-secondary"
              social={{
                wrapperClassName: "social-media-bg-dark-gray",
                overlayClassName: "social-media-light",
              }}
            />
          </Col>
          <Col>
            <Disclaimer
              className={`menu-footer-disclaimer m-0 ${
                hoverMenuItem ? "menu-footer-disclaimer-color" : ""
              }`}
            />
          </Col>
        </Row>
        <Row className="menu-footer-mobile">
          <Col>
            <Row>
              <Col
                as={Link}
                to={`/${language}/${t("contact", { ns: NameSpace.ROUTES })}`}
                className={`menu-footer-mobile-location ${
                  props.mobileSubNav ? "menu-footer-mobile-location-subnav" : ""
                }`}
              >
                <IoLocationSharp /> {t("contact", { ns: NameSpace.NAVIGATION })}
              </Col>
            </Row>
            {/* Commented temporarily */}
            {/*  <Row>
              <Col as={Link} to="/" className="menu-footer-mobile-user">
                <FiUser /> {t("user", { ns: NameSpace.NAVIGATION })}
              </Col>
            </Row> */}
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default Menu;
