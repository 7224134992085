import { useState } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import {
  IoMdArrowDropleftCircle,
  IoMdArrowDroprightCircle,
} from "react-icons/io";
import { useTranslation } from "react-i18next";

import { PaginationModel } from "../../models/PaginationModel";

import { NameSpace, buttons } from "../../data/AppLanguage";

import "./Pagination.css";

interface Props {
  pagination: PaginationModel;
}

const Pagination = (props: Props) => {
  const { t } = useTranslation([NameSpace.COMMON]);

  const pagination = props.pagination;

  const [userInput, setUserInput] = useState(pagination.currentPage.toString());

  const handleInput = (e: any) => {
    if (
      Number.isInteger(parseInt(e.target.value)) &&
      pagination.totalPages >= 1 &&
      parseInt(e.target.value) >= 1 &&
      parseInt(e.target.value) <= pagination.totalPages
    )
      pagination.setPagination(parseInt(e.target.value));
    else setUserInput(pagination.currentPage.toString());
  };

  const handleArrows = (currentPage: number, input: number) => {
    setUserInput(
      pagination.filtered ? currentPage.toString() : input.toString()
    );
    pagination.setPagination(pagination.filtered ? currentPage : input);
  };

  return (
    <Container className="pages">
      <Form
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <Row className="pages-wrapper justify-content-end align-items-center">
          <Col className="pages-wrapper-button">
            {pagination.totalPages > 1 && (
              <Button
                variant="outline-primary"
                onClick={() => {
                  pagination.setItemsPerPage(
                    pagination.itemsPerPage + pagination.defaultItemsPerPage
                  );
                  pagination.setPagination(pagination.defaultCurrentPage);
                  setUserInput(pagination.defaultCurrentPage.toString());
                }}
              >
                {t(`${buttons}viewMore`)}
              </Button>
            )}
          </Col>
          <Col sm={1} className="d-flex justify-content-end">
            <Form.Control
              type="number"
              step="1"
              className="pages-wrapper-input"
              value={pagination.filtered ? pagination.currentPage : userInput}
              onChange={(e) => {
                if (pagination.filtered && pagination.setFiltered)
                  pagination.setFiltered(false);
                setUserInput(e.target.value);
              }}
              onBlur={(e: any) => {
                handleInput(e);
              }}
              onKeyPress={(e: any) => {
                if (e.charCode === 13) handleInput(e);
              }}
            />
          </Col>
          <Col sm={1} md="auto" className="pages-wrapper-total-pages">
            / {pagination.totalPages}
          </Col>
          <Col md="auto" className="pages-wrapper-icons">
            <IoMdArrowDropleftCircle
              onClick={() => {
                if (
                  pagination.totalPages >= 1 &&
                  parseInt(userInput) - 1 >= 1
                ) {
                  handleArrows(
                    pagination.currentPage - 1,
                    parseInt(userInput) - 1
                  );

                  if (pagination.filtered && pagination.setFiltered)
                    pagination.setFiltered(false);
                }
              }}
            />
          </Col>
          <Col md="auto" className="pages-wrapper-icons">
            <IoMdArrowDroprightCircle
              onClick={() => {
                if (
                  pagination.totalPages >= 1 &&
                  parseInt(userInput) < pagination.totalPages
                ) {
                  handleArrows(
                    pagination.currentPage + 1,
                    parseInt(userInput) + 1
                  );

                  if (pagination.filtered && pagination.setFiltered)
                    pagination.setFiltered(false);
                }
              }}
            />
          </Col>
        </Row>
      </Form>
    </Container>
  );
};

export default Pagination;
