import { Link } from "react-router-dom";
import { Image, Card, Button } from "react-bootstrap";
/* Commented just in case it would be needed again */
/* import { MdAdd } from "react-icons/md"; */
import { useTranslation } from "react-i18next";
import { Markup } from "interweave";

import { CategoryModel } from "../../models/CategoryModel";
import { MediaModel } from "../../models/MediaModel";
import "./CategoryItem.css";

import Util from "../../utils/Util";

import dataTransferDownIcon from '../../assets/icons/cil-data-transfer-down.svg';
import { Box } from "@mui/material";


interface ReviewOptions {
  text?: string;
  textField?: boolean;
}

interface StyleOptions {
  cardClassName?: string;
  nameClassName?: string;
  borderBottom?: boolean;
  overlay?: boolean;
  scaleImage?: boolean;
  background?: boolean;
}

interface Props {
  category?: CategoryModel;
  primaryMedia?: MediaModel;
  secondaryMedia?: MediaModel;
  energyEfficiencyMedia?: MediaModel;
  name: string;
  review?: ReviewOptions;
  path?: string;
  style?: StyleOptions;
  body?: JSX.Element;
  imageViewer?: () => void;
  isVariant?: boolean;
}

const CategoryItem = (props: Props) => {
  const { i18n } = useTranslation();
  const language = Util.splitLanguage(i18n.language, 0);

  const ancestors = props.category?.ancestors?.map((item) => {
    return item.path;
  });

  const buildUrl = `/${language}${
    ancestors && ancestors.length > 0 ? `/${ancestors.join("/")}/` : "/"
  }${props.category?.current.path}/`;

  const image = () => (
    <div className="position-relative">
      {props.secondaryMedia ? (
        <Card.Img
          src={props.secondaryMedia?.path}
          className="category-item-secondary-media"
        />
      ) : null}
      <Card.Img
        src={props.primaryMedia?.path}
        className={`category-item-primary-media ${
          props.style?.scaleImage ? "category-item-primary-media-scale" : ""
        }`}
        role={`${props.imageViewer ? "button" : ""}`}
        onClick={() => {
          if (props.imageViewer) props.imageViewer();
        }}
      />
      {props.energyEfficiencyMedia ? (
        <Image
          src={props.energyEfficiencyMedia.path}
          className="category-item-energy-efficiency-media"
          fluid
        />
      ) : null}
    </div>
  );

  const overlayStyle = (element: JSX.Element) => {
    return props.style?.overlay ? (
      <div className="category-item-overlay">
        {element}
        {/* Commented just in case it would be needed again */}
        {/* <div className="category-item-overlay-wrapper">
          <MdAdd className="category-item-overlay-wrapper-icon" />
        </div> */}
      </div>
    ) : (
      element
    );
  };

  const imageStyle = () => {
    return props.style?.background
      ? overlayStyle(<div className="category-item-background">{image()}</div>)
      : overlayStyle(image());
  };

  return (
    <Card className={`category-item w-75 ${props.style?.cardClassName}`}>
      {props.path ? (
        <Link to={`${props.category ? buildUrl : ""}${props.path}`}>
          {imageStyle()}
        </Link>
      ) : (
        imageStyle()
      )}
      <Card.Body
        className={`category-item-body pl-0 pr-0 ${
          props.style?.borderBottom ? "category-item-border-bottom mt-4" : ""
        }`}
      >
        <Box display={'flex'} sx={{ justifyContent: 'center' }}>
          <Card.Title
            className={`category-item-body-title ${props.style?.nameClassName}`}
          >
            {props.name}
            {/* {
              props.isVariant  && 
              <button
              style={{ backgroundColor: '#ffffff', alignContent: 'center', border: 'none' }}
                >
                  <img src={dataTransferDownIcon} alt="Download Icon" className="icon" style={{ width: "1em", marginLeft: "0.3em"}} />
                </button>
            }   */}
          </Card.Title>
        </Box>
        
        
        {props.review && props.review.text ? (
          <div
            className={`category-item-body-text ${
              !props.review.textField ? "category-item-body-text-clamping" : ""
            }`}
          >
            <Card.Text as={Markup} content={props.review.text} />
          </div>
        ) : null}
        {props.body}
      </Card.Body>
    </Card>
  );
};

export default CategoryItem;
