import { PaginationModel } from "../../models/PaginationModel";
import Pagination from "../Pagination/Pagination";
import Util from "../../utils/Util";
import {
    Container,
    Row,
    Col,
    Image, /* Collapse, Button */
    Button,
  } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./CaseStudy.css";
import { useTranslation } from "react-i18next";
import { NameSpace } from "../../data/AppLanguage";
import CaseStudyItem from "../CaseStudyItem/CaseStudyItem";

import BreadcrumbPath from "../BreadcrumbPath/BreadcrumbPath";
import { CaseStudyModel } from "../../models/CaseStudyModel";


interface Props {
    caseStudys: CaseStudyModel[];
    path: string;
    pagination?: PaginationModel;
    className?: string;
}



const CaseStudy = (props: Props) => {

    const { t, i18n } = useTranslation([
        NameSpace.COMMON,
        NameSpace.GLOSSARY,
        NameSpace.NAVIGATION,
        NameSpace.ROUTES,
        NameSpace.PAGES
    ]);

     const language = Util.splitLanguage(i18n.language, 0);
    
    const solutionsPath = t("solutions", { ns: NameSpace.ROUTES });
    const solutionsTitle = t("solutions", { ns: NameSpace.NAVIGATION });
    const imageGalleryPath = t("caseStudy", { ns: NameSpace.ROUTES });
    const ImageGalleryTitle = t("caseStudy", { ns: NameSpace.NAVIGATION });
    const data = t("data", { ns: NameSpace.PAGES });

    return(
        <>              
            <article className="event mt-5 pb-0">
                <div className="container">
                    <h4 className="mt-8 mb-0">{t("caseStudy", { ns: NameSpace.NAVIGATION })}</h4>
                    <hr/>
                    <BreadcrumbPath
                        ancestors={[
                        {
                            name: solutionsTitle,
                            path: solutionsPath,
                        },
                        {
                            name: ImageGalleryTitle,
                            path: imageGalleryPath
                        }
                        ]}                
                        className="pt-1"
                    />  
                        {props.caseStudys.length > 0 ? (
                            <div className="event-wrapper row justify-content-center align-items-center teste001">            
                            {/* -------------------------------------------- ORIGINAL INÍCIO ------------------------------------------------------------ */}
                                <Row className="justify-content-left event"> 
                                {props.caseStudys.map((item, index) => {
                                    return (
                                    <Col
                                        xl={4}
                                        lg={5}
                                        md={6}
                                        sm={10}
                                        key={index}
                                        className="d-flex justify-content-left"
                                    >
                                        <CaseStudyItem caseStudy={item} path={props.path} />
                                    </Col>
                                    );
                                })}
                                </Row>
                            {/* -------------------------------------------- ORIGINAL FIM --------------------------------------------------------------- */}    
                            
                        </div>
                        ) : (
                            <div className="alert alert-warning" role="alert">
                                <h5 className="text-align-center dados p-0 m-0">
                                    {t("data", {ns: NameSpace.PAGES})}
                                </h5>
                            </div>
                        )}                      
                       
                        {props.pagination ? <Pagination pagination={props.pagination} /> : null}
                        <br/>
                </div>
            </article>
        </>
    );
}

export default CaseStudy;