import { DocumentTypeModel } from "../../models/DocumentTypeModel";
import { DocumentFamilyModel } from "../../models/DocumentFamilyModel";

import { get, post } from "./Api";

const endpoint = "downloads/";

export const downloadDocumentEndpoint = `${endpoint}downloaddocument/`;

const Download = {
  getDocuments: async (language: string) =>
    get<DocumentTypeModel[]>(`${endpoint}documents`, { params: { language } }),

  downloadDocument: async (familyId: string, documentId: number) =>
    get<Blob>(`${downloadDocumentEndpoint}${familyId}/${documentId}`, {
      responseType: "blob",
    }),

  downloadDocuments: async (model: DocumentFamilyModel[], language: string) =>
    post<Blob>(`${endpoint}downloaddocuments`, model, {
      params: { language },
      responseType: "blob",
    }),
};

export default Download;
