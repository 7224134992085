import SettingModel from "../../models/SettingsModel";
import { get } from "./Api";

const SettingService = {
    GetById: async (settingId: string) => 
        get<SettingModel>(`settings/settings/${settingId}`),

    GetOpportunityById: async (settingId: string) => 
        get<SettingModel>(`settings/settings/${settingId}`),
}

export default SettingService;