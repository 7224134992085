import { Col, ColProps } from "react-bootstrap";

import "./MobileDivider.css";

interface Props {
  colSpec?: ColProps;
  dividerClassName?: string;
}

const MobileDivider = (props: Props) => {
  return (
    <Col
      xs={props.colSpec?.xs}
      sm={props.colSpec?.sm}
      md={props.colSpec?.md}
      className="mobile-divider"
    >
      <hr className={props.dividerClassName} />
    </Col>
  );
};

export default MobileDivider;
