import { useEffect, useState } from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { GammaModel } from "../../models/GammaModel";

import { NameSpace, socialMedia } from "../../data/AppLanguage";

import Util from "../../utils/Util";

import GammaService from "../../services/api/Gamma";

import Disclaimer from "../../components/Disclaimer/Disclaimer";
import SocialMedia from "../../components/SocialMedia/SocialMedia";

import livro_reclamacoes from "../../assets/images/livro_reclamacoes.png";

import "./Footer.css";
import SettingModel from "../../models/SettingsModel";
import SettingService from "../../services/api/Settings";
import OpportunityGammaModel from "../../models/OpportunityGammaModel";
import OpportunityService from "../../services/Opportunity";
import { Box } from "@mui/material";

const Footer = () => {
  const [gammas, setGammas] = useState<GammaModel[]>([]);
  const [setting, setSetting] = useState<SettingModel>();
  const [opporunitiesByGamaList, setOpporunitiesByGamaList] = useState<OpportunityGammaModel[]>([]);

  const params = useParams<{ id: string }>();
  

  const { t, i18n } = useTranslation([
    NameSpace.COMMON,
    NameSpace.GLOSSARY,
    NameSpace.NAVIGATION,
    NameSpace.ROUTES,
  ]);
  const language = Util.splitLanguage(i18n.language, 0);

  useEffect(() => {
    let mounted = true;
   
    if (mounted) {
      GammaService.getGammas(language).then((res) => {
        setGammas(res.data);
      });

      SettingService.GetById("1").then((res) => {
        setSetting(res.data);
      });
  
      OpportunityService.getAllGroupedOpportunities(language).then((res) =>{
        setOpporunitiesByGamaList(res.data);        
      })

      // SettingService.GetOpportunityById("2").then((res) => {
      //   setSetting(res.data);
      // });

    }

    return () => {
      mounted = false;
    };
  }, [language, params.id]);

  return (
    <div className="footer pt-5 pl-5 pr-5">
      <Container className="footer-wrapper">
        <Row>
          <Col className="pl-0 pr-0">
            <h5 className="footer-wrapper-title">
              {t("group", { ns: NameSpace.NAVIGATION })}
            </h5>
            <hr />
            <ul className="footer-wrapper-list">
              <li>
                <Link
                  className="footer-wrapper-item"
                  to={`/${language}/${t("aboutUs", { ns: NameSpace.ROUTES })}`}
                >
                  {t("aboutUs", { ns: NameSpace.NAVIGATION })}
                </Link>
              </li>
              <li>
                <Link
                  className="footer-wrapper-item"
                  to={`/${language}/${t("qualityCertification", {
                    ns: NameSpace.ROUTES,
                  })}`}
                >
                  {t("qualityCertification", { ns: NameSpace.NAVIGATION })}
                </Link>
              </li>             
              <li>
                {setting && setting.isVisible && (
                    <Link
                    className="footer-wrapper-item"
                    to={`/${language}/${t("solutions", {
                      ns: NameSpace.ROUTES,
                    })}`}
                  >
                    {t("solutions", { ns: NameSpace.NAVIGATION })}
                  </Link>
                )}                
              </li>             
               {/* <li>               
                    <Link
                    className="footer-wrapper-item"
                    to={`/${language}/${t("solutions", {
                      ns: NameSpace.ROUTES,
                    })}`}
                  >
                    {t("solutions", { ns: NameSpace.NAVIGATION })}
                  </Link>                               
              </li> */}
            </ul>
          </Col>
          <Col className="pl-0 pr-0">
            <h5 className="footer-wrapper-title">
              {t("products", { ns: NameSpace.NAVIGATION })}
            </h5>
            <hr />
            <ul className="footer-wrapper-list">
              {gammas.map((item, index) => {
                return (
                  <li key={index}>
                    <Link

                      className="footer-wrapper-item"
                      to={`/${language}/${item.path}`}
                    >
                      {item.name}
                    </Link>
                  </li>
                  
                );
              })}
              {
                opporunitiesByGamaList.length ? (
                <li>
                <Link
                    className="footer-wrapper-item"
                    to={`/${language}/${t("opportunity", {
                      ns: NameSpace.ROUTES,
                    })}`}
                  >
                    {t("opportunities", { ns: NameSpace.NAVIGATION })}
                  </Link>                 
                </li> ) : null
              }
            
            </ul>
          </Col>
          <Col className="pl-0 pr-0">
            <h5 className="footer-wrapper-title">
              <Link
                to={`/${language}/${t("newsEvents", {
                  ns: NameSpace.ROUTES,
                })}`}
              >
                {t("newsEvents", { ns: NameSpace.NAVIGATION })}
              </Link>
            </h5>
            <hr />
          </Col>
          <Col className="pl-0 pr-0">
            <h5 className="footer-wrapper-title">
              <Link
                to={`/${language}/${t("recruitment", {
                  ns: NameSpace.ROUTES,
                })}`}
              >
                {t("recruitment", { ns: NameSpace.NAVIGATION })}
              </Link>
            </h5>
            <hr />
          </Col>
          <Col className="pl-0 pr-0">
            <h5 className="footer-wrapper-title">
              <Link
                to={`/${language}/${t("contact", {
                  ns: NameSpace.ROUTES,
                })}`}
              >
                {t("contact", { ns: NameSpace.NAVIGATION })}
              </Link>              
            </h5>
            <hr />
            {language === 'PT' || language === 'pt' && 
              <ul className="footer-wrapper-list">
                <li>
                  <Link
                  className="footer-wrapper-item"
                  to={`/${language}/${t("complaint", {
                    ns: NameSpace.ROUTES,
                  })}`}
                >
                  {t("complaint", { ns: NameSpace.NAVIGATION })}
                </Link>     
                </li>
               </ul>
            }
           
          </Col>
        </Row>
        {/* <Row className="justify-content-center">
          <a href="https://www.livroreclamacoes.pt/inicio" target="_blank">
            <Image
              src={livro_reclamacoes}
              alt={t("complaintBook", { ns: NameSpace.NAVIGATION })}
              className="w-75"
            />
          </a>
        </Row> */}        
        <Box display={window.innerWidth > 1000 ? 'flex': 'block'}>
          <Box display="block" width={'70%'}>
            <Row className="justify-content-center">
              <Disclaimer className="footer-wrapper-disclaimer" />
            </Row>
            <Row className="justify-content-center">
              <Link
                className="footer-wrapper-privacy-policy"
                to={`/${language}/${t("privacyPolicy", { ns: NameSpace.ROUTES })}`}
              >
                {t("privacyPolicy", { ns: NameSpace.GLOSSARY })}
              </Link>
            </Row>
          </Box>
          <Box 
            width={window.innerWidth > 1000 ? '30%' : '100%'} 
            display={window.innerWidth > 1000 ? 'flex': 'block'} 
            alignItems={'center'} 
            mt={window.innerWidth > 1000 ? '': '20px'}>
            {/* <Box className="footer-wrapper-social-media justify-content-end align-items-center"> */}
              <SocialMedia
                // title={t(`${socialMedia}v1`, { ns: NameSpace.COMMON })}
                className="social-media-primary"
                titleClassName="social-media-primary-title"
                social={{
                  wrapperClassName: "social-media-bg-light",
                  overlayClassName: "social-media-dark-blue",
                }}
              /> 
                &emsp;
                <a href="https://www.livroreclamacoes.pt/inicio" target="_blank">
                <Image
                  src={livro_reclamacoes}
                  alt={t("complaintBook", { ns: NameSpace.NAVIGATION })}
                  className={`${window.innerWidth > 1000 ? 'w-25': 'w-25 mt-2'}`}
                />
              </a>
            {/* </Box> */}
          </Box>
        </Box>
        
      </Container>
    </div>
  );
};

export default Footer;
