import { GalleryModel } from "../../models/GalleryModel";
import { GalleryItemModel } from "../../models/GalleryItemModel";

import { get } from "./Api";

const endpoint = `/newsevents`;

const NewsEvent = {
  getNewsEvents: async (language: string, rows?: number) =>
    get<GalleryModel[]>(endpoint, {
      params: { rows, language },
    }),

  getNewsEvent: async (path: string, language: string) =>
    get<GalleryItemModel>(`${endpoint}/${path}`, {
      params: { language },
    }),
};

export default NewsEvent;
