import { InfoWindow, Marker } from "@react-google-maps/api";

import { LocationModel } from "../../models/LocationModel";

import "./GoogleMapMarker.css";

interface Props {
  location: LocationModel;
  locations: LocationModel[];
  currentLocation: LocationModel | null;
  setCurrentLocation: (currentLocation: LocationModel | null) => void;
  index: number;
}

const GoogleMapMarker = (props: Props) => {
  const toggleActive = (index: number) => {
    if (props.currentLocation !== props.locations[index])
      props.setCurrentLocation(props.locations[index]);
    else props.setCurrentLocation(null);
  };

  const toggleActiveWindow = (index: number) => {
    if (props.locations[index] === props.currentLocation) return true;
    return false;
  };

  return (
    <Marker
      position={{
        lat: props.location.latitude,
        lng: props.location.longitude,
      }}
      onClick={() => toggleActive(props.index)}
    >
      {toggleActiveWindow(props.index) ? (
        <InfoWindow onCloseClick={() => props.setCurrentLocation(null)}>
          <div className="info-window">
            <div className="info-window-region">{props.location.region}</div>
            <div className="mt-2">{props.location.address}</div>
          </div>
        </InfoWindow>
      ) : null}
    </Marker>
  );
};

export default GoogleMapMarker;
