import { useState, useEffect } from "react";
import { useHistory } from "react-router";
import Select from "react-select";
import { useTranslation } from "react-i18next";

import { LanguageModel } from "../../models/LanguageModel";

import {
  fallbackLanguage,
  fallbackCountry,
  fallbackVariant,
} from "../../data/AppLanguage";

import Util from "../../utils/Util";

import LanguageService from "../../services/api/Language";

import "./LanguageSwitcher.css";

interface LanguageSelect {
  value: string;
  label: string;
}

const LanguageSwitcher = () => {
  const [languages, setLanguages] = useState<LanguageModel[]>([]);
  const [currentLanguage, setCurrentLanguage] = useState<LanguageSelect>();

  const { i18n } = useTranslation();
  const history = useHistory();

  const languageSelect = (): LanguageSelect[] => {
    return languages.map((language) => ({
      label: language.id,
      value: language.countryId,
    }));
  };

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      LanguageService.getLanguages().then((res) => {
        setLanguages(res.data);

        var languageQuery = res.data.find(
          (l) => l.id === Util.splitLanguage(i18n.language, 0).toUpperCase()
        );

        setCurrentLanguage({
          label: languageQuery?.id ?? fallbackLanguage,
          value: languageQuery?.countryId ?? fallbackCountry,
        });

        if (!languageQuery) i18n.changeLanguage(fallbackVariant);
        else
          i18n.changeLanguage(
            Util.joinLanguage(
              languageQuery.id.toLowerCase(),
              languageQuery.countryId.toLowerCase()
            )
          );
      });
    }

    return () => {
      mounted = false;
    };
  }, [i18n]);

  const customLanguageSelect = {
    control: (base: any) => ({
      ...base,
      background: "transparent",
      border: "none",
      boxShadow: "none",
      cursor: "pointer",
    }),
    option: (base: any) => ({
      ...base,
      cursor: "pointer",
    }),
  };

  return languages.length ? (
    <Select
      className="language-switcher"
      styles={customLanguageSelect}
      options={languageSelect()}
      value={currentLanguage}
      isSearchable={false}
      components={{
        DropdownIndicator: () => null,
        IndicatorSeparator: () => null,
      }}
      onChange={(e) => {
        if (e) {
          setCurrentLanguage({ label: e.label, value: e.value });
          i18n.changeLanguage(Util.joinLanguage(e.label, e.value));

          history.push(`/${e.label.toLowerCase()}`);
          history.go(0);
        }
      }}
    />
  ) : null;
};

export default LanguageSwitcher;
