import { LanguageModel } from "../models/LanguageModel";

import Util from "../utils/Util";

interface CountriesCodes {
  [key: string]: string;
}

export const defaultLanguage = "pt";
export const defaultCountry = "pt";
export const defaultCountryCode = "351";
export const defaultVariant = Util.joinLanguage(
  defaultLanguage,
  defaultCountry
);

export const fallbackLanguage = "en";
export const fallbackCountry = "gb";
export const fallbackCountryCode = "44";
export const fallbackVariant = Util.joinLanguage(
  fallbackLanguage,
  fallbackCountry
);

const spainLanguage = "es";
const spainCountry = "es";
const spainCountryCode = "34";

const franceLanguage = "fr";
const franceCountry = "fr";
const franceCountryCode = "33";

export const countries = [
  defaultCountry,
  fallbackCountry,
  spainCountry,
  franceCountry,
];
export const countriesCodes: CountriesCodes = {
  [defaultCountry]: defaultCountryCode,
  [fallbackCountry]: fallbackCountryCode,
  [spainCountry]: spainCountryCode,
  [franceCountry]: franceCountryCode,
};
export const languages = [
  defaultLanguage,
  fallbackLanguage,
  spainLanguage,
  franceLanguage,
];

export const newsEvents = "newsEvents.";
export const socialMedia = "socialMedia.";
export const newsletter = "newsletter.";
export const contactForm = "contactForm.";
export const buttons = "buttons.";
export const filters = "filters.";
export const families = "families.";
export const genericForm = "generic.";
export const specificForm = "specific.";
export const jobOfferForm = "jobOffer.";
export const spontaneousForm = "spontaneous.";
export const privacyPolicy = `${contactForm}privacyPolicy.`;
export const messagePhoneSuccess = `${contactForm}messagePhoneSuccess.`;
export const error = "error.";

export enum NameSpace {
  COMMON = "common",
  CONTACTFORMS = "contactforms",
  GLOSSARY = "glossary",
  NAVIGATION = "navigation",
  PAGES = "pages",
  ROUTES = "routes",
  VALIDATION = "validation",
}

export const defaultLanguageState: LanguageModel = {
  id: defaultLanguage,
  countryId: defaultCountry,
};

export const fallbackLanguageState: LanguageModel = {
  id: fallbackLanguage,
  countryId: fallbackCountry,
};
