import { SliderModel } from "../../models/SliderModel";

import { get } from "./Api";

const endpoint = "/sliders";

const Slider = {
  getSliders: async (language?: string) =>
    get<SliderModel[]>(endpoint, { params: { language } }),
};

export default Slider;
