import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpApi from "i18next-http-backend";

import { NameSpace, fallbackVariant } from "./data/AppLanguage";
import { Environment } from "./enums/Environment";

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(HttpApi)
  .init({
    ns: [
      NameSpace.COMMON,
      NameSpace.CONTACTFORMS,
      NameSpace.GLOSSARY,
      NameSpace.NAVIGATION,
      NameSpace.PAGES,
      NameSpace.ROUTES,
      NameSpace.VALIDATION,
    ],
    nonExplicitSupportedLngs: true,
    fallbackLng: fallbackVariant,
    interpolation: {
      escapeValue: false,
    },
    detection: {
      order: [
        "path",
        "cookie",
        "htmlTag",
        "localStorage",
        "sessionStorage",
        "navigator",
        "subdomain",
      ],
    },
    backend: {
      loadPath: "/locales/{{lng}}/{{ns}}.json",
    },
    debug: process.env.NODE_ENV === Environment.DEVELOPMENT,
  });

export default i18n;
