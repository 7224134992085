import { Row, Col } from "react-bootstrap";
import { MdAdd } from "react-icons/md";
import { AiOutlineMinus } from "react-icons/ai";

import { DocumentTypeModel } from "../../models/DocumentTypeModel";
import { DocumentClickedModel } from "../../models/DocumentClickedModel";

interface Props {
  setSubNav: (subNav: boolean) => void;
  subNav: boolean;
  itemId?: string;
  documentType: DocumentTypeModel;
  label: string;
  subMenu: boolean;
  currentSubMenu?: boolean;
  depth?: number;
  documentClicked: DocumentClickedModel;
  setItem?: (typeId: string, lineId: string, familyId?: string) => void;
}

const DownloadAreaItem = (props: Props) => {
  return (
    <Row className="download-area-item justify-content-md-center">
      <Col
        className={`download-area-item-text-left ${
          props.currentSubMenu ? "download-area-subitem" : ""
        }`}
        md={props.depth}
        onClick={() => {
          if (props.currentSubMenu) {
            if (props.documentClicked.setClickedDocumentType)
              props.documentClicked.setClickedDocumentType(
                props.documentType.type.name
              );
            props.documentClicked.setSearched(false);
            props.documentClicked.setClickedDownloadList(false);
            props.documentClicked.setClickedLine(true);
            props.documentClicked.setClickedFamily(false);
            if (props.setItem && props.itemId)
              props.setItem(props.documentType.type.id, props.itemId);
          } else props.setSubNav(!props.subNav);
        }}
      >
        {props.label}
      </Col>
      <Col
        md="auto"
        onClick={() => props.setSubNav(!props.subNav)}
        className={`download-area-item-text-right d-flex align-items-center ${
          props.currentSubMenu ? "download-area-subitem" : ""
        }`}
      >
        {props.subMenu ? (
          props.subNav ? (
            <AiOutlineMinus className="download-area-item-icon rounded-pill" />
          ) : (
            <MdAdd className="download-area-item-icon rounded-pill" />
          )
        ) : null}
      </Col>
    </Row>
  );
};

export default DownloadAreaItem;
