import { useEffect } from "react";
import { useHistory } from "react-router-dom";

import { useAppDispatch } from "../app/hooks";

import setupInterceptors from "../services/api/Api";

const InjectAxiosInterceptors = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();

  useEffect(() => {
    setupInterceptors(history, dispatch);
  }, [dispatch, history]);

  return null;
};

export default InjectAxiosInterceptors;
