import { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  InputGroup,
  FormControl,
  Alert,
} from "react-bootstrap";
import { MdSearch } from "react-icons/md";
import { BsDownload } from "react-icons/bs";
import { MdCloudDownload, MdDelete } from "react-icons/md";
import { BsLink45Deg } from "react-icons/bs";
import mime from "mime-types";
import { useTranslation, Trans } from "react-i18next";

import { ContentPageModel } from "../../models/ContentPageModel";
import { DocumentTypeModel } from "../../models/DocumentTypeModel";
import { DocumentFamilyModel } from "../../models/DocumentFamilyModel";

import { NameSpace, buttons } from "../../data/AppLanguage";
import { ContentPageType } from "../../enums/ContentPageType";

import Util from "../../utils/Util";

import { config } from "../../utils/Env";

import ContentPageService from "../../services/api/ContentPage";
import DownloadService, {
  downloadDocumentEndpoint,
} from "../../services/api/Download";

import BreadcrumbPath from "../../components/BreadcrumbPath/BreadcrumbPath";
import Title from "../../components/Title/Title";
import Description from "../../components/Description/Description";
import DocumentSearchCollection from "../../components/DocumentSearchCollection/DocumentSearchCollection";
import DownloadAreaNav from "../../components/DownloadAreaNav/DownloadAreaNav";
import DocumentCollection from "../../components/DocumentCollection/DocumentCollection";
import DownloadAreaList from "../../components/DownloadAreaList/DownloadAreaList";

import "./DownloadArea.css";

const DownloadArea = () => {
  const [searched, setSearched] = useState(false);
  const [clickedDownloadList, setClickedDownloadList] = useState(false);
  const [clickedDocumentType, setClickedDocumentType] = useState("");
  const [clickedLine, setClickedLine] = useState(false);
  const [clickedFamily, setClickedFamily] = useState(false);
  const [group, setGroup] = useState<ContentPageModel>();
  const [search, setSearch] = useState("");
  const [documentTypes, setDocumentTypes] = useState<DocumentTypeModel[]>([]);
  const [documentLineId, setDocumentLineId] = useState("");
  const [documentFamilyId, setDocumentFamilyId] = useState("");
  const [documentTypeId, setDocumentTypeId] = useState("");
  const [downloadList, setDownloadList] = useState<DocumentFamilyModel[]>([]);
  const [currentDownloadListItem, setCurrentDownloadListItem] =
    useState<DocumentFamilyModel | null>(null);

  const { t, i18n } = useTranslation([
    NameSpace.COMMON,
    NameSpace.NAVIGATION,
    NameSpace.PAGES,
  ]);
  const language = Util.splitLanguage(i18n.language, 0);
  const downloadAreaTitle = t("downloadArea", { ns: NameSpace.NAVIGATION });

  const setItem = (typeId: string, lineId: string, familyId?: string) => {
    if (lineId) setDocumentLineId(lineId);
    if (familyId) setDocumentFamilyId(familyId);
    setDocumentTypeId(typeId);
  };

  const downloadDocuments = () => {
    DownloadService.downloadDocuments(downloadList, language).then((res) => {
      saveAs(res.data, `Documentos.${mime.extension(res.data.type)}`);
    });
  };

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      document.title = Util.setDocumentTitle(downloadAreaTitle);

      ContentPageService.getContentPages(
        ContentPageType.DOWNLOADAREA,
        language
      ).then((res) => {
        setGroup(res.data);
      });

      var list = JSON.parse(sessionStorage.getItem("downloadList") || "[]");

      setDownloadList(list);

      DownloadService.getDocuments(language).then((res) => {
        setDocumentTypes(res.data);
      });
    }

    return () => {
      mounted = false;
    };
  }, [downloadAreaTitle, language]);

  return (
    <>
      <BreadcrumbPath current={downloadAreaTitle} className="pt-8" />
      <article className="download-area mb-5">
        <Container>
          <Row>
            <Col md={8}>
              {group?.title || group?.subtitle ? (
                <Row>
                  <Col>
                    {group?.title ? (
                      <Title title={group.title} className="title-subtitle" />
                    ) : null}
                    {group?.subtitle ? (
                      <Title title={group.subtitle} className="title-title" />
                    ) : null}
                  </Col>
                </Row>
              ) : null}
              {!searched && clickedDownloadList ? (
                <Row className="mt-5">
                  <Col>
                    <Container className="download-area-wrapper-list">
                      <Row>
                        <Col className="download-area-wrapper-list-title">
                          {t("selected", { ns: NameSpace.PAGES })}
                        </Col>
                      </Row>
                      {downloadList.length === 0 ? (
                        <Row className="mt-3">
                          <Col>
                            <Alert variant="warning">
                              <Trans
                                i18nKey="noDocumentsSelected"
                                ns={NameSpace.PAGES}
                              >
                                Sem <strong>Documentos</strong> selecionados.
                              </Trans>
                            </Alert>
                          </Col>
                        </Row>
                      ) : (
                        <DownloadAreaList
                          downloadList={downloadList}
                          currentDownloadListItem={currentDownloadListItem}
                          setDownloadList={setDownloadList}
                          setCurrentDownloadListItem={
                            setCurrentDownloadListItem
                          }
                        />
                      )}
                      <Row className="mt-7 justify-content-md-center">
                        <Col md="auto">
                          <Button
                            variant="primary"
                            className="download-area-wrapper-list-button"
                            disabled={downloadList.length === 0}
                            onClick={() => downloadDocuments()}
                          >
                            <MdCloudDownload />{" "}
                            {t(`${buttons}downloadAll`, {
                              ns: NameSpace.COMMON,
                            })}
                          </Button>
                        </Col>
                        <Col md="auto">
                          <Button
                            variant="primary"
                            className="download-area-wrapper-list-button"
                            disabled={
                              !currentDownloadListItem ||
                              downloadList.length === 0
                            }
                            onClick={() => {
                              window.location.href =
                                `mailto:exemplo@exemplo.pt?subject=Documento | ${currentDownloadListItem?.name} ` +
                                `- ${currentDownloadListItem?.type.name}&body=${config.url.API_URL}${downloadDocumentEndpoint}` +
                                `${currentDownloadListItem?.id}/${currentDownloadListItem?.documentId}`;
                            }}
                          >
                            <BsLink45Deg />{" "}
                            {t(`${buttons}copyLink`, {
                              ns: NameSpace.COMMON,
                            })}
                          </Button>
                        </Col>
                        <Col md="auto">
                          <Button
                            variant="primary"
                            className="download-area-wrapper-list-button"
                            disabled={downloadList.length === 0}
                            onClick={() => {
                              setDownloadList([]);
                              sessionStorage.setItem("downloadList", "[]");
                            }}
                          >
                            <MdDelete />{" "}
                            {t(`${buttons}clearList`, {
                              ns: NameSpace.COMMON,
                            })}
                          </Button>
                        </Col>
                      </Row>
                    </Container>
                  </Col>
                </Row>
              ) : searched || clickedLine || clickedFamily ? (
                <Row>
                  <Col className="mt-7" md={11}>
                    <Container className="download-area-document">
                      <Row className="download-area-document-header">
                        <Col className="download-area-document-header-text">
                          {searched && search.trim() !== "" ? (
                            t("searchFor", {
                              ns: NameSpace.PAGES,
                              name: search,
                            })
                          ) : (
                            <>
                              <span>{clickedDocumentType}</span> -{" "}
                              {
                                documentTypes
                                  .find((d) => d.type.id === documentTypeId)
                                  ?.lines.find((l) => l.id === documentLineId)
                                  ?.name
                              }
                            </>
                          )}
                        </Col>
                        <Col md="auto">
                          <div
                            className="download-area-document-icon-badge-container"
                            onClick={() => {
                              setSearched(false);
                              setClickedDownloadList(true);
                              setClickedLine(false);
                              setClickedFamily(false);
                            }}
                          >
                            <BsDownload className="download-area-document-header-icon download-area-document-icon-badge-container-icon" />
                            {downloadList.length ? (
                              <div className="download-area-document-icon-badge-container-icon-badge shadow-sm bg-white rounded-circle">
                                {downloadList.length}
                              </div>
                            ) : null}
                          </div>
                        </Col>
                      </Row>
                      <Row className="category-item mt-5">
                        {!searched || search.trim() === "" ? (
                          <DocumentCollection
                            documentTypes={documentTypes}
                            downloadList={downloadList}
                            documentTypeId={documentTypeId}
                            documentLineId={documentLineId}
                            documentFamilyId={documentFamilyId}
                            clickedLine={clickedLine}
                            clickedFamily={clickedFamily}
                            setDownloadList={setDownloadList}
                          />
                        ) : (
                          <DocumentSearchCollection
                            documentTypes={documentTypes}
                            downloadList={downloadList}
                            search={search}
                            setDownloadList={setDownloadList}
                          />
                        )}
                      </Row>
                    </Container>
                  </Col>
                </Row>
              ) : group?.body ? (
                <Row className="mt-5">
                  <Col md={9}>
                    <Description
                      text={group.body}
                      className="description-download-area"
                    />
                  </Col>
                </Row>
              ) : null}
            </Col>
            <Col md={4}>
              <Row className="mt-5">
                <Col>
                  <InputGroup className="mb-3">
                    <InputGroup.Text id="search">
                      <MdSearch />
                    </InputGroup.Text>
                    <FormControl
                      placeholder={t("search", {
                        ns: NameSpace.PAGES,
                      })}
                      aria-label="Search"
                      aria-describedby="search"
                      onChange={(event) => {
                        if (event.target.value.trim() === "") {
                          setSearched(false);
                          setSearch(event.target.value);
                        } else {
                          setSearched(true);
                          setSearch(event.target.value);
                        }
                      }}
                    />
                  </InputGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <DownloadAreaNav
                    documentTypes={documentTypes}
                    clickedLine={clickedLine}
                    documentClicked={{
                      setSearched: setSearched,
                      setClickedDocumentType: setClickedDocumentType,
                      setClickedDownloadList: setClickedDownloadList,
                      setClickedLine: setClickedLine,
                      setClickedFamily: setClickedFamily,
                    }}
                    setItem={setItem}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </article>
    </>
  );
};

export default DownloadArea;
