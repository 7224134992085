import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { JobOfferModel } from "../../models/JobOfferModel";

import { RootState } from "../../app/store";

interface JobOfferState {
  data: JobOfferModel[];
}

const initialState: JobOfferState = {
  data: [],
};

export const jobOffersSlice = createSlice({
  name: "jobOffers",
  initialState,
  reducers: {
    setJobOffers: (state, action: PayloadAction<JobOfferModel[]>) => {
      state.data = action.payload;
    },
  },
});

export const { setJobOffers } = jobOffersSlice.actions;

export const selectJobOffers = (state: RootState) => state.jobOffers;

export default jobOffersSlice.reducer;
