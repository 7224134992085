import { Link } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import { MdKeyboardArrowLeft } from "react-icons/md";

import { MenuSubNavModel } from "../../models/MenuSubNavModel";

import "./MobileMenuSubitem.css";

interface Props {
  subitem: MenuSubNavModel;
  setOpen: (open: boolean) => void;
  setMobileSubNav: (mobileSubNav: MenuSubNavModel | null) => void;
}

const MobileMenuSubitem = (props: Props) => {
  return (
    <Row>
      <Col className="mobile-menu-subitem">
        <Row>
          <Col md="auto" className="w-auto align-self-center">
            <span
              className="mobile-menu-subitem-icon"
              onClick={() => props.setMobileSubNav(null)}
            >
              <MdKeyboardArrowLeft />
            </span>
          </Col>
          <Col className="mobile-menu-subitem-title">{props.subitem.title}</Col>
        </Row>
        <Row className="mt-4">
          <Col>
            {props.subitem.subitems.map((item, index) => {
              return (
                <Row className="mt-3 mobile-menu-subitem-subitems" key={index}>
                  <Col>
                    <Link
                      to={item.path}
                      onClick={() => {
                        props.setOpen(false);
                      }}
                    >
                      {item.name}
                    </Link>
                  </Col>
                </Row>
              );
            })}
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default MobileMenuSubitem;
