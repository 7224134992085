import OpportunityGammaModel from "../models/OpportunityGammaModel";
import OpportunityModel from "../models/OpportunityModel";
import { get } from "./api/Api";

const endpoint = "/opportunity/";

const Opportunity = {
    getAllGroupedOpportunities: async (language: string) =>
        get<OpportunityGammaModel[]>(`${endpoint}getallgroupedopportunities/${language.toUpperCase()}`),

    getGroupedOpportunityByGamma: async (language: string, gammaId: string) =>
        get<OpportunityGammaModel>(`${endpoint}getallgroupedopportunitiesbygamma/${language}/${gammaId}`),
   
    getOpportunityById: async (language: string, id: string) =>
      get<OpportunityModel>(`${endpoint}GetOpportunityById/${language}/${id}`),
};

export default Opportunity;
