import { useEffect, useState, useRef } from "react";
import { match } from "react-router";
import { saveAs } from "file-saver";
import mime from "mime-types";
import { useTranslation, Trans } from "react-i18next";

import { FamilyModel } from "../../models/FamilyModel";
import { GalleryModel } from "../../models/GalleryModel";
import { TypeModel } from "../../models/TypeModel";

import { largeWidth } from "../../data/BreakpointsWidth";
import { NameSpace, families } from "../../data/AppLanguage";

import Util from "../../utils/Util";

import CategoryService from "../../services/api/Category";
import SolutionService from "../../services/api/Solution";

import FamilyComponent from "../../components/Family/Family";
import MobileFamily from "../../components/MobileFamily/MobileFamily";
import ContactPhoneForm from "../../components/ContactPhoneForm/ContactPhoneForm";
import MediaCollection from "../../components/MediaCollection/MediaCollection";
import Variant from "../../components/Variant/Variant";
import MultiCarousel from "../../components/MultiCarousel/MultiCarousel";
import Gallery from "../../components/Gallery/Gallery";
import ContactSpecificForm from "../../components/ContactSpecificForm/ContactSpecificForm";

interface Path {
  gamma: string;
  line: string;
  family: string;
  subfamily: string;
}

interface Props {
  match: match<Path>;
}

const Family = (props: Props) => {
  const [family, setFamily] = useState<FamilyModel>();
  const [solutions, setSolutions] = useState<GalleryModel[]>([]);
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);
  const params = props.match.params;
  const contactFormRef = useRef<HTMLElement>(null);

  const { t, i18n } = useTranslation([
    NameSpace.COMMON,
    NameSpace.CONTACTFORMS,
    NameSpace.NAVIGATION,
    NameSpace.ROUTES,
  ]);
  const language = Util.splitLanguage(i18n.language, 0);

  const handleWindowSizeChange = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      SolutionService.getSolutions(language, 3).then((res) => {
        setSolutions(res.data);
      });
      CategoryService.getFamily(
        params.gamma,
        params.line,
        params.family,
        language
      ).then((res) => {
        document.title = Util.setCategoryDocumentTitle(
          res.data.ancestors.map((a) => a.name),
          res.data.name
        );
        setFamily(res.data);
      });

      window.addEventListener("resize", handleWindowSizeChange);
    }

    return () => {
      mounted = false;

      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, [props.match.path, params, language]);

  const downloadDocument = (item: TypeModel) => {
    if (family?.id) {
      CategoryService.getFamilyDocument(
        family.id,
        parseInt(item.id),
        language
      ).then((res) => {
        if (res.data)
          saveAs(
            res.data,
            `${family?.path}_${item.id}_${language}.${mime.extension(
              res.data.type
            )}`
          );
      });
    }
  };

  const askInformation = () => {
    if (contactFormRef.current) {
      contactFormRef.current.scrollIntoView({
        block: "start",
        behavior: "smooth",
      });
    }
  };

  const familyDesktop = (
    <FamilyComponent
      family={family}
      downloadDocument={downloadDocument}
      askInformation={askInformation}
    />
  );

  const familyMobile = (
    <MobileFamily
      family={family}
      downloadDocument={downloadDocument}
      askInformation={askInformation}
    />
  );

  return (
    <>
      {windowWidth >= largeWidth ? familyDesktop : familyDesktop}
      {/* {windowWidth >= largeWidth ? familyDesktop : familyMobile} */}
      {/* <ContactPhoneForm />
      {family?.subfamilies.length ? (
        <Variant subfamilies={family.subfamilies} />
      ) : null} */}
      {family?.mediaCollection.length ? (
        <article className="mt-7">
          <MediaCollection mediaCollection={family.mediaCollection} />
        </article>
      ) : null}
      {family?.siblings.length ? (
        <MultiCarousel
          items={family.siblings}
          title={t(`${families}v1`, { ns: NameSpace.PAGES })}
          className="mt-7"
        />
      ) : null}
      {solutions.length ? (
        <Gallery
          header={
            <Trans i18nKey="solutions" ns={NameSpace.PAGES}>
              Galeria de <br /> Soluções
            </Trans>
          }
          path={t("solutions", { ns: NameSpace.ROUTES })}
          galleryItems={solutions}
          type
          className="mt-7"
          border
          galleryItemsColSpec={{ md: 9 }}
        />
      ) : null}
      <ContactSpecificForm
        category={family}
        className="mt-10"
        ref={contactFormRef}
      />
    </>
  );
};

export default Family;
