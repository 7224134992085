import { Row, Col, Card, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { MdLocationOn } from "react-icons/md";
import { useTranslation } from "react-i18next";

import { JobOfferModel } from "../../models/JobOfferModel";

import { NameSpace, buttons } from "../../data/AppLanguage";

import Util from "../../utils/Util";

import "./JobOffer.css";

interface Props {
  jobOffer: JobOfferModel;
  type?: boolean;
  path: string;
}

const JobOffer = (props: Props) => {
  const { t, i18n } = useTranslation([NameSpace.COMMON]);
  const language = Util.splitLanguage(i18n.language, 0);

  return (
    <Card className="joboffer shadow mb-5 bg-white rounded">
      <Row className="no-gutters">
        <Col md={5}>
          <Card.Img
            variant="top"
            src={props.jobOffer.media.path}
            className="joboffer-media"
          />
        </Col>
        <Col md={7} className="joboffer-wrapper">
          <Card.Body className="d-flex flex-column h-100">
            <Card.Title className="joboffer-wrapper-title">
              {props.jobOffer.title}
            </Card.Title>
            <Card.Text className="joboffer-wrapper-date mt-4 mb-0">
              {props.jobOffer.date}
            </Card.Text>
            <Card.Text className="joboffer-wrapper-type mb-0">
              {props.jobOffer.jobType.name}
            </Card.Text>
            <Card.Text className="joboffer-wrapper-location">
              <MdLocationOn /> {props.jobOffer.location.name}
            </Card.Text>
            <div className="mt-auto text-right">
              <Button
                as={Link}
                to={`/${language}/${props.path}/${props.jobOffer.path}`}
                variant="primary"
                className="joboffer-wrapper-button"
              >
                {t(`${buttons}findOutMore`)}
              </Button>
            </div>
          </Card.Body>
        </Col>
      </Row>
    </Card>
  );
};

export default JobOffer;
