import { SocialMediaModel } from "../../../models/SocialMediaModel";

import "../SocialMediaCollection.css";

interface Props {
  social?: SocialMediaModel;
}

const LinkedIn = (props: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 29.24 29.24"
      className={`social-media-collection ${props.social?.wrapperClassName}`}
    >
      <title>{"LinkedIn"}</title>
      <g id="prefix__Camada_2" data-name="Camada 2">
        <g id="prefix__ELEMENTS">
          <rect
            width={29.24}
            height={29.24}
            rx={7.65}
            transform="rotate(90 14.62 14.62)"
          />
          <path
            d="M8.49 11.9h2.83V21H8.49zm1.42-4.52A1.64 1.64 0 118.26 9a1.64 1.64 0 011.65-1.62M13.1 11.9h2.71v1.24a3 3 0 012.68-1.47c2.87 0 3.39 1.89 3.39 4.34v5h-2.79v-4.44c0-1.05 0-2.41-1.47-2.41s-1.7 1.15-1.7 2.34V21H13.1z"
            className={props.social?.overlayClassName}
          />
        </g>
      </g>
    </svg>
  );
};

export default LinkedIn;
