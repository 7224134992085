import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import SwiperCore, { Navigation, Mousewheel } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { MdArrowBack, MdArrowForward } from "react-icons/md";
import { Box, Button, CircularProgress, Grid, IconButton, Typography } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import style from '../SubfamilyDataSheet.module.css';


import { breakpoints } from "../../../data/SwiperData";
import DataSheetModelResponse from "../../../models/DataSheetModelResponse";

import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import "./TabSubFamilyDataSheetsMobile.css";
import SubfamilyDataSheetModel from "../../../models/SubfamilyDataSheetModel";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Util from "../../../utils/Util";
import { NameSpace , contactForm} from "../../../data/AppLanguage";

SwiperCore.use([Navigation, Mousewheel]);

interface Props {
    subfamilyDataSheet: SubfamilyDataSheetModel;  
    generatePdf: (datahseet: DataSheetModelResponse) => void;
    askInformation: () => void;
    isLoading: boolean;
    setIsLoading: (bool : boolean) => void;

}

const TabSubFamilyDataSheetsMobile = (props: Props) => {
    const [selectedItemIndex, setSelectedItemIndex ] = useState<number>(0);                    
    const [hasCodeDataSheet, setHasCodeDataSheet] = useState<string>('');
    const Empty = "https://mm.mafirol.info/empty.png";

    const { t, i18n } = useTranslation([ NameSpace.ROUTES ]);
    const language = Util.splitLanguage(i18n.language, 0);
    const contactFormRef = useRef<HTMLElement>(null);    
    
  const slidesPerView = 4;

  return (
    <article className="datasheet mt-9">
      <Container className="datasheet-wrapper">
        <Tab.Container defaultActiveKey={`${props?.subfamilyDataSheet?.dataSheets[0]?.name}`}>
          <Row className="datasheet-wrapper-tabs justify-content-center">
            <Col
              xs={4}
              sm={4}
              md="auto"
              lg="auto"
              xl="auto"
              className="align-self-center"
            >
              <div className="datasheet-wrapper-tabs-button-prev">
                <MdArrowBack className="datasheet-wrapper-tabs-button-prev-icon" />
              </div>
            </Col>
            <Col
              xs={4}
              sm={4}
              md={10}
              lg={10}
              xl={10}
              className="datasheet-wrapper-tabs-col"
            >
              <Swiper
                mousewheel={
                  props.subfamilyDataSheet?.dataSheets?.length > slidesPerView ? true : false
                }
                navigation={{
                  prevEl: ".datasheet-wrapper-tabs-button-prev",
                  nextEl: ".datasheet-wrapper-tabs-button-next",
                }}
                centerInsufficientSlides
                breakpoints={breakpoints(slidesPerView)}
              >
                {props.subfamilyDataSheet?.dataSheets?.map((item, index) => {
                  return (
                    <SwiperSlide key={index}>
                      <Nav variant="pills" className="justify-content-center">
                        <Nav.Item onClick={() => setSelectedItemIndex(index)}>
                          <Nav.Link eventKey={item.subtitleText}>{item.subtitleText}</Nav.Link>
                        </Nav.Item>
                      </Nav>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </Col>
            <Col
              xs={4}
              sm={4}
              md="auto"
              lg="auto"
              xl="auto"
              className="align-self-center text-right"
            >
              <div className="datasheet-wrapper-tabs-button-next">
                <MdArrowForward className="datasheet-wrapper-tabs-button-next-icon" />
              </div>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
              <Tab.Content>
                {props.subfamilyDataSheet?.dataSheets.map((item, index) => {
                  return (
                    selectedItemIndex === (index) ? (
                        <>
                            <Typography sx={{ mt: 3 , color: '#8B939F' , fontSize: 20}} >
                                {item.subtitleText}
                            </Typography>
                            <Box display={'flex'} justifyContent={'center'} paddingBottom={3}>
                                <img
                                    src={item.urlImgCut ?? Empty}
                                    alt=""
                                    style={{ width: 400, maxWidth: "450px", 
                                    }}
                                />
                            </Box>
                            <div className={`${style.cardContainer}`}>
                                    {item && item?.listColor1 != 0 && item?.listColor1 && 
                                    <>                                           
                                        <div className="row mb-5">
                                                {item.listColor1 && item.listColor1.map((item, index) => {
                                                    return(
                                                        <div  className={style.cardColor}>
                                                            <img 
                                                            key={index}
                                                            className={style.imageColor}
                                                            src={item} alt="image" />
                                                        </div>
                                                    );
                                                    
                                                })}                            
                                        </div>                                        
                                    </>                             
                                    }
                                
                            </div>            
                            <Box marginTop={5} justifyContent={'center'} display={'flex'}>                          
                                <Button
                                className=""
                                startIcon={props.isLoading ? <CircularProgress sx={{ color: "#ffffff" }}/> : <FileDownloadOutlinedIcon />}                                                               
                                onClick={() =>  { 
                                    props.setIsLoading(true);
                                    setTimeout(() => {
                                        props.generatePdf(item);
                                    },1)
                                }}                                    
                                sx={{ 
                                    color: "green",
                                    backgroundColor: "#ffffff",
                                    border: "1px solid green",
                                    borderRadius: 1,                                                                                        
                                    margin: "20px",
                                    ":hover": { color: "white", backgroundColor: "green" }
                                }}
                                >                                
                                    <Typography>
                                        { props.isLoading ? t(`${contactForm}loading`, {ns: NameSpace.COMMON }) : t("dataSheets", {ns: NameSpace.PAGES }) }
                                    </Typography>
                                </Button> 
                          
                                <Button
                                startIcon={<AddIcon sx={{ ":hover" : { color: "#ffffff"}}}/>}
                                onClick={() => props.askInformation()}
                                sx={{ 
                                    color: "#28487A",
                                    backgroundColor: "#ffffff",
                                    border: "1px solid #28487A",
                                    borderRadius: 1,
                                    margin: "20px 0",
                                    ":hover": { color: "white", backgroundColor: "#28487A" }
                                }}
                                >
                                    <Typography>
                                        {t("knowMore", { ns: NameSpace.NAVIGATION })}
                                    </Typography>
                                </Button> 
                            </Box>
                            <Typography sx={{ mt: 3 , color: '#8B939F' , fontSize: 14}} >
                                Ref: {item.code}
                            </Typography>  

                            <Box sx={{ padding: '5px', mt:5, textAlign: 'left'}}>
                                {item?.title1 ?
                                    (
                                        <>
                                            <Typography component={'p'} variant="body2" sx={{ fontSize: "20px", color: "#345C8D", textTransform: "uppercase", marginTop: '5px'}} >
                                                {item?.title1}
                                            </Typography>
                                            <hr />
                                            <Typography component={'p'} variant="body2" sx={{ fontSize: "15px"}} dangerouslySetInnerHTML={{ __html: item?.paragraph1 }} />
                                        </>
                                    ) : null
                                }

                                {item?.title2 ?
                                    (
                                        <>
                                            <Typography component={'p'} variant="body2" sx={{ fontSize: "20px", color: "#345C8D", textTransform: "uppercase", marginTop: 5}} >
                                                {item?.title2}
                                            </Typography>
                                            <hr />
                                            <Typography component={'p'} variant="body2" sx={{ fontSize: "15px"}} dangerouslySetInnerHTML={{ __html: item?.paragraph2 }} />
                                        </>
                                    ) : null
                                }

                                {item?.title3 ?
                                    (
                                        <>
                                            <Typography component={'p'} variant="body2" sx={{ fontSize: "20px", color: "#345C8D", textTransform: "uppercase", marginTop: 5}} >
                                                {item?.title3}
                                            </Typography>
                                            <hr />
                                            <Typography component={'p'} variant="body2" sx={{ fontSize: "15px"}} dangerouslySetInnerHTML={{ __html: item?.paragraph3 }} />
                                        </>
                                    ) : null
                                }


                                {item?.title4 ?
                                    (
                                        <>
                                            <Typography component={'p'} variant="body2" sx={{ fontSize: "20px", color: "#345C8D", textTransform: "uppercase", marginTop: 5}} >
                                                {item?.title4}
                                            </Typography>
                                            <hr />
                                            <Typography component={'p'} variant="body2" sx={{ fontSize: "15px"}} dangerouslySetInnerHTML={{ __html: item?.paragraph4 }} />
                                        </>
                                    ) : null
                                }


                                {item?.title5 ?
                                    (
                                        <>
                                        <Typography component={'p'} variant="body2" sx={{ fontSize: "20px", color: "#345C8D", textTransform: "uppercase", marginTop: 5}} >
                                                {item?.title5}
                                            </Typography>
                                            <hr />
                                            <Typography component={'p'} variant="body2" sx={{ fontSize: "15px"}} dangerouslySetInnerHTML={{ __html: item?.paragraph5 }} />
                                        </>
                                    ) : null
                                }
                            </Box>
                        </>
                        ) :  null
                );
                })}
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </Container>
    </article>
  );
};

export default TabSubFamilyDataSheetsMobile;
