import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { FilterModel } from "../../models/FilterModel";

import { NameSpace, filters } from "../../data/AppLanguage";

import "./Filter.css";

interface Props {
  filter: FilterModel;
}

const Filter = (props: Props) => {
  const { t } = useTranslation([NameSpace.COMMON]);

  return (
    <>
      <Form.Label className="filter-label">{t(`${filters}title`)}</Form.Label>
      <Form.Control
        className="filter-select"
        as="select"
        onChange={(event) => {
          props.filter.setFilter(event.target.value);
        }}
      >
        <option value={t(`${filters}all`).toUpperCase()}>
          {t(`${filters}all`)}
        </option>
        {props.filter.types.map((item, index) => {
          return (
            <option value={item.id} key={index}>
              {item.name}
            </option>
          );
        })}
      </Form.Control>
    </>
  );
};

export default Filter;
