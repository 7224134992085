interface Props {
  className?: string;
  path: string;
}

const Video = (props: Props) => {
  return (
    <video loop autoPlay muted playsInline className={props.className}>
      <source src={props.path} type="video/mp4" />
      <source src={props.path} type="video/ogg" />O seu navegador não suporta o
      atributo video.
    </video>
  );
};

export default Video;
