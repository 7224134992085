import { useTranslation } from "react-i18next";

import { NameSpace } from "../../data/AppLanguage";

import "./Disclaimer.css";

interface Props {
  className: string;
}

const Disclaimer = (props: Props) => {
  const { t } = useTranslation([NameSpace.NAVIGATION]);

  return (
    <p className={`disclaimer ${props.className}`}>
      {t("disclaimer", { year: new Date().getUTCFullYear() })}
    </p>
  );
};

export default Disclaimer;
