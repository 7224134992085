import React from "react";
import {
  Container,
  Row,
  Col,
  Image, /* Collapse, Button */
  Button,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { NameSpace, buttons } from "../../data/AppLanguage";

import Util from "../../utils/Util";

import "./SolutionItem.css";

import Card from 'react-bootstrap/Card';
import { SolutionModel } from "../../models/SolutionModel";
import { GammaModel } from "../../models/GammaModel";

interface Props {
    gamma: GammaModel;
    path: string;
}

interface HtmlTagsRemoverProps {
  text: string;
}

const SolutionItem = (props: Props) => {
    const [hover, setHover] = React.useState(false);
    const changeHover = () => setHover(!hover);
  
    const {t, i18n } = useTranslation([NameSpace.COMMON]);
    const language = Util.splitLanguage(i18n.language, 0);

    const removeHtmlTags = ({ text }: HtmlTagsRemoverProps) => {
      let temp = document.createElement("div");
      temp.innerHTML = text;
      return temp.textContent || temp.innerText || "";
    }

    const handleLocalStorage = (id:string) => {
      const storage = localStorage;
      let newID = id;
      storage.setItem('gammaPath', newID);
    }

    return(        
        <div className="grid-container01">
            <Link to={`/${language}/${props.path}/${props.gamma.path}`} onClick={() => handleLocalStorage(props.gamma.path)}>   
            <Col>                
                    <div className="card01">
                        <img 
                        src={props.gamma.mediaSmall?.path}
                        alt={props.gamma.name}
                        className="card-image01"/>
                        <div className="card-body01">
                            <h2 className="card-title01"><b>{props.gamma.name}</b></h2>
                            {/* <p className="card-text01"> {removeHtmlTags({ text: props.gamma.name })}</p> */}
                        </div>
                    </div>                  
            </Col>
              
            </Link>
        </div>        
    );
}

export default SolutionItem;
