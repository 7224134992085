import { Toast } from "react-bootstrap";

import { useAppSelector } from "../../app/hooks";
import { selectError } from "../../features/error/errorSlice";

import "./ErrorMessage.css";

const ErrorMessage = () => {
  const error = useAppSelector(selectError);

  if (error.statusCode !== 0) {
    return (
      <aside>
        <Toast className="error-message">
          <Toast.Header className="error-message-header" closeButton={false}>
            <strong className="me-auto">Erro {error.statusCode}</strong>
          </Toast.Header>
          <Toast.Body className="error-message-body">
            {error.message}
          </Toast.Body>
        </Toast>
      </aside>
    );
  }
  return null;
};

export default ErrorMessage;
