import { useState } from "react";
import { Row, Col, Accordion, Card } from "react-bootstrap";
import { MdArrowDropUp, MdArrowDropDown } from "react-icons/md";
import { IoMdClock } from "react-icons/io";

import { LocationWeekDayModel } from "../../models/LocationWeekDayModel";

import "./LocationWeekDay.css";

interface Props {
  weekDays: LocationWeekDayModel[];
}

const LocationWeekDay = (props: Props) => {
  const [clicked, setClicked] = useState(false);

  return (
    <Row className="location-week-day mt-1">
      <Col>
        <Accordion>
          <Card className="border-0">
            <Card.Body className="p-0" onClick={() => setClicked(!clicked)}>
              <Accordion.Toggle
                eventKey="0"
                className="border-0 p-0 bg-transparent"
              >
                <IoMdClock />{" "}
                <span className="location-week-day-header contact-locations-gray contact-locations-text">
                  {props.weekDays[0].weekDay}
                </span>
                {clicked ? (
                  <MdArrowDropUp className="location-week-day-arrow" />
                ) : (
                  <MdArrowDropDown className="location-week-day-arrow" />
                )}
              </Accordion.Toggle>
            </Card.Body>
            {props.weekDays
              .slice(1, props.weekDays.length)
              .map((weekDay, weekDayIndex) => {
                return (
                  <Accordion.Collapse eventKey="0" key={weekDayIndex}>
                    <Card.Body className="contact-locations-gray contact-locations-text pb-0 pt-0">
                      {weekDay.weekDay}
                    </Card.Body>
                  </Accordion.Collapse>
                );
              })}
          </Card>
        </Accordion>
      </Col>
    </Row>
  );
};

export default LocationWeekDay;
