import { SchemaOf, object, string, boolean, mixed } from "yup";
import byteSize from "byte-size";
import i18n from "i18next";

import { JobOfferApplicationFormModel } from "../models/JobOfferApplicationFormModel";

import { NameSpace } from "../data/AppLanguage";

import {
  jobApplicationExtension,
  jobApplicationMaxSize,
} from "../data/JobApplicationExtension";

const JobOfferApplicationFormSchema =
  (): SchemaOf<JobOfferApplicationFormModel> =>
    object({
      name: string()
        .trim()
        .required(i18n.t("required", { ns: NameSpace.VALIDATION }))
        .min(
          2,
          i18n.t("nameMinLength", { ns: NameSpace.VALIDATION, number: 2 })
        )
        .max(
          255,
          i18n.t("nameMaxLength", { ns: NameSpace.VALIDATION, number: 255 })
        ),
      email: string()
        .trim()
        .required(i18n.t("required", { ns: NameSpace.VALIDATION }))
        .email(i18n.t("emailInvalid", { ns: NameSpace.VALIDATION })),
      phoneNumber: string()
        .trim()
        .validPhoneNumber(
          i18n.t("phoneNumberInvalid", { ns: NameSpace.VALIDATION })
        )
        .required(),
      dateOfBirth: string()
        .trim()
        .validDateOfBirth(
          i18n.t("dateOfBirthInvalid", { ns: NameSpace.VALIDATION })
        )
        .required(),
      location: string()
        .required(i18n.t("required", { ns: NameSpace.VALIDATION }))
        .min(
          2,
          i18n.t("locationMinLength", { ns: NameSpace.VALIDATION, number: 2 })
        )
        .max(
          255,
          i18n.t("locationMaxLength", { ns: NameSpace.VALIDATION, number: 255 })
        ),
      curriculumVitae: mixed()
        .validCurriculumVitae(
          i18n.t("curriculumVitaeMaxLength", {
            ns: NameSpace.VALIDATION,
            number: byteSize(jobApplicationMaxSize),
          }),
          i18n.t("curriculumVitaeRequired", {
            ns: NameSpace.VALIDATION,
            extensions: jobApplicationExtension.join(","),
          })
        )
        .required(),
      message: string()
        .trim()
        .required(i18n.t("required", { ns: NameSpace.VALIDATION })),
      privacyPolicy: boolean()
        .required()
        .oneOf([true], i18n.t("required", { ns: NameSpace.VALIDATION })),
    });

export default JobOfferApplicationFormSchema;
