import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { GammaModel } from "../../models/GammaModel";

import Util from "../../utils/Util";

interface Props {
  item: GammaModel;
  handledGamma?: GammaModel;
  hover: boolean;
  setImage: (gamma: GammaModel) => void;
  setOpen: (open: boolean) => void;
}

const MenuRightItem = (props: Props) => {
  const { i18n } = useTranslation();
  const language = Util.splitLanguage(i18n.language, 0);

  return (
    <>
      <Row
        className={`item justify-content-end ${
          props.hover && props.item === props.handledGamma
            ? "item-image-color"
            : ""
        } ${
          (props.hover && props.item !== props.handledGamma) ||
          !props.item.mediaBig
            ? "item-color"
            : ""
        }`}
      >
        <Col xl={10} className="text-right">
          <Link
            to={`/${language}/${props.item.path}`}
            // onMouseOver={() =>
            //   props.item.mediaBig && props.setImage(props.item)
            // }
            // onMouseOut={() => props.item.mediaBig && props.setImage(props.item)}
            onClick={() => props.setOpen(false)}
          >
            {props.item.name}
          </Link>
        </Col>
      </Row>
    </>
  );
};

export default MenuRightItem;
