import { Row, Col } from "react-bootstrap";
import { FaPhone } from "react-icons/fa";
import { GiSelect } from "react-icons/gi";
import { useTranslation } from "react-i18next";

import { LocationModel } from "../../models/LocationModel";

import { NameSpace } from "../../data/AppLanguage";

import LocationWeekDay from "../LocationWeekDay/LocationWeekDay";

import "./Location.css";

interface Props {
  location: LocationModel;
}

const Location = (props: Props) => {
  const { t } = useTranslation([NameSpace.PAGES]);

  return (
    <Row className="location mt-3">
      <Col>
        <Row>
          <Col className="location-region">{props.location.region}</Col>
        </Row>
        <Row className="mt-2">
          <Col className="location-gray location-text">
            {props.location.address}
          </Col>
        </Row>
        <Row className="mt-1">
          <Col>
            <FaPhone className="location-icon" />{" "}
            <span className="location-phone-number location-text">
              {props.location.phoneNumber}
            </span>
          </Col>
        </Row>
        {props.location.weekDays ? (
          <LocationWeekDay weekDays={props.location.weekDays} />
        ) : null}
        <Row className="mt-1">
          <Col>
            <GiSelect className="location-icon" />{" "}
            <span className="location-gray location-text">
              {props.location.plusCode}
            </span>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col className="location-technical-assistance-title">
            {t("technicalAssistance")}
          </Col>
        </Row>
        <Row className="mt-1">
          <Col>
            <FaPhone className="location-icon" />{" "}
            <span className="location-technical-assistance location-text">
              {props.location.technicalAssistance}
            </span>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default Location;
