import { ThunkAction, AnyAction } from "@reduxjs/toolkit";

import { RootState, store } from "../../app/store";
import { setJobOffers } from "../joboffers/joboffersSlice";

import RecruitmentService from "../../services/api/Recruitment";

export const getJobOffers =
  (): ThunkAction<void, RootState, unknown, AnyAction> => async (dispatch) => {
    RecruitmentService.getJobOffers().then((res) => {
      dispatch(setJobOffers(res.data));
    });
  };

store.dispatch(getJobOffers);
