import { FilterModel } from "../../models/FilterModel";
import { JobOfferModel } from "../../models/JobOfferModel";
import { PaginationModel } from "../../models/PaginationModel";
import Pagination from "../Pagination/Pagination";
import Util from "../../utils/Util";
import {
    Container,
    Row,
    Col,
    Image, /* Collapse, Button */
    Button,
  } from "react-bootstrap";
import "./ImageGallery.css";
import { useTranslation } from "react-i18next";
import { NameSpace } from "../../data/AppLanguage";
import BreadcrumbPath from "../BreadcrumbPath/BreadcrumbPath";
import GammaItem from "../GammaItem/GammaItem";
import { GammaModel } from "../../models/GammaModel";
import SolutionItem from "../SolutionItem/SolutionItem";


interface Props {
   // filter: FilterModel;
    gammas: GammaModel[];
    path: string;
    pagination?: PaginationModel;
    className?: string;
}



const ImageGallery = (props: Props) => {

    const { t, i18n } = useTranslation([
        NameSpace.COMMON,
        NameSpace.GLOSSARY,
        NameSpace.NAVIGATION,
        NameSpace.ROUTES,
    ]);


     const language = Util.splitLanguage(i18n.language, 0);
    
    const solutionsPath = t("solutions", { ns: NameSpace.ROUTES });
    const solutionsTitle = t("solutions", { ns: NameSpace.NAVIGATION });
    const imageGalleryPath = t("imageGallery", { ns: NameSpace.ROUTES });
    const ImageGalleryTitle = t("imageGallery", { ns: NameSpace.NAVIGATION });

    return(
        <>  
            
            <article className="event mt-5">
                <div className="container p-0">
                    <h4 className="mt-8 mb-0">{t("imageGallery", { ns: NameSpace.NAVIGATION })}</h4>
                    <hr/>
                    <BreadcrumbPath
                        ancestors={[
                        {
                            name: solutionsTitle,
                            path: solutionsPath,
                        },
                        {
                            name: ImageGalleryTitle,
                            path: imageGalleryPath
                        }
                        ]}                
                        className="pt-2 mb-0"
                    />
                        {props.gammas.length > 0 ? (
                            <div className="event-wrapper row justify-content-center align-items-center teste001 mt-0">            
                            {/* -------------------------------------------- ORIGINAL INÍCIO ------------------------------------------------------------ */}
                                <Row className="justify-content-left event"> 
                                {props.gammas.map((item, index) => {
                                    return (
                                    <Col
                                        xl={4}
                                        lg={5}
                                        md={6}
                                        sm={10}
                                        key={index}
                                        className="d-flex justify-content-center mt-0 mb-5"
                                    >
                                        <SolutionItem gamma={item} path={props.path} />
                                        {/* <GammaItem gamma={item} /> */}
                                    </Col>
                                    );
                                })}
                                </Row>
                            {/* -------------------------------------------- ORIGINAL FIM --------------------------------------------------------------- */}    
                            
                        </div>
                        ) : (
                            <div className="alert alert-warning" role="alert">
                                <h5 className="text-align-center dados p-0 m-0">
                                    {t("data", {ns: NameSpace.PAGES})}
                                </h5>
                            </div>
                            )}
                        
                        {props.pagination ? <Pagination pagination={props.pagination} /> : null}
                        <br/>
                </div>
            </article>
        </>
    );
}

export default ImageGallery;