import { Fragment } from "react";
import { Row, Col, Image } from "react-bootstrap";

import { CertificateModel } from "../../models/CertificateModel";

import "./Certificate.css";

interface Props {
  certificateCollection: CertificateModel[];
}

const Certificate = (props: Props) => {
  const certificateCollection = [];

  const col = (certificate: CertificateModel) => {
    const color = certificate.color;

    return certificate.name.length >= 70 ? (
      <>
        <Col xs={12} md="auto" className="certificate-media-col">
          <Image
            src={certificate.media.path}
            alt={certificate.name}
            className="certificate-media"
            onClick={() =>
              window.open(
                certificate.certificateMedia.path,
                "_blank",
                "noopener, noreferrer"
              )
            }
          />
        </Col>
        <Col
          md={4}
          className="certificate-text align-self-center"
          style={{ color }}
          onClick={() =>
            window.open(
              certificate.certificateMedia.path,
              "_blank",
              "noopener, noreferrer"
            )
          }
        >
          {certificate.name}
        </Col>
      </>
    ) : (
      <Col xs={12} md={4} className="certificate-media-col">
        <div
          className="certificate-text"
          style={{ color }}
          onClick={() =>
            window.open(
              certificate.certificateMedia.path,
              "_blank",
              "noopener, noreferrer"
            )
          }
        >
          {certificate.name}
        </div>
        <Image
          src={certificate.media.path}
          alt={certificate.name}
          className="certificate-media"
          onClick={() =>
            window.open(
              certificate.certificateMedia.path,
              "_blank",
              "noopener, noreferrer"
            )
          }
        />
      </Col>
    );
  };

  if (props.certificateCollection.length) {
    for (let i = 0; i < props.certificateCollection.length; i += 2) {
      var c = props.certificateCollection;
      certificateCollection.push(
        <Row className="certificate mt-5">
          {col(c[i])}
          {c[i + 1] ? col(c[i + 1]) : null}
        </Row>
      );
    }
  }

  return (
    <>
      {certificateCollection.map((item, index) => {
        return <Fragment key={index}>{item}</Fragment>;
      })}
    </>
  );
};

export default Certificate;
