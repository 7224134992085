export enum ContentPageType {
  ABOUTUS = "AboutUs",
  QUALITYCERTIFICATION = "QualityCertification",
  SOLUTIONS = "Solutions",
  NEWSEVENTS = "NewsEvents",
  RECRUITMENT = "Recruitment",
  DOWNLOADAREA = "DownloadArea",
  CONTACT = "Contact",
  PRIVACYPOLICY = "PrivacyPolicy",
}
