import { useState } from "react";
import {
  Container,
  Row,
  Col,
  Image /* Collapse, Button */,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { GammaModel } from "../../models/GammaModel";

import { NameSpace } from "../../data/AppLanguage";

import Util from "../../utils/Util";

import "./GammaItem.css";

interface Props {
  gamma: GammaModel;
}

const GammaItem = (props: Props) => {
  const [hover, setHover] = useState(false);
  const changeHover = () => setHover(!hover);

  const { i18n } = useTranslation([NameSpace.COMMON]);
  const language = Util.splitLanguage(i18n.language, 0);

  return (
    <Container
      className="gamma-item"
      onMouseOver={changeHover}
      onMouseOut={changeHover}
    >
      <Row>
        <Col className="p-0">
          <Link to={`/${language}/${props.gamma.path}`}>
            <div className="gamma-item-wrapper">
              <div className="gamma-item-wrapper-effect">
                <Image
                  src={props.gamma.mediaSmall.path}
                  alt={props.gamma.name}
                  className="gamma-item-wrapper-media"
                  fluid
                />
                <div className="gamma-item-wrapper-title">
                  {props.gamma.name}
                </div>
              </div>
            </div>
          </Link>
        </Col>
      </Row>
      {/* Commented in case it would be needed again */}
      {/* {props.gamma.lines.length ? (
        <Collapse in={hover}>
          <Row>
            {props.gamma.lines.map((item, index) => {
              return (
                <Col className="gamma-item-buttons mt-3 pl-2 pr-2" key={index}>
                  <Button
                    as={Link}
                    to={`/${language}/${props.gamma.path}/${item.path}`}
                    variant="outline-dark"
                    className="gamma-item-buttons-button"
                  >
                    {item.name}
                  </Button>
                </Col>
              );
            })}
          </Row>
        </Collapse>
      ) : null} */}
    </Container>
  );
};

export default GammaItem;
