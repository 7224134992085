import { SolutionModel } from "../../models/SolutionModel";

import { get } from "./Api";

const endpoint = "/solutionsback/";

const Solutions = {
  getSolutions: async (language: string) =>
    get<SolutionModel[]>(endpoint, { params: { language } }),

    getSolution: async (path: string) =>
    get<SolutionModel>(`${endpoint}solution/${path}`),
   
    getGammaSolutions: async (gammaPath: string, language: string) =>
      get<SolutionModel[]>(`${endpoint}${gammaPath}/${language}`),
};

export default Solutions;
