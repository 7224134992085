import { SocialMediaModel } from "../../../models/SocialMediaModel";

import "../SocialMediaCollection.css";

interface Props {
  social?: SocialMediaModel;
}

const Facebook = (props: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 29.24 29.24"
      className={`social-media-collection ${props.social?.wrapperClassName}`}
    >
      <title>{"Facebook"}</title>
      <g data-name="Camada 2">
        <path d="M0 21.59V7.65A7.67 7.67 0 017.65 0h13.94a7.67 7.67 0 017.65 7.65v13.94a7.67 7.67 0 01-7.65 7.65h-4.68l-2.46-.6-2.11.6H7.65A7.67 7.67 0 010 21.59z" />
        <path
          d="M20.31 19.33L21 15.1h-4.09v-2.74a2.11 2.11 0 012.38-2.28h1.84v-3.6a22.08 22.08 0 00-3.27-.29c-3.34 0-5.52 2-5.52 5.69v3.22H8.62v4.23h3.72v9.91h4.57v-9.91z"
          className={props.social?.overlayClassName}
        />
      </g>
    </svg>
  );
};

export default Facebook;
