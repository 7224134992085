import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { ContentPageModel } from "../../models/ContentPageModel";

import { NameSpace } from "../../data/AppLanguage";
import { ContentPageType } from "../../enums/ContentPageType";

import Util from "../../utils/Util";

import ContentPageService from "../../services/api/ContentPage";

import MainTitle from "../../components/MainTitle/MainTitle";
import BreadcrumbPath from "../../components/BreadcrumbPath/BreadcrumbPath";
import SpontaneousApplicationForm from "../../components/SpontaneousApplicationForm/SpontaneousApplicationForm";

import "./SpontaneousApplication.css";

const SpontaneousApplication = () => {
  const [mounted, setMounted] = useState(true);
  const [group, setGroup] = useState<ContentPageModel>();

  const { t, i18n } = useTranslation([
    NameSpace.COMMON,
    NameSpace.CONTACTFORMS,
    NameSpace.NAVIGATION,
    NameSpace.PAGES,
    NameSpace.ROUTES,
  ]);
  const language = Util.splitLanguage(i18n.language, 0);
  const recruitmentPath = t("recruitment", { ns: NameSpace.ROUTES });
  const recruitmentTitle = t("recruitment", { ns: NameSpace.NAVIGATION });
  const spontaneousTitle = t("spontaneousApplication", { ns: NameSpace.PAGES });

  useEffect(() => {
    if (mounted) {
      ContentPageService.getContentPages(
        ContentPageType.RECRUITMENT,
        language
      ).then((res) => {
        document.title = Util.setDocumentTitle(
          `${recruitmentTitle} | ${spontaneousTitle}`
        );
        setGroup(res.data);
      });
    }

    return () => {
      setMounted(false);
    };
  }, [mounted, recruitmentTitle, spontaneousTitle, language]);

  return (
    <>
      <MainTitle
        media={group?.media}
        name={spontaneousTitle}
        height="100vh"
        className="mb-0"
      />
      <BreadcrumbPath
        ancestors={[
          {
            name: recruitmentTitle,
            path: recruitmentPath,
          },
        ]}
        current={spontaneousTitle}
        className="mt-5"
      />
      <SpontaneousApplicationForm className="mt-2" />
    </>
  );
};

export default SpontaneousApplication;
