import DOMPurify from "dompurify";

import "./Description.css";

interface Props {
  text: string;
  className?: string;
}

const Description = (props: Props) => {
  return (
    <div>
      <div
        className={props.className}
        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(props.text) }}
      />
    </div>
  );
};

export default Description;
