import { useState } from "react";
import { DocumentFamilyModel } from "../../models/DocumentFamilyModel";

import DownloadAreaListItem from "../DownloadAreaListItem/DownloadAreaListItem";
import Pagination from "../Pagination/Pagination";

import "./DownloadAreaList.css";

interface Props {
  downloadList: DocumentFamilyModel[];
  currentDownloadListItem: DocumentFamilyModel | null;
  setDownloadList: (family: DocumentFamilyModel[]) => void;
  setCurrentDownloadListItem: (currentItem: DocumentFamilyModel | null) => void;
}

const DownloadAreaList = (props: Props) => {
  const defaultItemsPerPage = 12;
  const defaultCurrentPage = 1;

  const [currentPage, setCurrentPage] = useState(defaultCurrentPage);
  const [itemsPerPage, setItemsPerPage] = useState(defaultItemsPerPage);

  const indexLastItem = currentPage * itemsPerPage;
  const indexFirstItem = indexLastItem - itemsPerPage;
  const totalPages = Math.ceil(props.downloadList.length / itemsPerPage);
  const paginatedItems = props.downloadList.slice(
    indexFirstItem,
    indexLastItem
  );

  const setPagination = (page: number) => {
    setCurrentPage(page);
  };

  return (
    <>
      {paginatedItems.map((item, index) => {
        return (
          <DownloadAreaListItem
            downloadItem={item}
            downloadList={paginatedItems}
            currentDownloadListItem={props.currentDownloadListItem}
            setDownloadList={props.setDownloadList}
            setCurrentDownloadListItem={props.setCurrentDownloadListItem}
            index={index}
            key={index}
          />
        );
      })}
      <Pagination
        pagination={{
          totalPages,
          defaultCurrentPage,
          currentPage,
          setPagination,
          defaultItemsPerPage,
          itemsPerPage,
          setItemsPerPage,
        }}
      />
    </>
  );
};

export default DownloadAreaList;
