import { Control, Controller, FieldError } from "react-hook-form";
import PhoneInput from "react-phone-input-2";
import { useTranslation } from "react-i18next";

import { NameSpace, countries, contactForm } from "../../data/AppLanguage";

import Util from "../../utils/Util";

import "react-phone-input-2/lib/bootstrap.css";
import "./PhoneNumberForm.css";

interface Props {
  control: Control<any>;
  submitClicked: number;
  errors: FieldError | undefined;
  errorsMessage?: string | undefined;
  inputClassName?: string;
  disableDropdown?: boolean;
}

const PhoneNumberForm = (props: Props) => {
  const { t, i18n } = useTranslation([NameSpace.COMMON]);

  return (
    <>
      <Controller
        control={props.control}
        name="phoneNumber"
        render={({ field }) => (
          <PhoneInput
            {...field}
            placeholder={t(`${contactForm}phoneNumber`, {
              ns: NameSpace.COMMON,
            })}
            inputProps={{
              name: "phoneNumber",
            }}
            country={Util.splitLanguage(i18n.language, 1).toLowerCase()}
            preferredCountries={countries}
            enableAreaCodes
            enableSearch
            autocompleteSearch
            searchPlaceholder={t("search", { ns: NameSpace.PAGES })}
            searchNotFound={t("searchNotFound", { ns: NameSpace.PAGES })}
            containerClass="phone-number-form-container"
            inputClass={`phone-number-form-input ${props.inputClassName} ${
              props.submitClicked > 0 && !props.errors
                ? "is-valid"
                : !!props.errors
                ? "is-invalid"
                : ""
            }`}
            disableDropdown={props.disableDropdown}
            buttonClass={props.disableDropdown ? "dropdown-button-class" : ""}
          />
        )}
      />
      {props.errors && props.errorsMessage ? (
        <div className="phone-number-form-invalid text-danger">
          {props.errorsMessage}
        </div>
      ) : null}
    </>
  );
};

export default PhoneNumberForm;
