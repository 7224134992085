import { ContentPageModel } from "../../models/ContentPageModel";

import { ContentPageType } from "../../enums/ContentPageType";

import { get } from "./Api";

const endpoint = "/contentpages/";

const ContentPage = {
  getContentPages: async (type: ContentPageType, language: string) =>
    get<ContentPageModel>(`${endpoint}${type}`, {
      params: { language },
    }),
};

export default ContentPage;
