import { Container, Row, Col, Image } from "react-bootstrap";
import { useEffect, useState, useCallback } from "react";
import { match } from "react-router";
import { useTranslation } from "react-i18next";

import { ContentPageModel } from "../../models/ContentPageModel";

import { NameSpace, socialMedia } from "../../data/AppLanguage";
import { ContentPageType } from "../../enums/ContentPageType";

import Util from "../../utils/Util";

import { localePath } from "../../data/Routes";

import ContentPageService from "../../services/api/ContentPage";

import MainTitle from "../../components/MainTitle/MainTitle";
import ContactPhoneForm from "../../components/ContactPhoneForm/ContactPhoneForm";
import BreadcrumbPath from "../../components/BreadcrumbPath/BreadcrumbPath";
import Title from "../../components/Title/Title";
import MobileDivider from "../../components/MobileDivider/MobileDivider";
import SocialMedia from "../../components/SocialMedia/SocialMedia";
import Description from "../../components/Description/Description";
import MediaViewer from "../../components/MediaViewer/MediaViewer";
import Certificate from "../../components/Certificate/Certificate";

import "./Group.css";

interface Props {
  match: match;
}

const Group = (props: Props) => {
  const [group, setGroup] = useState<ContentPageModel>();
  const [contentPageType, setContentPageType] = useState<ContentPageType>(
    ContentPageType.ABOUTUS
  );
  const [title, setTitle] = useState("");
  const [currentImage, setCurrentImage] = useState(0);
  const [visible, setVisible] = useState(false);

  const { t, i18n } = useTranslation([
    NameSpace.COMMON,
    NameSpace.NAVIGATION,
    NameSpace.ROUTES,
  ]);
  const language = Util.splitLanguage(i18n.language, 0);

  const openImageViewer = useCallback((index) => {
    setVisible(true);
    setCurrentImage(index);
  }, []);

  const closeImageViewer = () => {
    setVisible(false);
    setCurrentImage(0);
  };

  var images: { src: string }[] = [];

  const socialMediaComponent = (
    <SocialMedia
      title={t(`${socialMedia}v2`, { ns: NameSpace.COMMON })}
      className="social-media-terciary"
      titleClassName="social-media-terciary-title"
      social={{
        wrapperClassName: "social-media-bg-gray",
        overlayClassName: "social-media-light",
      }}
    />
  );

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      if (
        props.match.path ===
        `${localePath}/${t("aboutUs", { ns: NameSpace.ROUTES })}`
      ) {
        setContentPageType(ContentPageType.ABOUTUS);
        setTitle(t("aboutUs", { ns: NameSpace.NAVIGATION }));
      } else {
        setContentPageType(ContentPageType.QUALITYCERTIFICATION);
        setTitle(t("qualityCertification", { ns: NameSpace.NAVIGATION }));
      }

      document.title = Util.setDocumentTitle(title);

      ContentPageService.getContentPages(contentPageType, language).then(
        (res) => {
          setGroup(res.data);
        }
      );
    }

    return () => {
      mounted = false;
    };
  }, [contentPageType, t, title, props.match.path, language]);

  return (
    <>
      <MainTitle media={group?.media} name={title} height="100vh" />
      {/* <ContactPhoneForm /> */}
      <BreadcrumbPath current={title} />
      <article className="group mb-5">
        <Container>
          <Row>
            {group?.title || group?.subtitle ? (
              <Col>
                {group?.title ? (
                  <Title title={group.title} className="title-subtitle" />
                ) : null}
                {group?.subtitle ? (
                  <Title title={group.subtitle} className="title-title" />
                ) : null}
              </Col>
            ) : null}
            <MobileDivider colSpec={{ xs: 12 }} dividerClassName="mt-5 mb-5" />
            <Col className="group-social-media-desktop flex-row-reverse">
              {socialMediaComponent}
            </Col>
          </Row>
        </Container>
        <Container className="group-body">
          <Row>
            {group?.body ? (
              <Col xs={12} md={group.mediaCollection.length > 0 ? 7 : 12}>
                <Description
                  text={group.body}
                  className="description-section"
                />
              </Col>
            ) : null}
            {group?.mediaCollection && group?.mediaCollection.length > 0 ? (
              <Col xs={12} md={5} className="group-media">
                <Row className="group-media-row">
                  {group.mediaCollection.map((item, index) => {
                    images.push({ src: item.path });
                    return (
                      <Col
                        md={12}
                        xl={6}
                        className="group-media-row-col"
                        key={index}
                      >
                        <Image
                          src={item.path}
                          alt={group.title}
                          onClick={() => {
                            openImageViewer(index);
                          }}
                          fluid
                          className="group-media-row-col-media"
                        />
                      </Col>
                    );
                  })}
                </Row>
                <MediaViewer
                  visible={visible}
                  onClose={closeImageViewer}
                  activeIndex={currentImage}
                  images={images}
                />
              </Col>
            ) : null}
          </Row>
          {group?.certificateCollection &&
          group?.certificateCollection.length ? (
            <Certificate certificateCollection={group.certificateCollection} />
          ) : null}
          <Row className="group-social-media-mobile mt-10">
            <Col>{socialMediaComponent}</Col>
          </Row>
        </Container>
      </article>
    </>
  );
};

export default Group;
