import { Container, Row, Col, ColProps } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Fragment } from "react";

import { FilterModel } from "../../models/FilterModel";
import { GalleryModel } from "../../models/GalleryModel";
import { PaginationModel } from "../../models/PaginationModel";

import { NameSpace } from "../../data/AppLanguage";

import Util from "../../utils/Util";

import Filter from "../Filter/Filter";
import GalleryItem from "../GalleryItem/GalleryItem";
import Pagination from "../Pagination/Pagination";
import MobileDivider from "../MobileDivider/MobileDivider";

import "./Gallery.css";

interface Props {
  header?: JSX.Element;
  filter?: FilterModel;
  pagination?: PaginationModel;
  path: string;
  galleryItems: GalleryModel[];
  type?: boolean;
  className?: string;
  cardClassName?: string;
  border?: boolean;
  galleryItemsColSpec: ColProps;
}

const Gallery = (props: Props) => {
  const { t, i18n } = useTranslation([NameSpace.PAGES]);
  const language = Util.splitLanguage(i18n.language, 0);

  return (
    <article
      className={`gallery ${props.border ? "gallery-border" : ""} ${
        props.className
      } pt-5`}
    >
      <Container>
        {props.filter ? (
          <Row className="mb-5 justify-content-end">
            <Col xs={12} md={3} className="text-right">
              <Filter filter={props.filter} />
            </Col>
          </Row>
        ) : null}
        <Row className="gallery-wrapper">
          {props.header ? (
            <Col xs={12} md={3} className="gallery-wrapper-header">
              <Row
                className="gallery-wrapper-header-row"
                as={Link}
                to={`/${language}/${props.path}`}
              >
                <Col className="gallery-wrapper-header-row-col">
                  <Row>
                    <Col
                      className="gallery-wrapper-header-row-col-title"
                      md="auto"
                    >
                      <h5>{props.header}</h5>
                    </Col>
                  </Row>
                  <Row className="gallery-wrapper-header-row-col-all pb-3">
                    <Col md="auto">{t("all")}</Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          ) : null}
          <Col md={props.galleryItemsColSpec.md} className="gallery-items">
            <Row>
              {props.galleryItems.map((item, index) => {
                return (
                  <Fragment key={index}>
                    <Col
                      key={index}
                      xs={12}
                      md={6}
                      lg={4}
                      className="gallery-wrapper-items-item"
                    >
                      <GalleryItem
                        galleryItem={item}
                        type={props.type}
                        path={props.path}
                        cardClassName={props.cardClassName}
                      />
                    </Col>
                    <MobileDivider
                      colSpec={{ xs: 6 }}
                      dividerClassName="mt-5 mb-5 text-center"
                    />
                  </Fragment>
                );
              })}
            </Row>
          </Col>
        </Row>
      </Container>
      {props.pagination ? <Pagination pagination={props.pagination} /> : null}
    </article>
  );
};

export default Gallery;
