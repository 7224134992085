import { FilterModel } from "../../models/FilterModel";
import { JobOfferModel } from "../../models/JobOfferModel";
import { PaginationModel } from "../../models/PaginationModel";
import Pagination from "../Pagination/Pagination";
import Util from "../../utils/Util";
import {
    Container,
    Row,
    Col,
    Image, /* Collapse, Button */
    Button,
  } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./Solution.css";
import { useTranslation } from "react-i18next";
import { NameSpace } from "../../data/AppLanguage";
import SolutionItem from "../SolutionItem/SolutionItem";
import { SolutionModel } from "../../models/SolutionModel";
import BreadcrumbPath from "../BreadcrumbPath/BreadcrumbPath";


interface Props {
   // filter: FilterModel;
    solutions: SolutionModel[];
    path: string;
    pagination?: PaginationModel;
    className?: string;
}



const Solution = (props: Props) => {

    const { t, i18n } = useTranslation([
        NameSpace.COMMON,
        NameSpace.GLOSSARY,
        NameSpace.NAVIGATION,
        NameSpace.ROUTES,
    ]);

     const language = Util.splitLanguage(i18n.language, 0);
    
    const solutionsPath = t("solutions", { ns: NameSpace.ROUTES });
    const solutionsTitle = t("solutions", { ns: NameSpace.NAVIGATION });

    return(
        <>  
            <article className="event">           
                <div className="container mb-8 pt-5">
                    <h4>{t("solutions", { ns: NameSpace.NAVIGATION })}</h4>
                    <hr/>
                        <BreadcrumbPath
                            ancestors={[
                            {
                                name: solutionsTitle,
                                path: solutionsPath,
                            },
                            ]}                
                            className="pt-2 pb-0 pl-0 ml-0"
                        />
                                <Row className="justify-content-center teste01 pb-0">
                                        <div className="teste02">
                                            
                                                <Col>   
                                                    <Link
                                                        className="footer-wrapper-item"
                                                        to={`/${language}/${t("caseStudy", {ns: NameSpace.ROUTES})}`}
                                                        >                                                                                        
                                                            <div className="card02">                                                
                                                                <img 
                                                                src="https://img.freepik.com/free-photo/engineer-meeting-architectural-project-working-with-partner_1421-73.jpg?size=626&ext=jpg&ga=GA1.1.852090800.1683726689&semt=sph"
                                                                alt={t("caseStudy", { ns: NameSpace.NAVIGATION })}
                                                                className="card-image02"/>
                                                                <div className="card-body02">
                                                                    <h2 className="card-title02">{t("caseStudy", { ns: NameSpace.NAVIGATION })}</h2>                                       
                                                                </div>
                                                            </div>                                      
                                                    </Link>  
                                                </Col>                        
                                            
                                        </div>   
                                        <div className="teste02">
                                            
                                                <Col>
                                                    <Link
                                                        className="footer-wrapper-item"
                                                        to={`/${language}/${t("imageGallery", {ns: NameSpace.ROUTES})}`}
                                                        >
                                                            <div className="card02">
                                                                <img 
                                                                    src="https://img.freepik.com/premium-photo/supermarket-cart_11208-1204.jpg?size=626&ext=jpg&ga=GA1.2.852090800.1683726689&semt=sph"
                                                                    alt={t("gallery", { ns: NameSpace.NAVIGATION })}
                                                                    className="card-image02"/>
                                                                <div className="card-body02">
                                                                    <h2 className="card-title02">{t("gallery", { ns: NameSpace.NAVIGATION })}</h2>                                       
                                                                </div>
                                                            </div>
                                                    </Link>
                                                </Col>                        
                                            
                                        </div>   
                                    </Row>                 
                                
                
                            {/* {props.pagination ? <Pagination pagination={props.pagination} /> : null}
                            <br/> */}
                </div>
            </article>
        </>
    );
}

export default Solution;