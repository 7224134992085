import { match } from "react-router";
import { useState, useEffect } from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import { MdLocationOn } from "react-icons/md";
import { useTranslation } from "react-i18next";

import { JobOfferModel } from "../../models/JobOfferModel";

import { NameSpace } from "../../data/AppLanguage";

import Util from "../../utils/Util";

import RecruitmentService from "../../services/api/Recruitment";

import MainTitle from "../../components/MainTitle/MainTitle";
import BreadcrumbPath from "../../components/BreadcrumbPath/BreadcrumbPath";
import Description from "../../components/Description/Description";
import JobOfferApplicationForm from "../../components/JobOfferApplicationForm/JobOfferApplicationForm";

import "./JobOffer.css";

interface Path {
  path: string;
}

interface Props {
  match: match<Path>;
}

const JobOffer = (props: Props) => {
  const [jobOffer, setJobOffer] = useState<JobOfferModel>();

  const params = props.match.params;

  const { t } = useTranslation([
    NameSpace.COMMON,
    NameSpace.NAVIGATION,
    NameSpace.PAGES,
    NameSpace.ROUTES,
  ]);
  const recruitmentPath = t("recruitment", { ns: NameSpace.ROUTES });
  const recruitmentTitle = t("recruitment", { ns: NameSpace.NAVIGATION });
  const jobOffersPath = t("jobOffers", { ns: NameSpace.ROUTES });
  const jobOffersTitle = t("jobOffers", { ns: NameSpace.PAGES });

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      RecruitmentService.getJobOffer(params.path).then((res) => {
        document.title = Util.setDocumentTitle(
          `${recruitmentTitle} | ${jobOffersTitle} | ${res.data.title}`
        );
        setJobOffer(res.data);
      });
    }

    return () => {
      mounted = false;
    };
  }, [params.path, recruitmentTitle, jobOffersTitle]);

  return (
    <>
      <MainTitle
        media={jobOffer?.media}
        height="58vh"
        articleClassName="joboffer-maintitle"
      />
      <BreadcrumbPath
        ancestors={[
          {
            name: recruitmentTitle,
            path: recruitmentPath,
          },
          {
            name: jobOffersTitle,
            path: jobOffersPath,
          },
        ]}
        current={jobOffer?.title}
        className="pt-8"
      />
      <article>
        <Container className="joboffer">
          <Row>
            <Col md="auto" className="joboffer-media p-0">
              <Image src={jobOffer?.media.path} />
            </Col>
            <Col className="mt-4 text">
              <Row>
                <Col className="joboffer-title">{jobOffer?.title}</Col>
              </Row>
              <Row className="joboffer-divider">
                <Col>
                  <hr />
                </Col>
              </Row>
              <Row className="mt-5">
                <Col className="joboffer-date">{jobOffer?.date}</Col>
              </Row>
              <Row className="mt-1">
                <Col className="joboffer-type">{jobOffer?.jobType.name}</Col>
              </Row>
              <Row>
                {jobOffer?.companyId ? (
                  <Col className="joboffer-company mt-1">
                    {t("company", {
                      ns: NameSpace.PAGES,
                      name: jobOffer.companyId,
                    })}
                  </Col>
                ) : null}
              </Row>
              <Row>
                <Col className="joboffer-location mt-1">
                  <MdLocationOn /> {jobOffer?.location.name}
                </Col>
              </Row>
            </Col>
          </Row>
          {jobOffer?.body ? (
            <Row className="mt-5">
              <Col>
                <Description text={jobOffer.body} />
              </Col>
            </Row>
          ) : null}
          <Row className="joboffer-disclaimer">
            <Col>{t("jobOfferDisclaimer", { ns: NameSpace.PAGES })}</Col>
          </Row>
        </Container>
      </article>
      {jobOffer ? (
        <JobOfferApplicationForm
          jobOfferPath={jobOffer.path}
          className="mt-10"
        />
      ) : null}
    </>
  );
};

export default JobOffer;
