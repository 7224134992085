import { useTranslation } from "react-i18next";
import { NameSpace, socialMedia } from "../../data/AppLanguage";
import { useEffect, useState } from "react";
import SolutionsService from "../../services/api/Solutions";
import Util from "../../utils/Util";
import { EventModel } from "../../models/EventModel";
import Event from "../../components/Event/Event";
import Solution from "../../components/Solution/Solution";
import { TypeModel } from "../../models/TypeModel";
import { useAppDispatch } from "../../app/hooks";
import { SolutionModel } from "../../models/SolutionModel";
import i18n from "../../i18n";
import BreadcrumbPath from "../../components/BreadcrumbPath/BreadcrumbPath";


interface Props {   
    path: string;  
    className?: string;
}


const SolutionsComponent = (props: Props) => {
    const [solutions, setGetSolutions] = useState<SolutionModel[]>([]);

    const defaultItemsPerPage = 12;
    const defaultCurrentPage = 1;

  //const [solutionsTypes, setSolutionsTypes] = useState<TypeModel[]>([]);
  const [currentPage, setCurrentPage] = useState(defaultCurrentPage);
  const [itemsPerPage, setItemsPerPage] = useState(defaultItemsPerPage);
  const [filteredItems, setFilteredItems] = useState<SolutionModel[]>([]);
  const [filtered, setFiltered] = useState(false);
//   const jobOffers = useAppSelector(selectJobOffers);
  const dispatch = useAppDispatch();

  const indexLastItem = currentPage * itemsPerPage;
  const indexFirstItem = indexLastItem - itemsPerPage;
  const totalPages = Math.ceil(filteredItems.length / itemsPerPage);
  const paginatedItems = filteredItems.slice(indexFirstItem, indexLastItem);

    const { t, i18n } = useTranslation([
        NameSpace.COMMON,
        NameSpace.GLOSSARY,
        NameSpace.NAVIGATION,
        NameSpace.ROUTES,
    ]);
    

//---------------------------------------------------------------------------
const setPagination = (page: number) => {
    setCurrentPage(page);
  };

  const setFilter = (filter: string) => { 
    setItemsPerPage(defaultItemsPerPage);
  };


    const solutionsPath = t("solutions", { ns: NameSpace.ROUTES });

    const language = Util.splitLanguage(i18n.language, 0);

    useEffect(() => {
        let mounted = true;

        // if(mounted){
        //     SolutionsService.getSolutions(language).then((res) => {
        //         setGetSolutions(res.data);
        //         document.title = Util.setCategoryDocumentTitle(
        //             res.data.map((g) => g.subtitle)
        //           );
        //     });

        // }      

        return() => {
            mounted = false;

        };
    }, [language]);

return (
    <>
        <div>                      
            <Solution 
               pagination={{
                 totalPages,
                 defaultCurrentPage,
                 currentPage,
                 filtered,
                 setFiltered,
                 setPagination,
                 defaultItemsPerPage,
                 itemsPerPage,
                 setItemsPerPage,
               }}
            solutions={solutions} 
            path={solutionsPath}/>
        </div>
    </>
);
}

export default SolutionsComponent;



