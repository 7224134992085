import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import SwiperCore, { Navigation, Mousewheel } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { MdArrowBack, MdArrowForward } from "react-icons/md";

import { breakpoints } from "../../../data/SwiperData";
import DataSheetModelResponse from "../../../models/DataSheetModelResponse";

import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import "./TabSubFamilyDataSheetsDesktop.css";
import SubfamilyDataSheetModel from "../../../models/SubfamilyDataSheetModel";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Util from "../../../utils/Util";
import { NameSpace , contactForm} from "../../../data/AppLanguage";
import DataSheetFeatureWithTranslation from "../../../models/DataSheetFeatureWithTranslation";

SwiperCore.use([Navigation, Mousewheel]);

interface Props {
    subfamilyDataSheet: SubfamilyDataSheetModel;  
    generatePdf: (datahseet: DataSheetModelResponse) => void;
    askInformation: () => void;
    isLoading: boolean;
    setIsLoading: (bool : boolean) => void;  
    handleImageChange: (
      url?: string,
      colorId?: number,
      index?: number, 
      data?: any,
      languages?: string,
      table?: DataSheetFeatureWithTranslation[],
    ) => void;
    selectedItemIndex: number;
}

const TabSubFamilyDataSheetsDesktop = (props: Props) => {
    const [selectedItemIndex, setSelectedItemIndex ] = useState<number>(1);                    
    const [hasCodeDataSheet, setHasCodeDataSheet] = useState<string>('');
    const Empty = "https://mm.mafirol.info/empty.png";

    const { t, i18n } = useTranslation([ NameSpace.ROUTES ]);
    const language = Util.splitLanguage(i18n.language, 0);
    const contactFormRef = useRef<HTMLElement>(null);    
    
  const slidesPerView = 4;

  useEffect(() => {
    setSelectedItemIndex(props.selectedItemIndex);
    // console.log('selectedindex é : ', props.selectedItemIndex)
  },[props.selectedItemIndex])

  return (
    <article className="datasheetDesktop">
      <Container className="datasheetDesktop-wrapper">
        <Tab.Container defaultActiveKey={`${props?.subfamilyDataSheet?.dataSheets[0]?.name}`}>
          <Row className="datasheetDesktop-wrapper-tabs justify-content-center">
            <Col
              xs={4}
              sm={4}
              md="auto"
              lg="auto"
              xl="auto"
              className="align-self-center"
            >
              <div className="datasheetDesktop-wrapper-tabs-button-prev">
                <MdArrowBack className="datasheetDesktop-wrapper-tabs-button-prev-icon" />
              </div>
            </Col>
            <Col
              xs={4}
              sm={4}
              md={10}
              lg={10}
              xl={10}
              className="datasheetDesktop-wrapper-tabs-col"
            >
              <Swiper
                mousewheel={
                  props.subfamilyDataSheet?.dataSheets?.length > slidesPerView ? true : false
                }
                navigation={{
                  prevEl: ".datasheetDesktop-wrapper-tabs-button-prev",
                  nextEl: ".datasheetDesktop-wrapper-tabs-button-next",
                }}
                centerInsufficientSlides
                breakpoints={breakpoints(slidesPerView)}
              >
                {props.subfamilyDataSheet?.dataSheets?.map((item, index) => {
                  return (
                    <SwiperSlide key={index}>
                      <Nav variant="pills" className="justify-content-center">
                        <Nav.Item 
                         onClick={() => {
                          props.handleImageChange(item?.urlImgCut, undefined, index + 1, item?.dataTable, item?.language, item?.dataTable)                        
                          }}    
                          >
                          <Nav.Link eventKey={item.subtitleText} active={selectedItemIndex === (index + 1) ? true : false} >{item.subtitleText}</Nav.Link>
                        </Nav.Item>
                      </Nav>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </Col>
            <Col
              xs={4}
              sm={4}
              md="auto"
              lg="auto"
              xl="auto"
              className="align-self-center text-right"
            >
              <div className="datasheetDesktop-wrapper-tabs-button-next">
                <MdArrowForward className="datasheetDesktop-wrapper-tabs-button-next-icon" />
              </div>
            </Col>
          </Row>        
        </Tab.Container>
      </Container>
    </article>
  );
};

export default TabSubFamilyDataSheetsDesktop;
