import { Container, Row, Col } from "react-bootstrap";

import { FilterModel } from "../../models/FilterModel";
import { JobOfferModel } from "../../models/JobOfferModel";
import { PaginationModel } from "../../models/PaginationModel";

import Filter from "../Filter/Filter";
import JobOffer from "../JobOffer/JobOffer";
import Pagination from "../Pagination/Pagination";

import "./JobOfferCollection.css";

interface Props {
  filter: FilterModel;
  pagination?: PaginationModel;
  path: string;
  jobOfferCollection: JobOfferModel[];
  className?: string;
}

const JobOfferCollection = (props: Props) => {
  return (
    <article className={props.className}>
      <Container>
        <Row className="mb-5 justify-content-end">
          <Col xs={12} md={3} className="text-right">
            <Filter filter={props.filter} />
          </Col>
        </Row>
        <Row className="joboffer-wrapper">
          {props.jobOfferCollection.map((item, index) => {
            return (
              <Col key={index} xs={12} md={6} className="joboffer-wrapper-col">
                <JobOffer jobOffer={item} path={props.path} />
              </Col>
            );
          })}
        </Row>
      </Container>
      {props.pagination ? <Pagination pagination={props.pagination} /> : null}
    </article>
  );
};

export default JobOfferCollection;
