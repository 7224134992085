import React, { useRef, useState } from 'react';
import ComplaintsFormModel from '../../models/Complaints';
import { Button, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { NameSpace } from '../../data/AppLanguage';
import Util from '../../utils/Util';

import ComplaintService from '../../services/api/Complaint';
import emailjs from '@emailjs/browser'
import './Complaint.css';

import toast, { Toaster } from 'react-hot-toast';


const Complaint = () => {  
  const [complaint, setComplaint] = useState<ComplaintsFormModel>({
    complaintType: '',
    occurrenceState: '',
    occurrenceDate: '',
    frequency: '',
    relationship: '',
    message: '',
    file: null,
    key: ''
  });

  const fileInputRef = useRef<HTMLInputElement>(null);
  //-----------------------------------------------

  const handleTextareaChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = event.target;
    setComplaint((prevValues) => ({
      ...prevValues,
      message: value,
    }));
  };
  //-----------------------------------------------

  const handleSelectInputChange = (event: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
    const { name, value } = event.target;
    setComplaint((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };
  //-----------------------------------------------

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];
    setComplaint((prevValues) => ({
      ...prevValues,
      file: file || null,
    }));
  }; 
  

 
  //-----------------------------------------------

  const submitForm: React.FormEventHandler<HTMLFormElement> = async (event) => {
    let formData = new FormData();
    event.preventDefault();   

    formData.append('complaintType', complaint.complaintType);
    formData.append('occurrenceState', complaint.occurrenceState);
    formData.append('occurrenceDate', complaint.occurrenceDate);
    formData.append('frequency', complaint.frequency);
    formData.append('relationship', complaint.relationship);
    formData.append('message', complaint.message);
    
    if (complaint.file) {
        formData.append('file', complaint.file);
    }


      ComplaintService.addComplaint(formData).then((response) => {
          //-------------------------- A chave gerada está na propriedade "key" do objeto response.data
          const generatedKey = response.data.key;
        
          complaint.key = generatedKey;

          toast.success(`Chave gerada: ${generatedKey} , anote-a`, {
            position: 'top-center',
            duration: 20000,
          });       
           //------------------------- Limpe o campo de arquivo após o envio bem-sucedido
          setComplaint({
            ...complaint,
            file: null
          });

          //-------------------------- Limpe o valor do campo de arquivo usando a referência
          if (fileInputRef.current) {
            fileInputRef.current.value = '';
          }

          
          limpaForm();
      })
      .catch((error) => {
        console.error('Erro ao adicionar reclamação', error)
        toast.error("Erro ao tentar submeter a denúncia!", {
          position: 'top-center',
          duration: 10000,
        }); 
      });
    };
 
   const limpaForm = () => {
    setComplaint({
    complaintType: '',
    occurrenceState: '',
    occurrenceDate: '',
    frequency: '',
    relationship: '',
    message: '',
    file: null,
    key: ''
    });
   };


  const { t, i18n } = useTranslation([
    NameSpace.COMMON,
    NameSpace.GLOSSARY,
    NameSpace.NAVIGATION,
    NameSpace.ROUTES,
  ]);
  const language = Util.splitLanguage(i18n.language, 0);

  return (
    <div className="container mt-8 mb-10">     
        <Toaster position="top-right" reverseOrder={false} toastOptions={{ duration: 10000 }} />          
        <Form className='mb-5' onSubmit={submitForm}>
            <div className='mr-3 mb-5'>
                <h5 className="mb-4">{t("complaintForm", { ns: NameSpace.NAVIGATION })}</h5>
                {/* <p>{t("textComplaint1", { ns: NameSpace.NAVIGATION })}</p> */}
                <p className='testeh5'>{t("textComplaint2", { ns: NameSpace.NAVIGATION })}</p>
            </div>            
            <Form.Group controlId="complaintType">
                <Form.Label>Tipo de reclamação:</Form.Label>
                <Form.Control
                as="select"
                name="complaintType"
                value={complaint.complaintType}
                required
                onChange={handleSelectInputChange}
                >
                <option value="">Selecione</option>
                <option value="Corrupção ou suborno">Corrupção ou suborno</option>
                <option value="Abuso de autoridade">Abuso de autoridade</option>
                <option value="Fraude">Fraude</option>
                <option value="Relação laboral">Relação laboral</option>
                <option value="Segurança da informação ou privacidade">Segurança da informação ou privacidade</option>
                </Form.Control>
            </Form.Group>

            <Form.Group controlId="occurrenceState">
                <Form.Label>Estado de ocorrência:</Form.Label>
                <Form.Control
                as="select"
                required
                name="occurrenceState"
                value={complaint.occurrenceState}
                onChange={handleSelectInputChange}
                >
                <option value="">Selecione</option>
                <option value="Já ocorreu">Já ocorreu</option>
                <option value="Está a ocorrer">Está a ocorrer</option>
                <option value="Previsível ocorrer">Previsível ocorrer</option>
                </Form.Control>
            </Form.Group>

            <Form.Group controlId="occurrenceDate">
                <Form.Label>Data de ocorrência:</Form.Label>
                <Form.Control
                type="date"
                required
                name="occurrenceDate"
                value={complaint.occurrenceDate}
                onChange={handleSelectInputChange}
                />
            </Form.Group>

            <Form.Group controlId="frequency">
                <Form.Label>Frequência:</Form.Label>
                <Form.Control
                as="select"
                name="frequency"
                required
                value={complaint.frequency}
                onChange={handleSelectInputChange}
                >
                <option value="">Selecione</option>
                <option value="Constantemente">Constantemente</option>
                <option value="Apenas uma vez">Apenas uma vez</option>
                <option value="Esporádico">Regular</option>
                </Form.Control>
            </Form.Group>

            <Form.Group controlId="relationship">
                <Form.Label>Relacionamento:</Form.Label>
                <Form.Control
                as="select"
                name="relationship"
                value={complaint.relationship}
                required
                onChange={handleSelectInputChange}
                >
                <option value="">Selecione</option>
                <option value="Colaborador">Colaborador</option>
                <option value="Ex-Colaborador">Ex-Colaborador</option>
                <option value="Em processo de recrutamento">Em processo de recrutamento</option>
                <option value="Estagiário">Estagiário</option>
                </Form.Control>
            </Form.Group>

            <Form.Group controlId="message">
                <Form.Label>Mensagem:</Form.Label>
                <Form.Control
                as="textarea"
                required
                name="message"
                value={complaint.message}
                onChange={handleTextareaChange}
                />
            </Form.Group>

            <Form.Group className='mt-5' controlId="file">
                <Form.Label>Arquivo:</Form.Label>
                <Form.Control id="attachment" type="file" name="file" accept="image/*, video/*, application/pdf" ref={fileInputRef} onChange={handleFileChange} />
            </Form.Group>

            <Button className="btn btn-primmary mt-5" type="submit">
                Enviar denúncia
            </Button>
        </Form>        
        
    </div>      
    
  );
};

export default Complaint;
