import { GammaModel } from "../../models/GammaModel";

import { get } from "./Api";

const endpoint = "/gammas";

const Gamma = {
  getGammas: async (language: string) =>
    get<GammaModel[]>(endpoint, { params: { language } }),
};

export default Gamma;
