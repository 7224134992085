import { SchemaOf, object, string, number, boolean } from "yup";
import i18n from "i18next";

import { ContactGenericFormModel } from "../models/ContactGenericFormModel";
import { DepartmentModel } from "../models/DepartmentModel";

import { NameSpace } from "../data/AppLanguage";

const ContactGenericFormSchema = (
  departments: DepartmentModel[]
): SchemaOf<ContactGenericFormModel> =>
  object().shape(
    {
      name: string()
        .trim()
        .required(i18n.t("required", { ns: NameSpace.VALIDATION }))
        .min(
          2,
          i18n.t("nameMinLength", { ns: NameSpace.VALIDATION, number: 2 })
        )
        .max(
          255,
          i18n.t("nameMaxLength", { ns: NameSpace.VALIDATION, number: 255 })
        ),
      email: string()
        .trim()
        .required(i18n.t("required", { ns: NameSpace.VALIDATION }))
        .email(i18n.t("emailInvalid", { ns: NameSpace.VALIDATION })),
      phoneNumber: string()
        .trim()
        .validPhoneNumber(
          i18n.t("phoneNumberInvalid", { ns: NameSpace.VALIDATION })
        )
        .required(),
      countryId: string()
        .trim()
        .validCountryId(i18n.t("countryInvalid", { ns: NameSpace.VALIDATION }))
        .required(),
      location: string()
        .trim()
        .when("location", {
          is: (value: any) => value?.length,
          then: string()
            .min(
              2,
              i18n.t("locationMinLength", {
                ns: NameSpace.VALIDATION,
                number: 2,
              })
            )
            .max(
              255,
              i18n.t("locationMaxLength", {
                ns: NameSpace.VALIDATION,
                number: 255,
              })
            ),
          otherwise: string().optional(),
        }),
      company: string()
        .trim()
        .when("company", {
          is: (value: any) => value?.length,
          then: string()
            .min(
              2,
              i18n.t("companyMinLength", {
                ns: NameSpace.VALIDATION,
                number: 2,
              })
            )
            .max(
              255,
              i18n.t("companyMaxLength", {
                ns: NameSpace.VALIDATION,
                number: 255,
              })
            ),
          otherwise: string().optional(),
        }),
      departmentId: number()
        .typeError(i18n.t("required", { ns: NameSpace.VALIDATION }))
        .required(i18n.t("required", { ns: NameSpace.VALIDATION }))
        .test(
          "departmentId",
          i18n.t("departmentInvalid", { ns: NameSpace.VALIDATION }),
          (value) => !!departments.find((d) => d.id === value)
        ),
      message: string()
        .trim()
        .required(i18n.t("required", { ns: NameSpace.VALIDATION })),
      privacyPolicy: boolean()
        .required()
        .oneOf([true], i18n.t("required", { ns: NameSpace.VALIDATION })),
    },
    [
      ["location", "location"],
      ["company", "company"],
    ]
  );

export default ContactGenericFormSchema;
