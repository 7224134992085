import { Jumbotron, Container, Row, Col, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { MediaModel } from "../../models/MediaModel";

import { NameSpace, buttons } from "../../data/AppLanguage";
import { MediaType } from "../../enums/MediaType";

import Util from "../../utils/Util";

import Video from "../Video/Video";

import "./MainTitle.css";

interface RecruitmentOptions {
  jobOffers: number;
}

interface Props {
  media?: MediaModel;
  name?: string;
  height: string;
  recruitment?: RecruitmentOptions;
  articleClassName?: string;
  className?: string;
}

const MainTitle = (props: Props) => {
  const { t, i18n } = useTranslation([NameSpace.COMMON, NameSpace.ROUTES]);
  const language = Util.splitLanguage(i18n.language, 0);
  const recruitmentPath = t("recruitment", {
    ns: NameSpace.ROUTES,
  });

  return (
    <article className={props.articleClassName}>
      <Jumbotron
        className={`maintitle d-flex align-items-center ${props.className}`}
        fluid
        style={{
          backgroundImage:
            props.media?.type === MediaType.IMAGE
              ? `url(${props.media?.path})`
              : "none",
          height: `${props.height}`,
        }}
      >
        {props.media?.type === MediaType.VIDEO ? (
          <Video className="maintitle-video" path={props.media?.path} />
        ) : null}
        <Container className="maintitle-wrapper align-bottom">
          {/* Commented in case it would be needed again */}
          {/* {props.name ? (
            <h1 className="maintitle-wrapper-title mx-auto">{props.name}</h1>
          ) : null} */}
          {props.recruitment ? (
            <Row className="maintitle-wrapper-recruitment justify-content-center mt-9">
              <Col
                xs={12}
                sm={12}
                md="auto"
                className="pl-0 pr-0 maintitle-wrapper-spontaneous-application-col"
              >
                <Button
                  as={Link}
                  to={`/${language}/${recruitmentPath}/${t(
                    "spontaneousApplication",
                    { ns: NameSpace.ROUTES }
                  )}`}
                  variant="primary"
                  className="maintitle-wrapper-recruitment-button"
                >
                  {t(`${buttons}spontaneousApplication`, {
                    ns: NameSpace.COMMON,
                  })}
                </Button>
              </Col>
              <Col
                xs={12}
                sm={12}
                md="auto"
                className="pl-0 pr-0 maintitle-wrapper-job-offers-col"
              >
                <Button
                  as={Link}
                  to={`/${language}/${recruitmentPath}/${t("jobOffers", {
                    ns: NameSpace.ROUTES,
                  })}`}
                  variant="primary"
                  className="maintitle-wrapper-recruitment-button"
                >
                  {t(`${buttons}jobOffers`, {
                    ns: NameSpace.COMMON,
                    number: props.recruitment.jobOffers,
                  })}
                </Button>
              </Col>
            </Row>
          ) : null}
        </Container>
      </Jumbotron>
    </article>
  );
};

export default MainTitle;
