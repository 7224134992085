import { useTranslation } from "react-i18next";
import { NameSpace, socialMedia } from "../../data/AppLanguage";
import { useEffect, useState } from "react";
import EventService from "../../services/api/Event";
import Util from "../../utils/Util";
import { EventModel } from "../../models/EventModel";
import Event from "../../components/Event/Event";
import { Alert, Col } from "react-bootstrap";
import { Container, Grid, Typography } from "@mui/material";


interface Props {   
    path: string;  
    className?: string;
}


const NewsComponent = (props: Props) => {
    const [events, setGetEvents] = useState<EventModel[]>([]);

    const { t, i18n } = useTranslation([
        NameSpace.COMMON,
        NameSpace.GLOSSARY,
        NameSpace.NAVIGATION,
        NameSpace.ROUTES,
        NameSpace.PAGES,
    ]);
    
    const eventsPath = t("newsEvents", { ns: NameSpace.ROUTES });
    const noContent = t("noContent", { ns: NameSpace.PAGES });


    const language = Util.splitLanguage(i18n.language, 0);

    useEffect(() => {
        let mounted = true;

        if(mounted){
            EventService.getEvents(language).then((res) => {
                setGetEvents(res.data);               
            });

        }      

        return() => {
            mounted = false;

        };
    }, [language]);

return (
    <>
        <div>
            {/* <h4 className="mt-10">{t("newsEvents", { ns: NameSpace.NAVIGATION })}</h4> */}
            {events.length ? (
                <Event events={events} path={eventsPath}/>        
            ) : (
                <Container maxWidth="xl" className="mt-10" style={{height: '100vh'}}>
                    <Typography component="h1" variant="h2" align="left">
                        {t("newsEvents", { ns: NameSpace.NAVIGATION })}
                    </Typography>     
                    <hr  />
                    
                    <Alert variant="warning" className="category-wrapper-no-content text-center mt-5">
                        <Typography component="h6" variant="h6" align="center">
                            {noContent}
                        </Typography>                          
                    </Alert>
                    
                </Container>                
            )}
             
        </div>
    </>
);
}

export default NewsComponent;

