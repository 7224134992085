import { SocialMediaModel } from "../../../models/SocialMediaModel";

import "../SocialMediaCollection.css";

interface Props {
  social?: SocialMediaModel;
}

const Instagram = (props: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 29.24 29.24"
      className={`social-media-collection ${props.social?.wrapperClassName}`}
    >
      <title>{"Instagram"}</title>
      <g id="prefix__Camada_2" data-name="Camada 2">
        <g id="prefix__ELEMENTS">
          <rect
            width={29.24}
            height={29.24}
            rx={7.65}
            transform="rotate(90 14.62 14.62)"
          />
          <path
            d="M19.16 9.11a1 1 0 101 1 1 1 0 00-1-1zM14.69 10.4a4.22 4.22 0 104.22 4.22 4.22 4.22 0 00-4.22-4.22zm0 6.93a2.71 2.71 0 112.71-2.71 2.71 2.71 0 01-2.71 2.71z"
            className={props.social?.overlayClassName}
          />
          <path
            d="M18 23.19h-6.8A5.16 5.16 0 016.05 18v-6.8a5.16 5.16 0 015.15-5.15H18a5.16 5.16 0 015.15 5.15V18A5.16 5.16 0 0118 23.19zM11.2 7.66a3.55 3.55 0 00-3.54 3.54V18a3.55 3.55 0 003.54 3.54H18A3.55 3.55 0 0021.58 18v-6.8A3.55 3.55 0 0018 7.66z"
            className={props.social?.overlayClassName}
          />
        </g>
      </g>
    </svg>
  );
};

export default Instagram;
