import "./Title.css";

interface Props {
  title?: string;
  className?: string;
}

const Title = (props: Props) => {
  return <p className={props.className}>{props.title}</p>;
};

export default Title;
