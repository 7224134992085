import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { JobOfferModel } from "../../models/JobOfferModel";
import { TypeModel } from "../../models/TypeModel";

import { NameSpace, filters } from "../../data/AppLanguage";

import Util from "../../utils/Util";

import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { selectJobOffers } from "../../features/joboffers/joboffersSlice";
import { getJobOffers } from "../../features/middlewares/jobOffersMiddleware";

import RecruitmentService from "../../services/api/Recruitment";

import BreadcrumbPath from "../../components/BreadcrumbPath/BreadcrumbPath";
import JobOfferCollectionComponent from "../../components/JobOfferCollection/JobOfferCollection";

const JobOfferCollection = () => {
  const defaultItemsPerPage = 12;
  const defaultCurrentPage = 1;

  const [jobOfferCollection, setJobOfferCollection] = useState<JobOfferModel[]>(
    []
  );
  const [jobOfferTypes, setJobOfferTypes] = useState<TypeModel[]>([]);
  const [currentPage, setCurrentPage] = useState(defaultCurrentPage);
  const [itemsPerPage, setItemsPerPage] = useState(defaultItemsPerPage);
  const [filteredItems, setFilteredItems] = useState<JobOfferModel[]>([]);
  const [filtered, setFiltered] = useState(false);
  const jobOffers = useAppSelector(selectJobOffers);
  const dispatch = useAppDispatch();

  const indexLastItem = currentPage * itemsPerPage;
  const indexFirstItem = indexLastItem - itemsPerPage;
  const totalPages = Math.ceil(filteredItems.length / itemsPerPage);
  const paginatedItems = filteredItems.slice(indexFirstItem, indexLastItem);

  const { t } = useTranslation([
    NameSpace.COMMON,
    NameSpace.NAVIGATION,
    NameSpace.PAGES,
    NameSpace.ROUTES,
  ]);
  const recruitmentPath = t("recruitment", { ns: NameSpace.ROUTES });
  const recruitmentTitle = t("recruitment", { ns: NameSpace.NAVIGATION });
  const jobOffersPath = t("jobOffers", { ns: NameSpace.ROUTES });
  const jobOffersTitle = t("jobOffers", { ns: NameSpace.PAGES });

  const setPagination = (page: number) => {
    setCurrentPage(page);
  };

  const setFilter = (filter: string) => {
    if (filter === t(`${filters}all`, { ns: NameSpace.COMMON }).toUpperCase()) {
      setFilteredItems(jobOfferCollection);
      setCurrentPage(jobOfferCollection.length >= 1 ? defaultCurrentPage : 0);
    } else {
      var jobOffer = jobOfferCollection.filter((j) => j.location.id === filter);
      setFilteredItems(jobOffer);
      setCurrentPage(jobOffer.length >= 1 ? defaultCurrentPage : 0);
    }

    setFiltered(true);
    setItemsPerPage(defaultItemsPerPage);
  };

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      document.title = Util.setDocumentTitle(
        `${recruitmentTitle} | ${jobOffersTitle}`
      );

      if (!jobOffers.data.length) dispatch(getJobOffers());

      setJobOfferCollection(jobOffers.data);
      setFilteredItems(jobOffers.data);

      RecruitmentService.getJobLocations().then((res) => {
        setJobOfferTypes(res.data);
      });
    }

    return () => {
      mounted = false;
    };
  }, [recruitmentTitle, jobOffersTitle, dispatch, jobOffers.data]);

  return (
    <>
      <BreadcrumbPath
        ancestors={[
          {
            name: recruitmentTitle,
            path: recruitmentPath,
          },
        ]}
        current={jobOffersTitle}
        className="pt-8"
      />
      <JobOfferCollectionComponent
        filter={{ setFilter, types: jobOfferTypes }}
        pagination={{
          totalPages,
          defaultCurrentPage,
          currentPage,
          filtered,
          setFiltered,
          setPagination,
          defaultItemsPerPage,
          itemsPerPage,
          setItemsPerPage,
        }}
        path={`${recruitmentPath}/${jobOffersPath}`}
        jobOfferCollection={paginatedItems}
        className="mt-2 mb-5"
      />
    </>
  );
};

export default JobOfferCollection;
