import { useTranslation } from "react-i18next";
import { NameSpace, socialMedia } from "../../data/AppLanguage";
import { useEffect, useState } from "react";
import CaseStudyService from "../../services/api/CaseStudy";
import Util from "../../utils/Util";
import { useAppDispatch } from "../../app/hooks";

import CaseStudy from "./CaseStudy";
import { CaseStudyModel } from "../../models/CaseStudyModel";


interface Props {   
    path: string;  
    className?: string;
}


const CaseStudies = (props: Props) => {
    const [caseStudys, setCaseStudyies] = useState<CaseStudyModel[]>([]);

    const defaultItemsPerPage = 12;
    const defaultCurrentPage = 1;

  const [currentPage, setCurrentPage] = useState(defaultCurrentPage);
  const [itemsPerPage, setItemsPerPage] = useState(defaultItemsPerPage);
  const [filteredItems, setFilteredItems] = useState<CaseStudyModel[]>([]);
  const [filtered, setFiltered] = useState(false);
  const dispatch = useAppDispatch();

  const indexLastItem = currentPage * itemsPerPage;
  const indexFirstItem = indexLastItem - itemsPerPage;
  const totalPages = Math.ceil(filteredItems.length / itemsPerPage);
  const paginatedItems = filteredItems.slice(indexFirstItem, indexLastItem);

    const { t, i18n } = useTranslation([
        NameSpace.COMMON,
        NameSpace.GLOSSARY,
        NameSpace.NAVIGATION,
        NameSpace.ROUTES,
    ]);
    

//---------------------------------------------------------------------------
const setPagination = (page: number) => {
    setCurrentPage(page);
  };

  const setFilter = (filter: string) => {
   
    setItemsPerPage(defaultItemsPerPage);
  };


//---------------------------------------------------------------------------

    const caseStudyPath = t("caseStudy", { ns: NameSpace.ROUTES });

    const language = Util.splitLanguage(i18n.language, 0);

    useEffect(() => {
        let mounted = true;

        if(mounted){
            CaseStudyService.GetCaseStudys(language).then((res) => {
                setCaseStudyies(res.data);
                document.title = Util.setCategoryDocumentTitle(
                    res.data.map((g) => g.subtitle)
                  );
            });

        }      

        return() => {
            mounted = false;

        };
    }, [language]);

return (
    <>
        <div>           
            <CaseStudy 
              // filter={{ setFilter, types: solutionsTypes }}
               pagination={{
                 totalPages,
                 defaultCurrentPage,
                 currentPage,
                 filtered,
                 setFiltered,
                 setPagination,
                 defaultItemsPerPage,
                 itemsPerPage,
                 setItemsPerPage,
               }}
            caseStudys={caseStudys} 
            path={caseStudyPath}/>
        </div>
    </>
);
}

export default CaseStudies;


