import { EventModel } from "../../models/EventModel";
import EventItem from "../EventItem/EventItem";
import { PaginationModel } from "../../models/PaginationModel";
import { Container, Grid, Typography, } from "@mui/material";
import styles from  "./Event.module.css";
import { useTranslation } from "react-i18next";
import { NameSpace } from "../../data/AppLanguage";


interface Props {
    events: EventModel[];
    path: string;
    pagination?: PaginationModel;
    className?: string;
}

const Event = (props: Props) => {

    const { t } = useTranslation([NameSpace.NAVIGATION,NameSpace.ROUTES,]);
    

    return(
        <>            
            <Container maxWidth="xl" className={styles.container}>
                <Typography component="h1" variant="h2" align="left">
                    {t("newsEvents", { ns: NameSpace.NAVIGATION })}
                </Typography>     
                <hr  className={styles.gridContainer}/>
                
                <Grid container spacing={2} paddingLeft={1}>
                    {props.events?.map((item, index) => {
                            return (
                            <Grid item
                            xl={3}
                            lg={4}
                            md={4}
                            sm={6}
                            xs={12}
                                key={index}
                                className={styles.gridItem}
                            >
                                <EventItem event={item} path={props.path} />
                            </Grid>
                            );
                        })}
                  
                </Grid>
            </Container>
        </>
    );
}

export default Event;