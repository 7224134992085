import { useState, useEffect } from "react";
import { Image } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import SwiperCore, { Mousewheel } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import { SliderModel } from "../../models/SliderModel";

import { MediaType } from "../../enums/MediaType";

import Util from "../../utils/Util";

import SliderService from "../../services/api/Slider";

import Video from "../Video/Video";

import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import "./Slider.css";

SwiperCore.use([Mousewheel]);

const SliderVideoCollection = () => {
  const [scrollPosition, setScrollPosition] = useState<number>();
  const [sliders, setSliders] = useState<SliderModel[]>([]);

  const { i18n } = useTranslation();
  const language = Util.splitLanguage(i18n.language, 0);

  const handleScroll = () => {
    setScrollPosition(window.pageYOffset);
  };

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      SliderService.getSliders(language).then((res) => {
        setSliders(res.data);
      });
      window.addEventListener("scroll", handleScroll, { passive: true });
    }
    return () => {
      mounted = false;
    };
  }, [language]);

  return (
    <article className="slider">
      {sliders.length ? (
        <Swiper
          allowSlidePrev={scrollPosition === 0}
          className="slider-swiper"
          direction="vertical"
          touchReleaseOnEdges
          mousewheel={{ releaseOnEdges: false }}
          onSlideChangeTransitionEnd={(slider) => {
            slider.params.mousewheel = {
              releaseOnEdges: slider.isEnd ? true : false,
            };
            slider.update();
          }}
          spaceBetween={0}
          slidesPerView={1}
        >
          {sliders.map((item, index) => {
            return (
              <SwiperSlide key={index}>
                {item.media.type === MediaType.IMAGE ? (
                  <div className="slider-container">
                    <Image
                      src={item.media.path}
                      className="slider-swiper-media"
                    />
                    <div className="slider-container-text">
                      <div className="slider-container-text-subtitle">
                        {item.title}
                      </div>
                      <div className="slider-container-text-title">
                        {item.subtitle}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="slider-container">
                    <Video
                      path={item.media.path}
                      className="slider-swiper-media"
                    />
                  </div>
                )}
                <div className="slider-swiper-arrow">
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      ) : null}
    </article>
  );
};

export default SliderVideoCollection;
