import { AxiosRequestConfig } from "axios";

import { JobOfferModel } from "../../models/JobOfferModel";
import { TypeModel } from "../../models/TypeModel";

import { get, post } from "./Api";

const endpoint = `/recruitment/`;

const config: AxiosRequestConfig = {
  headers: {
    "Content-Type": "multipart/form-data",
  },
};

const JobOffer = {
  getJobOffers: async (rows?: number, location?: string) =>
    get<JobOfferModel[]>(`${endpoint}joboffers`, {
      params: { rows, location },
    }),

  getJobOffer: async (path: string) =>
    get<JobOfferModel>(`${endpoint}joboffer/${path}`),

  getJobLocations: async () => get<TypeModel[]>(`${endpoint}joblocations`),

  getJobApplicationDegrees: async () =>
    get<TypeModel[]>(`${endpoint}jobapplicationdegrees`),

  getJobApplicationAreasOfInterest: async () =>
    get<TypeModel[]>(`${endpoint}jobapplicationareasofinterest`),

  postSpontaneousApplication: async (spontaneousApplication: FormData) =>
    post(`${endpoint}spontaneousapplication`, spontaneousApplication, config),

  postJobOfferApplication: async (
    jobOfferApplication: FormData,
    jobOfferPath: string
  ) =>
    post(
      `${endpoint}jobofferapplication/${jobOfferPath}`,
      jobOfferApplication,
      config
    ),
};

export default JobOffer;
