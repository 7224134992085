import { useEffect, useState } from 'react';
import { Switch, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { saveAs } from "file-saver";
import { NameSpace, contactForm } from "../../data/AppLanguage";

import jsPDF from 'jspdf';
import style from "./DataSheetType1.module.css";
import html2canvas from 'html2canvas';
import logoMafirol from '../../assets/images/mafiroLogo.png';
import Util from "../../utils/Util";
import i18n from "../../i18n";
import mime from "mime-types";
import logo from '../../assets/images/mafiroLogo.png';


const DataSheetType1 = ({dataSheetData, isGeneratingPDF }) => {
    const [isDataReady, setIsDataReady] = useState(false);  
   
    useEffect(() => {
        if(dataSheetData.dataTable != null){
            setIsDataReady(true);     
            // console.log("cores: ", dataSheetData.listColor1)
        }
        
    }, [dataSheetData])

    const generatePDF = () => {
        try {
            if (!isDataReady) {
                console.error('Dados não prontos para gerar o PDF.');
                return;
              }
              isGeneratingPDF = true;

              const pdf = new jsPDF({compress: true, orientation: 'p', unit: 'mm', format: 'a4'});
        //   const pdf = new jsPDF('p', 'mm', 'a4');
      
        //   setTimeout(() => {
            const content1 = document.getElementById('pdf-content');
          html2canvas(content1, {
            scale: 3,
            useCORS: true,           
            // allowTaint: true,
          }).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            // console.log('o que tem no imgdata é: ', imgData)
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = (canvas.height * pdfWidth) / canvas.width;
            pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
      
            // Adicionar uma nova página para a segunda página
            pdf.addPage();
      
            // Segunda página
            const content2 = document.getElementById('pdf-content2');
            html2canvas(content2, {
              scale: 3,
              useCORS: true,
            //   imageTimeout: 2000,
            // //   allowTaint: true,

            }).then((canvas) => {
              const imgData = canvas.toDataURL('image/png');
              const pdfWidth = pdf.internal.pageSize.getWidth();
              const pdfHeight = (canvas.height * pdfWidth) / canvas.width;
              pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
      
              const pdfBlob = pdf.output('blob');

              saveAs(
                pdfBlob,
                `product_mafirol.${mime.extension(
                  pdfBlob.type
                )}`
              );
            //   var url = URL.createObjectURL(pdfBlob);
            //   window.open(url, "_blank", "noopener, noreferrer");
            
            // saveAs(pdfBlob, 'product-Mafirol.pdf');
            

              setTimeout(() => {
                isGeneratingPDF = false;
              },1000)
            });
          });
        // }, 1000); 
         
        } catch (error) {
          console.error('Error generating PDF:', error);
          isGeneratingPDF(false);
        }
      };

      useEffect(() => {
        if (isDataReady) {
            generatePDF(1); // Gerar o PDF quando os dados estiverem prontos              
            if(dataSheetData.dataTable.length > 0)
            {
                createTable(dataSheetData.dataTable, dataSheetData.language);                      
            }           
        }
      }, [isDataReady]);

    return (
        <div>
        <div className={`${style.body}`} >           
            <div id="pdf-content" className={style.container}>  
               <div className='d-flex mb-2'>  
                    <div className='justify-content-start'>
                        <img 
                        className={style.imageColorHeader}
                        src={logoMafirol} />
                    </div>
                    <div  className={`${style.header} w-100 mt-4 ml-5`}>
                        <h1 className={style.h1}>{dataSheetData.title}</h1>
                    </div>
                 
                </div>        
            <hr className={`${style.separatorOne} ${style.hr}`}/>
            <h6 className={style.h6}>{dataSheetData.subtitle}</h6>
            <h5 className={style.h5}>{dataSheetData.subtitleText}</h5>

            <div className={style.parent}>
                <div className={style.sibilingOne}>
                    {dataSheetData.title1 != null && 
                    <div className={`${style.card} ${style.cardPosition01}`}>
                        <h5 className={style.cardTitle}>{dataSheetData.title1}</h5>
                        <hr className={`${style.separatorParagraph} ${style.hr}`}/>
                        <div className={style.paragraph}>
                            <span dangerouslySetInnerHTML={{ __html: dataSheetData.paragraph1 }}></span>
                        </div>
                    </div>
                    }
                    {dataSheetData.title2  && 
                    <div className={style.card}>
                        <h5 className={style.cardTitle}>{dataSheetData.title2}</h5>
                        <hr className={style.hr}/>
                        <p className={style.paragraph}>
                            <p dangerouslySetInnerHTML={{ __html: dataSheetData.paragraph2 }}></p>
                        </p>
                    </div>
                    }
                    {dataSheetData.title3 && 
                    <div className={style.card}>
                        <h5 className={style.cardTitle}>  {dataSheetData.title3}</h5>
                        <hr className={style.hr}/>
                        <p className={style.paragraph}>
                            <p dangerouslySetInnerHTML={{ __html: dataSheetData.paragraph3 }}></p>
                        </p>
                    </div>
                    }   
                    {dataSheetData.title4 && 
                    <div className={style.card}>
                        <h5 className={style.cardTitle}>  {dataSheetData.title4}</h5>
                        <hr className={style.hr}/>
                        <p className={style.paragraph}>
                            <p dangerouslySetInnerHTML={{ __html: dataSheetData.paragraph4 }}></p>
                        </p>
                    </div>
                    } 
                    {dataSheetData.title5 && 
                    <div className={style.card}>
                        <h5 className={style.cardTitle}>  {dataSheetData.title5}</h5>
                        <hr className={style.hr}/>
                        <p className={style.paragraph}>
                            <p dangerouslySetInnerHTML={{ __html: dataSheetData.paragraph5 }}></p>
                        </p>
                    </div>  
                    }
                    
                                        
                </div>
                <div className={style.sibilingTwo}>
                    <div className={style.cardImage}>
                        <img 
                        className={style.imageMain}
                        src={dataSheetData.urlImgMain} alt="image" />
                    </div>
                    <div className={style.cardContainer}>
                        {isDataReady && dataSheetData.listColor1 != 0 && dataSheetData.listColor1 && 
                        <>
                         <h5 className={style.cardTitle}>{dataSheetData.externColorsDescription}</h5>
                          <hr className={style.hr}/>
                          <div className="row">
                                  {dataSheetData.listColor1 && dataSheetData.listColor1.map((item, index) => {
                                      return(
                                          <div  className={style.cardColor}>
                                              <img 
                                              key={index}
                                              className={style.imageColor}
                                              src={item} alt="image" />
                                          </div>
                                      );
                                  
                                  })}                            
                          </div>
                        </>                             
                        }
                      
                    </div>
                    <div className={style.cardContainer} style={{ marginTop: '50px'}}>
                    {isDataReady && dataSheetData.listColor2 != 0 && dataSheetData.listColor2  &&
                        <>
                        <h5 className={style.cardTitle}>{dataSheetData.internColorsDescription}</h5>
                        <hr className={style.hr}/>
                        <div className="row">
                        {dataSheetData.listColor2 && dataSheetData.listColor2.map((item, index) => {
                                return(
                                    <div  className={style.cardColor}>
                                        <img 
                                        key={index}
                                        className={style.imageColor}
                                        src={item} alt="image" />
                                    </div>
                                );                                    
                                })}                                            
                        </div>
                        {dataSheetData.textFooter1 && 
                        <div className={`${style.textFooterColor}`}>
                                * {dataSheetData.textFooter1}
                        </div>
                        }
                        </>
                    }                            
                    </div>
                </div>
            </div> 
            {/* <div className={style.footerFixed1}>                    
                <div className={`row justify-content-center mb-0 p-0 mt-1`}>                              
                            <div className={style.imageFooterFixed2}>
                                <img 
                                className={style.imageColor}
                                src={wwwLogo} alt="image" />
                            </div>             
                            <div className={style.mafirolCom}>.mafirol.com
                            </div>                
                </div>
            </div>  */}
            <div className={style.footerFixed1}>                        
                        <div className={`${style.textFooter1} ${style.footerFixed}`}> 
                            <b dangerouslySetInnerHTML={{ __html: dataSheetData.titleFooter2 }}></b>
                            <p dangerouslySetInnerHTML={{ __html: dataSheetData.subTitleFooter2 }}></p>
                            <p dangerouslySetInnerHTML={{ __html: dataSheetData.textFooter2 }}></p>
                        </div>
                        <div className="row justify-content-center mb-0 p-0 mt-1 ml-5">
                            {/* <div className={style.itemFooter}>
                                <img 
                                className={style.imageColor}
                                src={logoMafirol} alt="image" />
                            </div> */}
                            <div className={style.itemFooter}>
                                {/* <img 
                                className={style.imageColor}
                                src={wwwLogo} alt="image" /> */}
                            </div>     
                            <div className={style.mafirolCom}>www.mafirol.com
                            </div>                         
                        </div>
                </div>                        
            </div>      




            <div id="pdf-content2" className={`${style.container1}  mt-10`}>
            <div className='d-flex mb-2'>  
                    <div className='justify-content-start'>
                        <img 
                        className={style.imageColorHeader}
                        src={logoMafirol} alt="image" />
                    </div>
                    <div  className={`${style.header} w-100 mt-4 ml-5`}>
                        <h1 className={style.h1}>{dataSheetData.title}</h1>
                    </div>
                 
                </div>        

                <hr className={`${style.separatorOne} ${style.hr}`}/>
                <h6 className={style.h6}>{dataSheetData.subtitle}</h6>
                <h5 className={style.h5}>{dataSheetData.subtitleText}</h5>
                
                <div className={style.cardImageSecondPage}>
                            <img 
                            className={style.imageSecondPage}
                            src={dataSheetData.urlImgCut} alt="image" />
                </div>
                <div className={`${style.card} ${style.backgroundImageColor}`}>
                    <h5 className={style.cardTitleTechnicalInformation}>
                    {dataSheetData.technicalInformation}
                    </h5>

                    <div className={`${style.content_table} ${style.backgroundImageColor}`}>
                        <div id="tableId" className={`${style.table} ${style.backgroundImageColor}`}></div>                               
                    </div>                  
                   
                </div>     
                {/* <div className={style.card}>
                    <h5 className={style.cardTitleTechnicalInformation}>
                    {dataSheetData.technicalInformation}
                    </h5>

                    <div className={style.content_table}>
                        <div id="tableId" className={`${style.table}`}></div>                               
                    </div>
                 
                   
                </div>                */}
            
                <div className={style.footerFixed2}>                        
                        <div className={`${style.textFooter1} ${style.footerFixed}`}> 
                            <b dangerouslySetInnerHTML={{ __html: dataSheetData.titleFooter2 }}></b>
                            <p dangerouslySetInnerHTML={{ __html: dataSheetData.subTitleFooter2 }}></p>
                            <p dangerouslySetInnerHTML={{ __html: dataSheetData.textFooter2 }}></p>
                        </div>
                        <div className="row justify-content-center mb-0 p-0 mt-1 ml-5">
                            {/* <div className={style.itemFooter}>
                                <img 
                                className={style.imageColor}
                                src={logoMafirol} alt="image" />
                            </div> */}
                            <div className={style.itemFooter}>
                                {/* <img 
                                className={style.imageColor}
                                src={wwwLogo} alt="image" /> */}
                            </div>     
                            <div className={style.mafirolCom}>www.mafirol.com
                            </div>                         
                        </div>
                </div>           
            </div>  
        </div>        
           
                
                

           <button
            id="btn-print"
            className="btn btn-success btn-lg hidden"
            style={{ backgroundColor: 'white', color: 'black', textAlign: 'center' }}
            onClick={() => {
                generatePDF(1); // Inicie a geração do PDF com a primeira página
            }}
            >
                Gerar PDF e Imprimir
            </button> 
      
    </div>
    )
}

export default DataSheetType1

function createTable(data, language) {
    // Crie uma tabela HTML
    const table = document.createElement('table');

    table.style.minWidth = '895px';
    table.style.marginLeft = '-150px';
    // Preencha os cabeçalhos da tabela com modelos únicos em ordem crescente
    const uniqueModels = [...new Set(data.map(item => item.feature.model))];
    uniqueModels.sort((a, b) => a - b); // Ordenar os modelos em ordem crescente numérica

    // Crie uma linha de cabeçalho
    const headerRow = document.createElement('tr');

    // Mapeamento das línguas para as palavras-chave correspondentes
    const languageToKeyword = {
        "PT": "MODELO",
        "EN": "MODEL",
        "FR": "MODÈLE",
        "ES": "MODELO",
    };

    // Obtenha a palavra-chave com base na língua fornecida
    const languageResult = languageToKeyword[language] || "Model"; // Usará "Model" como padrão se a língua não for encontrada


    // Adicione uma célula vazia para a parte superior esquerda da tabela
    const emptyHeaderCell = document.createElement('th');
    emptyHeaderCell.textContent = `${languageResult}`;    
    emptyHeaderCell.classList.add(style.greentext);
    emptyHeaderCell.style.textAlign = 'right'
    headerRow.appendChild(emptyHeaderCell);

    uniqueModels.forEach(model => {
        const headerCell = document.createElement('th');
        headerCell.textContent = `${model}`;
        //Styles
        headerCell.style.border = '6px'
        headerCell.style.padding = '2px'
        headerCell.style.borderStyle = 'solid'
        headerCell.style.borderColor = 'white'
        headerCell.style.borderli = 'solid'
        headerCell.style.textAlign = 'center'

        headerCell.classList.add(style.tableHeader);
        headerRow.appendChild(headerCell);
    });

    // Adicione a linha de cabeçalho à tabela
    table.appendChild(headerRow);

    // Crie um objeto para mapear as traduções para os valores correspondentes
    const translationToValueMap = {};

    // Preencha o mapeamento das traduções para os valores

    data.sort((a, b) => a.feature.position - b.feature.position);

    data.forEach(item => {
        const translation = item.translations[0]?.name; // Supomos que as traduções sejam únicas
        const model = item.feature.model;
        const value = item.feature.value;

        if (!translationToValueMap[translation]) {
            translationToValueMap[translation] = {};
        }

        translationToValueMap[translation][model] = value;
    });

    // Preencha as linhas da tabela com traduções e valores
    for (const translation in translationToValueMap) {
        // Verifique se a tradução não é undefined, vazia ou nula antes de criar a linha
        if (translation !== null && translation !== '' && translation !== 'undefined' && translation !== 'NaN') {
            const translationRow = document.createElement('tr');

            translationRow.style.textAlign = 'right'
            translationRow.style.border = '6px'
            translationRow.style.padding = '2px'
            translationRow.style.borderStyle = 'solid'
            translationRow.style.borderColor = 'white'
            translationRow.style.borderli = 'solid'

            // Alternar entre as classes de cores "gray-row" e "white-row"
            if (table.rows.length % 2 === 0) {
                translationRow.classList.add(style.white_row);
            } else {
                translationRow.classList.add(style.gray_row);
            }

            // Crie uma célula para a tradução
            const translationCell = document.createElement('td');
            translationCell.textContent = translation;
            translationRow.appendChild(translationCell);

            const valueToUrlMap = {
                "A.jpg": "https://mm.mafirol.info/Categories/Subfamilies/EnergyEfficiency/20220107172351808_f900b70d-817b-43aa-8b82-d9bb76a8606e.jpg",
                "B.jpg": "https://mm.mafirol.info/Categories/Subfamilies/EnergyEfficiency/20220107172408158_0df5639c-c310-4603-b566-bd1d796fa1a7.jpg",
                "C.jpg": "https://mm.mafirol.info/Categories/Subfamilies/EnergyEfficiency/20220107172425079_fba4ffcb-cef0-4dc3-bb8c-b324f41e0f9d.jpg",
                "D.jpg": "https://mm.mafirol.info/Categories/Subfamilies/EnergyEfficiency/20220107172437494_1af8e266-236e-4fa6-a497-a7b9cc524d1a.jpg",
                "E.jpg": "https://mm.mafirol.info/Categories/Subfamilies/EnergyEfficiency/20220107172449586_4c99e082-15f7-482f-a749-91abe1ccdbd2.jpg",
                "F.jpg": "https://mm.mafirol.info/Categories/Subfamilies/EnergyEfficiency/20220107172503150_f8c2824f-9291-4005-89cf-ead2ba5c52c7.jpg",
                "G.jpg": "https://mm.mafirol.info/Categories/Subfamilies/EnergyEfficiency/20230313141543304_a7c37510-06e3-4c1c-8081-6af15c9d809c.jpg",
            }


            uniqueModels.forEach(model => {
                const value = translationToValueMap[translation][model] || '-'; // Use uma string vazia se não houver valor
                const valueCell = document.createElement('td');
                // Styles
                valueCell.style.textAlign = 'center'
                valueCell.style.border = '6px'
                valueCell.style.borderStyle = 'solid'
                valueCell.style.borderColor = 'white'
                valueCell.style.borderli = 'solid'
                
                

                const item = data.find(item => item.translations[0]?.name === translation && item.feature?.model === model);
            
                if (item && item.feature.id === "06" && value) {
                            // Verifique se o valor está no mapeamento
                    if (valueToUrlMap.hasOwnProperty(value)) {
                        // Crie um elemento de imagem e defina o atributo src com base no URL mapeado
                        const imageElement = document.createElement('img');
                        imageElement.src = valueToUrlMap[value];
                        imageElement.width = 60;
                        // Adicione a imagem à célula
                        valueCell.appendChild(imageElement);
                        valueCell.textAlign = 'center';
                    } else {
                        // Se o valor não estiver no mapeamento, defina o texto normal
                        valueCell.textAlign = 'center';
                        valueCell.textContent = value;
                    }
                } else {
                    // Se não for "06" ou o valor estiver vazio, apenas defina o texto normal
                    valueCell.textContent = value;
                }
            
                translationRow.appendChild(valueCell);
            });


            // Adicione a linha de tradução à tabela
            table.appendChild(translationRow);
        }
    }

    // Adicione a tabela ao seu elemento de destino (substitua 'elementId' pelo ID do elemento HTML onde deseja inserir a tabela)
    const tableContainer = document.getElementById('tableId');
    if (tableContainer != null) {
        tableContainer.appendChild(table);
    }
}