import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { ErrorModel } from "../../models/ErrorModel";

import { RootState } from "../../app/store";

const initialState: ErrorModel = {
  statusCode: 0,
  message: "",
};

export const errorSlice = createSlice({
  name: "error",
  initialState,
  reducers: {
    setErrorMessage: (state, action: PayloadAction<ErrorModel>) => {
      state.statusCode = action.payload.statusCode;
      state.message = action.payload.message;
    },
  },
});

export const { setErrorMessage } = errorSlice.actions;

export const selectError = (state: RootState) => state.error;

export default errorSlice.reducer;
