import { Container, Row, Col, Button } from "react-bootstrap";
import { MdAdd, MdCloudDownload } from "react-icons/md";
import { AiOutlineMinus } from "react-icons/ai";
import { FaBinoculars } from "react-icons/fa";
import { useTranslation } from "react-i18next";

import { DocumentFamilyModel } from "../../models/DocumentFamilyModel";

import { NameSpace, buttons } from "../../data/AppLanguage";

import Util from "../../utils/Util";

import CategoryItem from "../CategoryItem/CategoryItem";

import "./DocumentItem.css";

interface Props {
  documentFamily?: DocumentFamilyModel;
  downloadList: DocumentFamilyModel[];
  setDownloadList: (families: DocumentFamilyModel[]) => void;
}

const DocumentItem = (props: Props) => {
  var document = props.documentFamily;
  var categoryPath = document?.path;

  const { t, i18n } = useTranslation([NameSpace.COMMON]);
  const language = Util.splitLanguage(i18n.language, 0);

  const downloadDocument = () => {
    if (document) {
      Util.downloadFamilyDocument(
        document.id,
        document.documentId,
        document.path.familyPath,
        language
      );
    }
  };

  const getDocument = () => {
    if (document) {
      Util.getFamilyDocument(document.id, document.documentId);
    }
  };

  return (
    <>
      {document ? (
        <CategoryItem
          name={document.name}
          primaryMedia={document.media}
          path={`/${language}${Util.getCategoryPath(
            categoryPath?.gammaPath,
            categoryPath?.linePath,
            categoryPath?.familyPath
          )}`}
          style={{
            borderBottom: true,
            overlay: true,
            scaleImage: true,
            background: true,
            nameClassName: "text-center text-uppercase",
          }}
          body={
            <Container>
              <Row>
                <Col className="p-0">
                  <Row>
                    <Col>
                      <Button
                        variant="outline-primary"
                        className="document-item-button document-item-button-list mt-0"
                        onClick={() => {
                          var list = [];
                          if (document) {
                            if (
                              !props.downloadList.find(
                                (f) =>
                                  f.id === document?.id &&
                                  f.type.id === document.type.id
                              )
                            ) {
                              list = [...props.downloadList, document];
                              props.setDownloadList(list);

                              sessionStorage.setItem(
                                "downloadList",
                                JSON.stringify(list)
                              );
                            } else {
                              list = props.downloadList.filter(
                                (f) =>
                                  !(
                                    f.id === document?.id &&
                                    f.type.id === document?.type.id
                                  )
                              );
                              props.setDownloadList(list);

                              sessionStorage.setItem(
                                "downloadList",
                                JSON.stringify(list)
                              );
                            }
                          }
                        }}
                      >
                        {!props.downloadList.find(
                          (f) =>
                            f.id === document?.id &&
                            f.type.id === document.type.id
                        ) ? (
                          <>
                            <MdAdd className="document-item-icon-list" />{" "}
                            {t(`${buttons}addDownloadList`)}
                          </>
                        ) : (
                          <>
                            <AiOutlineMinus className="document-item-icon-list" />{" "}
                            {t(`${buttons}deleteDownloadList`)}
                          </>
                        )}
                      </Button>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6} className="pr-0">
                      <Button
                        onClick={() => getDocument()}
                        variant="outline-primary"
                        className="document-item-button document-item-button-view"
                      >
                        <FaBinoculars className="document-item-icon" />{" "}
                        {t(`${buttons}view`)}
                      </Button>
                    </Col>
                    <Col md={6} className="pl-0">
                      <Button
                        variant="outline-primary"
                        className="document-item-button document-item-button-download"
                        onClick={() => downloadDocument()}
                      >
                        <MdCloudDownload className="document-item-icon" />{" "}
                        {t(`${buttons}download`)}
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          }
        />
      ) : null}
    </>
  );
};

export default DocumentItem;
