import React from "react";
import {
  Container,
  Row,
  Col,
  Image, /* Collapse, Button */
  Button,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { NameSpace, buttons } from "../../data/AppLanguage";

import Util from "../../utils/Util";

import "./CaseStudyItem.css";
import { CaseStudyModel } from "../../models/CaseStudyModel";


interface Props {
    caseStudy: CaseStudyModel;
    path: string;
}

interface HtmlTagsRemoverProps {
  text: string;
}

const CaseStudyItem = (props: Props) => {
    const [hover, setHover] = React.useState(false);
    const changeHover = () => setHover(!hover);
  
    const {t, i18n } = useTranslation([NameSpace.COMMON]);
    const language = Util.splitLanguage(i18n.language, 0);

    const removeHtmlTags = ({ text }: HtmlTagsRemoverProps) => {
      let temp = document.createElement("div");
      temp.innerHTML = text;
      return temp.textContent || temp.innerText || "";
    }

    return(        
        <div className="grid-container01">
            <Link to={`/${language}/${props.path}/${props.caseStudy.path}`}>   
            <Col>                
                    <div className="card01">
                        <img 
                        src={props.caseStudy.mediaMain?.path}
                        alt={props.caseStudy.subtitle}
                        className="card-image01"/>
                        <div className="card-body01">
                            <h2 className="card-title01"><b>{props.caseStudy.subtitle}</b></h2>
                            <p className="card-text01"> {removeHtmlTags({ text: props.caseStudy.section1 })}</p>
                        </div>
                    </div>                  
            </Col>
              
            </Link>
        </div>        
    );
}

export default CaseStudyItem;
