import { useState, useEffect, SetStateAction } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Row, Col, Form, Button, FormCheck } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { CountryModel } from "../../models/CountryModel";
import { ContactFormModel } from "../../models/ContactFormModel";

import ContactFormSchema from "../../schemas/ContactFormSchema";

import {
  NameSpace,
  contactForm,
  buttons,
  privacyPolicy,
} from "../../data/AppLanguage";

import Util from "../../utils/Util";

import CountryService from "../../services/api/Country";
import ContactService from "../../services/api/Contact";
import emailjs from '@emailjs/browser'

import PhoneNumberForm from "../PhoneNumberForm/PhoneNumberForm";
import SpinnerAnimation from "../SpinnerAnimation/SpinnerAnimation";

import "./ContactForm.css";

interface Props {
  className?: string;
}

const ContactForm = (props: Props) => {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [countryId, setCountry] = useState('')
  const [location, setLocation] = useState('')
  const [message, setMessage] = useState('')
  const [countries, setCountries] = useState<CountryModel[]>([]);
  const [submitClicked, setSubmitClicked] = useState(0);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    control,
  } = useForm<ContactFormModel>({
    resolver: yupResolver(ContactFormSchema()),
  });

  const { t, i18n } = useTranslation([
    NameSpace.COMMON,
    NameSpace.GLOSSARY,
    NameSpace.ROUTES,
  ]);
  const language = Util.splitLanguage(i18n.language, 0);

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      CountryService.getCountries().then((res) => {
        setCountries(res.data);
      });

      if (formSubmitted) {
        setTimeout(() => {
          setFormSubmitted(false);
          setSubmitClicked(0);
          reset({
            phoneNumber: Util.getCountryCode(
              Util.splitLanguage(i18n.language, 1).toLowerCase()
            ),
          });
        }, 1500);
      }
    }

    return () => {
      mounted = false;
    };
  }, [formSubmitted, reset, i18n.language]);

  const onSubmit: SubmitHandler<ContactFormModel> = (data) => {
    setSubmitting(true);
    data.phoneNumber = `+${data.phoneNumber}`;

    ContactService.postContact(data)
    .then(() => {
      setSubmitting(false);
      setFormSubmitted(true);
    })
    .catch(() => {
      setSubmitting(false);
    });   
  };

  return (
    <Form
      noValidate
      onSubmit={handleSubmit(onSubmit)}
      className={`contact-form-wrapper ${props.className}`}
    >
      <Row>
        <Col>
          <Form.Row>
            <Form.Group as={Col} 
            controlId="nameInput"
            onChange={(e: { target: { value: SetStateAction<string>; }; }) => setName(e.target.value)} value={name} 
            >
              <Form.Label>
                {t(`${contactForm}name`, { ns: NameSpace.COMMON })}*
              </Form.Label>
              <Form.Control
                type="text"
                placeholder={t(`${contactForm}name`, { ns: NameSpace.COMMON })}
                isValid={submitClicked > 0 && !errors.name}
                isInvalid={!!errors.name}
                {...register("name")}
              />
              {errors.name && (
                <Form.Control.Feedback type="invalid">
                  {errors.name.message}
                </Form.Control.Feedback>
              )}
            </Form.Group>
          </Form.Row>
          <Form.Row>
                <Form.Group as={Col}
                  controlId="emailInput"
                  onChange={(e: { target: { value: SetStateAction<string>; }; }) => setEmail(e.target.value)} value={email}>
                    <Form.Label>
                      {t(`${contactForm}email`, { ns: NameSpace.COMMON })}*
                    </Form.Label>
                    <Form.Control
                      type="email"
                      placeholder={t(`${contactForm}email`, {
                        ns: NameSpace.COMMON,
                      })}
                      isValid={submitClicked > 0 && !errors.email}
                      isInvalid={!!errors.email}
                      {...register("email")}
                    />
                    {errors.email && (
                      <Form.Control.Feedback type="invalid">
                        {errors.email.message}
                      </Form.Control.Feedback>
                    )}
                </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} 
            onChange={(e: { target: { value: SetStateAction<string>; }; }) => setPhoneNumber(e.target.value)} value={phoneNumber}
            controlId="phoneNumberInput">
              <Form.Label>
                {t(`${contactForm}phoneNumber`, { ns: NameSpace.COMMON })}*
              </Form.Label>
              <PhoneNumberForm
                control={control}
                submitClicked={submitClicked}
                errors={errors.phoneNumber}
                errorsMessage={errors.phoneNumber?.message}
              />
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} 
            onChange={(e: { target: { value: SetStateAction<string>; }; }) => setCountry(e.target.value)} value={countryId}
            controlId="countryInput">
              <Form.Label>
                {t(`${contactForm}country`, { ns: NameSpace.COMMON })}*
              </Form.Label>
              <Form.Control
                as="select"
                defaultValue=""
                isValid={submitClicked > 0 && !errors.countryId}
                isInvalid={!!errors.countryId}
                {...register("countryId")}
              >
                <option value="" disabled>
                  {t(`${contactForm}country`, { ns: NameSpace.COMMON })}
                </option>
                {countries.map((item, index) => {
                  return (
                    <option key={index} value={item.id}>
                      {item.name}
                    </option>
                  );
                })}
              </Form.Control>
              {errors.countryId && (
                <Form.Control.Feedback type="invalid">
                  {errors.countryId.message}
                </Form.Control.Feedback>
              )}
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} 
            onChange={(e: { target: { value: SetStateAction<string>; }; }) => setLocation(e.target.value)} value={location}
            controlId="locationInput">
              <Form.Label>
                {t(`${contactForm}location`, { ns: NameSpace.COMMON })}
              </Form.Label>
              <Form.Control
                type="text"
                placeholder={t(`${contactForm}location`, {
                  ns: NameSpace.COMMON,
                })}
                isValid={submitClicked > 0 && !errors.location}
                isInvalid={!!errors.location}
                {...register("location")}
              />
              {errors.location && (
                <Form.Control.Feedback type="invalid">
                  {errors.location.message}
                </Form.Control.Feedback>
              )}
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} 
            onChange={(e: { target: { value: SetStateAction<string>; }; }) => setMessage(e.target.value)} value={message}
            controlId="messageInput">
              <Form.Label>
                {t(`${contactForm}message`, { ns: NameSpace.COMMON })}*
              </Form.Label>
              <Form.Control
                as="textarea"
                placeholder={t(`${contactForm}message`, {
                  ns: NameSpace.COMMON,
                })}
                rows={8}
                isValid={submitClicked > 0 && !errors.message}
                isInvalid={!!errors.message}
                {...register("message")}
              />
              {errors.message && (
                <Form.Control.Feedback type="invalid">
                  {errors.message.message}
                </Form.Control.Feedback>
              )}
              <small className="contact-form-wrapper-required">
                {t(`${contactForm}required`, { ns: NameSpace.COMMON })}
              </small>
            </Form.Group>
          </Form.Row>
          <Form.Row className="align-items-center justify-content-end">
            <Col className="contact-form-wrapper-privacy-policy-col">
              <FormCheck>
                <FormCheck.Input
                  isValid={submitClicked > 0 && !errors.privacyPolicy}
                  isInvalid={!!errors.privacyPolicy}
                  {...register("privacyPolicy")}
                />
                <FormCheck.Label>
                  {t(`${privacyPolicy}readAccept`, { ns: NameSpace.COMMON })}{" "}
                  <Link
                    className="contact-form-wrapper-privacy-policy"
                    to={`/${language}/${t("privacyPolicy", {
                      ns: NameSpace.ROUTES,
                    })}`}
                  >
                    {t("privacyPolicy", { ns: NameSpace.GLOSSARY })}
                  </Link>
                  .*
                </FormCheck.Label>
                {errors.privacyPolicy && (
                  <Form.Control.Feedback type="invalid">
                    {errors.privacyPolicy.message}
                  </Form.Control.Feedback>
                )}
              </FormCheck>
            </Col>
          </Form.Row>
          <Form.Row>
            <Col className="text-center mt-4 contact-form-wrapper-submit-col">
              {submitting ? (
                <SpinnerAnimation />
              ) : formSubmitted ? (
                <p className="contact-form-wrapper-submit-success">
                  {t(`${contactForm}messageSuccess`, { ns: NameSpace.COMMON })}
                </p>
              ) : (
                <Button
                  type="submit"
                  variant="outline-dark"
                  className="contact-form-wrapper-submit"
                  onClick={() => setSubmitClicked(submitClicked + 1)}
                >
                  {t(`${buttons}submit`, { ns: NameSpace.COMMON })}
                </Button>
              )}
            </Col>
          </Form.Row>
        </Col>
      </Row>
    </Form>
  );
};

export default ContactForm;
