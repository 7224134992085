import { Carousel, Container, Row, Col, Image, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { MdAdd } from "react-icons/md";
import { useTranslation } from "react-i18next";

import { CarouselModel } from "../../models/CarouselModel";

import { MediaType } from "../../enums/MediaType";

import Util from "../../utils/Util";

import Video from "../Video/Video";

import "./ProductCarouselItem.css";
import { buttons, NameSpace } from "../../data/AppLanguage";

interface Props {
  carousel: CarouselModel;
  odd: boolean;
}

const ProductCarouselItem = (props: Props) => {
  const { i18n } = useTranslation();
  const language = Util.splitLanguage(i18n.language, 0);

  const { t } = useTranslation([NameSpace.COMMON]);

  return (
    <Carousel
      indicators={!props.odd ? false : true}
      className="product-carousel"
    >
      {props.carousel.mediaCollection.map((item, index) => {
        var family = item.familyPath;
        return (
          <Carousel.Item key={index} className="product-carousel-item">
            {item.media?.type === MediaType.IMAGE ? (
              <Image
                className="product-carousel-item-media d-block w-100"
                src={item.media.path}
                alt={item.title}
              />
            ) : (
              <Video
                className="product-carousel-item-media d-block w-100"
                path={item.media.path}
              />
            )}
            <Link
              to={
                family
                  ? `/${language}/${family.gammaPath}/${family.linePath}/${family.familyPath}`
                  : ""
              }
              className="product-carousel-item-link"
            >
              <Carousel.Caption
                className={`product-carousel-item-link-caption ${
                  props.odd
                    ? "product-carousel-item-link-caption-odd d-flex align-items-center justify-content-center"
                    : "product-carousel-item-link-caption-regular"
                }`}
              >
                <Container>
                  <Row className={props.odd ? "justify-content-center" : ""}>
                    {!props.odd ? <Col md="auto"></Col> : null}
                    <Col
                      md={props.odd ? "auto" : undefined}
                      className={`product-carousel-item-link-caption-title ${
                        props.odd
                          ? "product-carousel-item-link-caption-odd-title"
                          : "product-carousel-item-link-caption-regular-title"
                      }`}
                    >
                      {item.title}
                    </Col>
                    {!props.odd ? (
                      <Col
                        sm={1}
                        className="product-carousel-item-link-caption-icon"
                      >
                        <MdAdd />
                      </Col>
                    ) : null}
                  </Row>
                </Container>
              </Carousel.Caption>
            </Link>
            <Carousel.Caption className="product-carousel-item-caption-mobile">
              <h3 className="product-carousel-item-caption-mobile-title">
                {item.title}
              </h3>
              <Button
                variant="outline-light"
                as={Link}
                className="product-carousel-item-caption-mobile-button mt-7"
                to={
                  family
                    ? `/${language}/${family.gammaPath}/${family.linePath}/${family.familyPath}`
                    : ""
                }
              >
                {t(`${buttons}findOutMore`)}
              </Button>
            </Carousel.Caption>
          </Carousel.Item>
        );
      })}
    </Carousel>
  );
};

export default ProductCarouselItem;
