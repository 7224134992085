import { useEffect, useState } from "react";
import { Row, Col, Collapse } from "react-bootstrap";
import { Link } from "react-router-dom";
import { MdAdd } from "react-icons/md";
import { AiOutlineMinus } from "react-icons/ai";
import { useTranslation } from "react-i18next";

import { MenuLeftModel } from "../../models/MenuLeftModel";
import { MenuSubNavModel } from "../../models/MenuSubNavModel";
import { GammaModel } from "../../models/GammaModel";

import { mediumWidth } from "../../data/BreakpointsWidth";
import { menuGammasName } from "../../data/MenuLeftData";
import { NameSpace } from "../../data/AppLanguage";

import Util from "../../utils/Util";

import "./MenuItem.css";

interface Props {
  item: MenuLeftModel;
  setOpen: (open: boolean) => void;
  setMobileSubNav: (mobileSubNav: MenuSubNavModel) => void;
  gammas: GammaModel[];
}

const MenuLeftItem = (props: Props) => {
  const [subNav, setSubNav] = useState(false);
  const [width, setWidth] = useState<number>(window.innerWidth);
  const [hoverItem, setHoverItem] = useState(false);

  const { t, i18n } = useTranslation([NameSpace.NAVIGATION, NameSpace.ROUTES]);
  const language = Util.splitLanguage(i18n.language, 0);

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  const getMobileSubitems = () => {
    if (props.item.subNav) {
      if (props.item.name === menuGammasName) {
        props.setMobileSubNav({
          title: t(menuGammasName, { ns: NameSpace.NAVIGATION }),
          subitems: props.gammas.map((g) => ({
            name: g.name,
            path: `/${language}/${g.path}`,
          })),
        });
      } else {
        props.setMobileSubNav({
          title: t(props.item.name, { ns: NameSpace.NAVIGATION }),
          subitems: props.item.subNav.map((s) => ({
            name: t(s.name, { ns: NameSpace.NAVIGATION }),
            path: `/${language}/${Util.getRoutesPath(s.routes)}`,
          })),
        });
      }
    }
  };

  const subNavIcon = () => (
    <Col className="text-left" lg={6} md="auto">
      <span
        className={`item-pointer ${
          hoverItem && width > mediumWidth ? "item-pointer-hover" : ""
        }`}
        onClick={() => {
          if (width <= mediumWidth) {
            getMobileSubitems();
          } else {
            if (props.item.subNav) setSubNav(!subNav);
          }
        }}
        onMouseOver={() => setHoverItem(!hoverItem)}
        onMouseOut={() => setHoverItem(!hoverItem)}
      >
        {width > mediumWidth ? (
          props.item.subNav ? (
            subNav ? (
              <AiOutlineMinus />
            ) : (
              <MdAdd />
            )
          ) : null
        ) : props.item.subNav ? (
          <MdAdd />
        ) : null}
      </span>
    </Col>
  );

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      window.addEventListener("resize", handleWindowSizeChange);
    }
    return () => {
      mounted = false;

      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  return (
    <>
      <Row
        className={`item ${
          width < mediumWidth ? "justify-content-center mt-3" : ""
        }`}
      >
        <Col
          className={`item-wrapper ${
            hoverItem && width >= mediumWidth ? "item-wrapper-hover" : ""
          }`}
          md="auto"
          lg={6}
        >
          {!props.item.subNav ? (
            <Link
              to={`/${language}/${Util.getRoutesPath(props.item.routes)}`}
              onClick={() => {
                props.setOpen(false);
                setSubNav(!subNav);
                setHoverItem(true);
              }}
              onMouseOver={() => setHoverItem(!hoverItem)}
              onMouseOut={() => setHoverItem(!hoverItem)}
            >
              <span>{t(props.item.name, { ns: NameSpace.NAVIGATION })}</span>
            </Link>
          ) : (
            <span
              className="item-pointer"
              onClick={() => {
                if (width < mediumWidth) {
                  getMobileSubitems();
                } else setSubNav(!subNav);
              }}
              onMouseOver={() => setHoverItem(!hoverItem)}
              onMouseOut={() => setHoverItem(!hoverItem)}
            >
              {t(props.item.name, { ns: NameSpace.NAVIGATION })}
            </span>
          )}
        </Col>
        {width < mediumWidth
          ? props.item.subNav
            ? subNavIcon()
            : null
          : subNavIcon()}
      </Row>
      {width < mediumWidth ? null : (
        <Collapse in={subNav}>
          <div>
            {props.item.subNav?.map((item, index) => {
              return (
                <Row key={index} className="subitem">
                  <Col xs={8} key={index} className="subitem-wrapper">
                    <Link
                      key={index}
                      to={`/${language}/${Util.getRoutesPath(item.routes)}`}
                      onClick={() => {
                        props.setOpen(false);
                        setSubNav(!subNav);
                      }}
                    >
                      {t(item.name, { ns: NameSpace.NAVIGATION })}
                    </Link>
                  </Col>
                </Row>
              );
            })}
          </div>
        </Collapse>
      )}
    </>
  );
};

export default MenuLeftItem;
