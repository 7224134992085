import { Environment } from "../enums/Environment";

const dev = {
  url: {
    API_URL: "https://api.mafirol.info/api/",
  },
};

const prod = {
  url: {
    API_URL: "https://api.mafirol.info/api/",
  },
};

export const config =
  process.env.NODE_ENV === Environment.DEVELOPMENT ? dev : prod;


  //------- Produção -------------
  // https://api.mafirol.info/api/
//https://localhost:44385/api/
//https://localhost:44390/api/
//https://apistage.mafirol.info/api/