import { useState } from "react";
import { Col } from "react-bootstrap";

import { DocumentFamilyModel } from "../../models/DocumentFamilyModel";
import { DocumentTypeModel } from "../../models/DocumentTypeModel";

import DocumentItem from "../DocumentItem/DocumentItem";
import Pagination from "../Pagination/Pagination";

interface Props {
  documentTypes: DocumentTypeModel[];
  downloadList: DocumentFamilyModel[];
  documentTypeId: string;
  documentLineId: string;
  documentFamilyId: string;
  clickedLine: boolean;
  clickedFamily: boolean;
  setDownloadList: (downloadList: DocumentFamilyModel[]) => void;
}

const DocumentCollection = (props: Props) => {
  const defaultItemsPerPage = 8;
  const defaultCurrentPage = 1;

  const [currentPage, setCurrentPage] = useState(defaultCurrentPage);
  const [itemsPerPage, setItemsPerPage] = useState(defaultItemsPerPage);

  const documentTypeFamilies = props.documentTypes
    .find((d) => d.type.id === props.documentTypeId)
    ?.lines.find(
      (l) => props.documentLineId && l.id === props.documentLineId
    )?.families;

  const indexLastItem = currentPage * itemsPerPage;
  const indexFirstItem = indexLastItem - itemsPerPage;
  const totalPages = Math.ceil(
    (documentTypeFamilies?.length || 0) / itemsPerPage
  );
  const paginatedItems = documentTypeFamilies?.slice(
    indexFirstItem,
    indexLastItem
  );

  const setPagination = (page: number) => {
    setCurrentPage(page);
  };

  const colClassName =
    "download-area-document-item-col d-flex justify-content-center";

  return (
    <>
      {props.clickedLine ? (
        <>
          {paginatedItems
            ? paginatedItems.map((item, index) => {
                return (
                  <Col md={6} key={index} className={colClassName}>
                    <DocumentItem
                      documentFamily={item}
                      downloadList={props.downloadList}
                      setDownloadList={props.setDownloadList}
                    />
                  </Col>
                );
              })
            : null}
        </>
      ) : props.clickedFamily ? (
        <Col md={6} className={colClassName}>
          {documentTypeFamilies ? (
            <DocumentItem
              documentFamily={documentTypeFamilies.find(
                (f) => f.id === props.documentFamilyId
              )}
              downloadList={props.downloadList}
              setDownloadList={props.setDownloadList}
            />
          ) : null}
        </Col>
      ) : null}
      <Pagination
        pagination={{
          totalPages: props.clickedLine ? totalPages : 0,
          defaultCurrentPage,
          currentPage,
          setPagination,
          defaultItemsPerPage,
          itemsPerPage,
          setItemsPerPage,
        }}
      />
    </>
  );
};

export default DocumentCollection;
