import { Container, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { DocumentTypeModel } from "../../models/DocumentTypeModel";
import { DocumentClickedModel } from "../../models/DocumentClickedModel";

import { NameSpace } from "../../data/AppLanguage";

import DownloadAreaItemCollection from "../DownloadAreaItemCollection/DownloadAreaItemCollection";

import "./DownloadAreaNav.css";

interface Props {
  documentTypes: DocumentTypeModel[];
  clickedLine: boolean;
  documentClicked: DocumentClickedModel;
  setItem: (id: string, typeId: string) => void;
}

const DownloadAreaNav = (props: Props) => {
  const { t } = useTranslation([NameSpace.NAVIGATION]);

  return (
    <Container className="download-area shadow-sm mb-5 bg-white rounded">
      <Row className="download-area-header rounded-top">
        <Col>{t("downloadArea")}</Col>
      </Row>
      {props.documentTypes.map((item, index) => {
        return (
          <DownloadAreaItemCollection
            clickedLine={props.clickedLine}
            documentClicked={props.documentClicked}
            documentType={item}
            setItem={props.setItem}
            key={index}
          />
        );
      })}
    </Container>
  );
};

export default DownloadAreaNav;
