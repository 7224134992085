import { CarouselModel } from "../../models/CarouselModel";

import { get } from "./Api";

const endpoint = "/carousels";

const Carousel = {
  getCarousels: async (language: string) =>
    get<CarouselModel[]>(endpoint, { params: { language } }),
};

export default Carousel;
