import { useEffect, useState } from "react";
import { Button, Col, Container, Image, Row } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { ErrorModel } from "../../models/ErrorModel";

import { NameSpace, error, buttons } from "../../data/AppLanguage";

import Util from "../../utils/Util";

import errorImage from "../../assets/images/error.svg";

import "./Error.css";

const Error = () => {
  const [errorData, setErrorData] = useState<ErrorModel>({
    statusCode: 0,
    message: "",
  });
  const location = useLocation<ErrorModel>();

  const { t } = useTranslation([NameSpace.COMMON, NameSpace.PAGES]);

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      location.state !== undefined
        ? setErrorData(location.state)
        : setErrorData({
            statusCode: 404,
            message: t(`${error}pageNotFound`, { ns: NameSpace.PAGES }),
          });
    }

    return () => {
      mounted = false;
    };
  }, [location, t]);
  document.title = Util.setDocumentTitle(
    t(`${error}statusCode`, { ns: NameSpace.PAGES, code: errorData.statusCode })
  );
  return (
    <Container className="error mb-5">
      <Row className="text-center">
        <Col>
          <p className="error-code">{errorData.statusCode}</p>
          <Image src={errorImage} className="error-image"></Image>
          <p>{errorData.message}</p>
          <Button as={Link} to="/" className="return-button">
            {t(`${buttons}return`, { ns: NameSpace.COMMON })}
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default Error;
