import { Row, Col, Button, ColProps } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { FamilyModel } from "../../models/FamilyModel";
import { TypeModel } from "../../models/TypeModel";

import { NameSpace, buttons } from "../../data/AppLanguage";

import FamilyAccordion from "../FamilyAccordion/FamilyAccordion";
import Description from "../Description/Description";

import "./FamilyDescription.css";
import { useEffect, useState } from "react";
import DataSheetEntitie from "../../models/DataSheetEntitie";
import DataSheetService from "../../services/api/DataSheetService";
import dataTransferDownIcon from '../../assets/icons/cil-data-transfer-down.svg';
import Util from "../../utils/Util";
import i18n from "../../i18n";
import PDFGenerator from "../PDFGenerator/PDFGeneratorDataSheet";
import { useHistory } from "react-router-dom";
import DataSheetAccordion from "../DataSheetAccordion/DataSheetAccordion";


interface Props {
  articleClassName: string;
  family?: FamilyModel;
  titleColSpec?: ColProps;
  section1ColSpec?: ColProps;
  section2ColSpec?: ColProps;
  section3ColSpec?: ColProps;
  askInformationColSpec?: ColProps;
  documentsColSpec?: ColProps;
  className?: string;
  dataSheets?: DataSheetEntitie [];
  downloadDocument: (item: TypeModel) => void;
  askInformation: () => void;
 
}

const FamilyDescription = (props: Props) => {
  const { t } = useTranslation([NameSpace.COMMON, NameSpace.PAGES]);
 
  return (
    <article className={`family-description ${props.articleClassName}`}>
      <Row className="mb-6">
        <Col md={props.titleColSpec?.md} className="family-description-title">
          {props.family?.name}
        </Col>
      </Row>
      {props.family?.section1 ? (
        <Row>
          <Col
            md={props.section1ColSpec?.md}
            className="family-description-section-1"
          >
            <Description text={props.family?.section1} />
          </Col>
        </Row>
      ) : null}
      <Row
        className={`family-description-buttons mt-4 ${
          props.family?.documents.length === 1 ? "justify-content-center" : ""
        }`}
      >
        <Col
          xs={props.askInformationColSpec?.xs}
          sm={props.askInformationColSpec?.sm}
          md={props.askInformationColSpec?.md}
          lg={props.askInformationColSpec?.lg}
          xl={props.askInformationColSpec?.xl}
          className="family-description-buttons-info pl-2 pr-2"
        >
          <Button variant="outline-dark" onClick={props.askInformation}
           style={{ width: '100%'}}
          >
            {t(`${buttons}askInformation`, { ns: NameSpace.COMMON })}
          </Button>
        </Col>
        {props.family?.documents.map((item, index) => {
          return (
            <Col
              xs={props.documentsColSpec?.xs}
              sm={props.documentsColSpec?.sm}
              md={props.documentsColSpec?.md}
              lg={props.documentsColSpec?.lg}
              xl={props.documentsColSpec?.xl}
              className="family-description-buttons-documents pl-2 pr-2"
              key={index}
            >
              <Button 
              style={{ marginLeft: '5px', width: '100%'}}
              onClick={() => props.downloadDocument(item)}              
              >
                {item.name}
              </Button>
            </Col>
          );
        })}
      </Row>
      {/* {props.family?.section2 ? (
        <Row className="mt-4 justify-content-md-center">
          <Col md={props.section2ColSpec?.md}>
            <FamilyAccordion
              title={t("availableVersions", { ns: NameSpace.PAGES })}
              description={props.family?.section2}
              className={`family-accordion-border-top ${
                !props.family?.section3
                  ? "family-accordion-border-bottom"
                  : "border-bottom-0"
              }`}
            />
          </Col>
        </Row>
      ) : null}      */}
       {/* {!props.dataSheets?.length  && props.family?.section3 ? (
        <Row
          className={`justify-content-md-center ${
            props.family?.section2 ? "mt-2" : "mt-4"
          }`}
        >
          <Col
            md={props.section3ColSpec?.md}
            className="justify-content-center"
          >
            <FamilyAccordion
              title={t("productInformation", { ns: NameSpace.PAGES })}
              description={props.family?.section3}
              className="family-accordion-border-top family-accordion-border-bottom"
            />
          </Col>
        </Row>
      ) : null} */}

      {/* {props.dataSheets?.length ? (
        <Row
          className={`justify-content-md-center ${
            props.family?.section2 ? "mt-2" : "mt-4"
          }`}
        >
          <Col
            md={props.section3ColSpec?.md}
            className="justify-content-center"
          >
            <DataSheetAccordion
              title={t("dataSheets", { ns: NameSpace.PAGES })}
              dataSheetsDescription={props.dataSheets}
              className="family-accordion-border-top family-accordion-border-bottom"
            />
          </Col>
        </Row>
      ) : null} */}
    </article>
  );
};

export default FamilyDescription;
