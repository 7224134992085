import { useState } from "react";
import { Collapse } from "react-bootstrap";

import { DocumentTypeModel } from "../../models/DocumentTypeModel";
import { DocumentClickedModel } from "../../models/DocumentClickedModel";

import DownloadAreaItem from "./DownloadAreaItem";
import DownloadAreaSubItem from "./DownloadAreaSubItem";

interface Props {
  clickedLine: boolean;
  documentClicked: DocumentClickedModel;
  documentType: DocumentTypeModel;
  setItem: (id: string, typeId: string) => void;
}

const DownloadAreaItemCollection = (props: Props) => {
  const [subNav, setSubNav] = useState(false);

  return (
    <>
      <DownloadAreaItem
        label={props.documentType.type.name}
        setSubNav={setSubNav}
        subNav={subNav}
        subMenu={props.documentType.lines.length ? true : false}
        documentClicked={props.documentClicked}
        documentType={props.documentType}
      />
      <Collapse in={subNav}>
        <div>
          {props.documentType.lines?.map((item, index) => {
            return (
              <DownloadAreaSubItem
                clickedLine={props.clickedLine}
                documentClicked={props.documentClicked}
                line={item}
                setItem={props.setItem}
                documentType={props.documentType}
                key={index}
              />
            );
          })}
        </div>
      </Collapse>
    </>
  );
};

export default DownloadAreaItemCollection;
