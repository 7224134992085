import { useState } from "react";
import { Card, ResponsiveEmbed } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Markup } from "interweave";
import { useTranslation } from "react-i18next";

import { GalleryModel } from "../../models/GalleryModel";

import Util from "../../utils/Util";

import "./GalleryItem.css";

interface Props {
  galleryItem: GalleryModel;
  type?: boolean;
  path: string;
  cardClassName?: string;
}

const GalleryItem = (props: Props) => {
  const [hover, setHover] = useState(false);
  const changeHover = () => setHover(!hover);

  const item = props.galleryItem;

  const { i18n } = useTranslation();
  const language = Util.splitLanguage(i18n.language, 0);

  return (
    <Card className={`gallery-item ${props.cardClassName}`}>
      <Link
        to={`/${language}/${props.path}/${item.path}`}
        onMouseOver={changeHover}
        onMouseOut={changeHover}
      >
        <ResponsiveEmbed aspectRatio="16by9">
          <Card.Img variant="top" src={item.media.path} alt={item.title} />
        </ResponsiveEmbed>
        <Card.Body className="gallery-item-body">
          <Card.Text
            className={`gallery-item-subtitle ${
              props.type ? "gallery-item-type" : ""
            }`}
          >
            {props.type
              ? item.type.name
              : item.startDate
              ? item.startDate === item.endDate
                ? item.startDate
                : `${item.startDate} - ${item.endDate}`
              : null}
          </Card.Text>
          <Card.Title className="gallery-item-title">{item.title}</Card.Title>
          <hr
            className={`gallery-item-divider ${
              hover ? "gallery-item-divider-hover" : ""
            }`}
          />
          {item.review ? (
            <div
              className={`gallery-item-description ${
                !item.reviewField ? "gallery-item-description-clamping" : ""
              }`}
            >
              <Card.Text as={Markup} content={item.review} />
            </div>
          ) : null}
        </Card.Body>
      </Link>
    </Card>
  );
};

export default GalleryItem;
