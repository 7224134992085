import { useEffect, useState } from "react";

import SubfamilyDesktop from "./SubfamilyDesktop";
import SubfamilyMobile from "./SubfamilyMobile";

const Subfamily = () => {
    const [isSmallScreen, setIsSmallScreen] = useState<boolean>(false);

    useEffect(() => { 

        setIsSmallScreen(window.innerWidth < 954); // Verifique se a largura da tela é menor que 1200px ao carregar a página
        window.addEventListener("resize", () => {
            setIsSmallScreen(window.innerWidth < 954); // Atualize o estado quando a tela for redimensionada
        });

        return () => {
            window.removeEventListener("resize", () => {});
        };
    
    }, []);

    return (
        <>         
            {
                !isSmallScreen ? (
                    <SubfamilyDesktop />
                ) : (
                    <SubfamilyMobile />
                )
            }                             
        </>

    )
}

export default Subfamily;


