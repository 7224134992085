import Viewer from "react-viewer";

interface Props {
  visible: boolean;
  onClose: () => void;
  activeIndex: number;
  images: { src: string }[];
}

const MediaViewer = (props: Props) => {
  return (
    <Viewer
      visible={props.visible}
      onClose={props.onClose}
      onMaskClick={(e: any) => {
        if (e.target.className === "react-viewer-canvas") {
          props.onClose();
        }
      }}
      images={props.images}
      activeIndex={props.activeIndex}
      zIndex={2000}
      drag={false}
      rotatable={false}
      scalable={false}
      zoomSpeed={0.1}
    />
  );
};

export default MediaViewer;
