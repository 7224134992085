import { EventModel } from "../../models/EventModel";

import { get } from "./Api";

const endpoint = "/events/";

const Event = {
  getEvents: async (language: string) =>
    get<EventModel[]>(endpoint, { params: { language } }),

    getEvent: async (path: string) =>
    get<EventModel>(`${endpoint}${path}`),

    
    getEventss: async (language?: string) =>
    get<EventModel[]>(`${endpoint}events`, {
      params: { language },
    }),
};

export default Event;
