import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { ContentPageModel } from "../../models/ContentPageModel";

import { NameSpace } from "../../data/AppLanguage";
import { ContentPageType } from "../../enums/ContentPageType";

import Util from "../../utils/Util";

import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { selectJobOffers } from "../../features/joboffers/joboffersSlice";
import { getJobOffers } from "../../features/middlewares/jobOffersMiddleware";

import ContentPageService from "../../services/api/ContentPage";

import MainTitle from "../../components/MainTitle/MainTitle";

const Recruitment = () => {
  const [group, setGroup] = useState<ContentPageModel>();
  const jobOffers = useAppSelector(selectJobOffers);
  const dispatch = useAppDispatch();

  const { t, i18n } = useTranslation([NameSpace.NAVIGATION]);
  const language = Util.splitLanguage(i18n.language, 0);
  const recruitmentTitle = t("recruitment");

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      if (!jobOffers.data.length) dispatch(getJobOffers());

      ContentPageService.getContentPages(
        ContentPageType.RECRUITMENT,
        language
      ).then((res) => {
        document.title = Util.setDocumentTitle(recruitmentTitle);
        setGroup(res.data);
      });
    }

    return () => {
      mounted = false;
    };
  }, [dispatch, jobOffers.data.length, language, recruitmentTitle]);

  return (
    <>
      <MainTitle
        media={group?.media}
        name={recruitmentTitle}
        height="100vh"
        recruitment={{ jobOffers: jobOffers.data.length }}
        className="mb-0"
      />
    </>
  );
};

export default Recruitment;
