import { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { CarouselModel } from "../../models/CarouselModel";

import Util from "../../utils/Util";

import CarouselService from "../../services/api/Carousel";

import ProductCarouselItem from "../ProductCarouselItem/ProductCarouselItem";

import "./ProductCarousel.css";

interface Props {
  index: number;
  collection: CarouselModel[];
  odd: boolean;
  className?: string;
}

const ProductCarousel = () => {
  const [carousels, setCarousels] = useState<CarouselModel[]>([]);

  const { i18n } = useTranslation();
  const language = Util.splitLanguage(i18n.language, 0);

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      CarouselService.getCarousels(language).then((res) => {
        setCarousels(res.data);
      });
    }

    return () => {
      mounted = false;
    };
  }, [language]);

  const carouselCollection = [];

  const col = (carousel: Props) => {
    return (
      <Col
        xs={12}
        lg
        xl
        className={`product-carousel-wrapper-col ${carousel.className}`}
      >
        <ProductCarouselItem
          carousel={carousel.collection[carousel.index]}
          odd={carousel.odd}
        />
      </Col>
    );
  };

  for (let i = 0; i < carousels.length; i += 2) {
    carouselCollection.push(
      <Row key={i} className="product-carousel-wrapper">
        {col({
          index: i,
          collection: carousels,
          odd: carousels[i + 1] ? false : true,
          className: !carousels[i + 1] ? "pl-0 pr-0" : "",
        })}
        {carousels[i + 1]
          ? col({ index: i + 1, collection: carousels, odd: false })
          : null}
      </Row>
    );
  }

  return (
    <article className="mt-6">
      <Container fluid>{carouselCollection}</Container>
    </article>
  );
};

export default ProductCarousel;
