import { Link } from "react-router-dom";
import { Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
/* Commented just in case it would be needed again */
/* import { MdAdd } from "react-icons/md"; */

import { MultiCarouselItemModel } from "../../models/MultiCarouselItemModel";

import { NameSpace } from "../../data/AppLanguage";

import Util from "../../utils/Util";

import "./MultiCarouselItem.css";

interface Props {
  item: MultiCarouselItemModel;
  overlay?: boolean;
  scaleImage?: boolean;
}

const MultiCarouselItem = (props: Props) => {
  const { i18n } = useTranslation([NameSpace.COMMON]);
  const language = Util.splitLanguage(i18n.language, 0);

  const path = props.item.path;
  const url = `/${language}${Util.getCategoryPath(
    path?.gammaPath,
    path?.linePath,
    path?.familyPath
  )}`;

  return (
    <Card className="multicarousel-item">
      <Link to={url} draggable={false}>
        <div className="category-item-overlay">
          <Card.Img
            src={props.item.media.path}
            draggable={false}
            className="multicarousel-item-media category-item-primary-media-scale"
          />
          {/* Commented just in case it would be needed again */}
          {/* <div className="category-item-overlay-wrapper">
            <MdAdd className="category-item-overlay-wrapper-icon" />
          </div> */}
        </div>
      </Link>
      <Card.Body className="multicarousel-item-body">
        <Card.Subtitle className="multicarousel-item-body-subtitle">
          {props.item.line}
        </Card.Subtitle>
        <Card.Title className="multicarousel-item-body-title mt-2">
          {props.item.name}
        </Card.Title>
      </Card.Body>
    </Card>
  );
};

export default MultiCarouselItem;
