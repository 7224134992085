import { useEffect, useState } from 'react';
import DataSheetService from "../../services/api/DataSheetService";
import { useHistory } from "react-router-dom";
import Util from "../../utils/Util";
import i18n from "../../i18n";
import DataSheetType1 from '../DataSheetTypes/DataSheetType1';
import DataSheetType2 from '../DataSheetTypes/DataSheetType2';
import style from './PDFGenerator.module.css'
import { useTranslation } from "react-i18next";
import { NameSpace, contactForm } from "../../data/AppLanguage";




const PDFGenerator = () => {
    const [dataSheetData, setDataSheetData] = useState({});
    const language = Util.splitLanguage(i18n.language, 0);
    const { t } = useTranslation([NameSpace.COMMON]);
    const [isGeneratingPDF, setIsGeneratingPDF] = useState(true);
    const history = useHistory();

    
    const dataSheetId = localStorage.getItem('dataSheetId')
    

    useEffect(() => {
        DataSheetService.getDataSheetById(dataSheetId, language).then((result) => {
            setDataSheetData(result.data);         
            // console.log("veio:", result.data) 
            setTimeout(() => {
                history.goBack();             
            }, 2000);            
          })     
        //   
    },[])


    return(                 
        <div>
            {isGeneratingPDF && 
                 <div className={style.overlay}>
                    <div className={style.loadingOverlay}>
                            <div className="spinner-border text-primary" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                            <p className="ml-3">{t(`${contactForm}loading`)}</p>
                    </div>    
                </div> 
            }
            {
                dataSheetData.datasheet_type_id == 1 
                ? <DataSheetType1 dataSheetData={dataSheetData} isGeneratingPDF={isGeneratingPDF}/> 
                : null
            }
             {
                dataSheetData.datasheet_type_id == 2 
                ? <DataSheetType2 dataSheetData={dataSheetData} /> 
                : null
            }
        </div>
    );
}

export default PDFGenerator



