import { SocialMediaModel } from "../../models/SocialMediaModel";

import Facebook from "../SocialMediaCollection/SocialMedia/Facebook";
import LinkedIn from "../SocialMediaCollection/SocialMedia/LinkedIn";
import Youtube from "../SocialMediaCollection/SocialMedia/Youtube";
import Instagram from "../SocialMediaCollection/SocialMedia/Instagram";

import "./SocialMedia.css";

interface Props {
  title?: string;
  className?: string;
  titleClassName?: string;
  social?: SocialMediaModel;
}

const SocialMedia = (props: Props) => {
  return (
    <div className={props.className}>
      {props.title ? (
        <p className={`social-media-title ${props.titleClassName}`}>
          {props.title}
        </p>
      ) : null}
      <div>
        <a href="https://www.facebook.com/mafirol/">
          <Facebook social={props.social} />
        </a>
        <a href="https://www.linkedin.com/company/grupo-mafirol/">
          <LinkedIn social={props.social} />
        </a>
        <a href="https://www.youtube.com/c/GrupoMAFIROL/">
          <Youtube social={props.social} />
        </a>
        <a href="https://www.instagram.com/grupomafirol/">
          <Instagram social={props.social} />
        </a>
      </div>
    </div>
  );
};

export default SocialMedia;
