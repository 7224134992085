import { Row, Col } from "react-bootstrap";
import { MdLocationOn } from "react-icons/md";
import { useTranslation } from "react-i18next";

import { LocationModel } from "../../models/LocationModel";

import { NameSpace } from "../../data/AppLanguage";

import Location from "../Location/Location";

import "./LocationCollection.css";

interface Props {
  locations: LocationModel[];
}

const LocationCollection = (props: Props) => {
  const { t } = useTranslation([NameSpace.PAGES]);

  return (
    <>
      <Row className="location-collection mt-5">
        <Col className="location-collection-title">
          <MdLocationOn className="location-collection-title-icon" />{" "}
          {t("locations")}
        </Col>
      </Row>
      {props.locations?.map((item, index) => {
        return <Location location={item} key={index} />;
      })}
    </>
  );
};

export default LocationCollection;
