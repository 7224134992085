import { Fragment, useState } from "react";
import { Row, Col, ListGroup } from "react-bootstrap";

import { DocumentFamilyModel } from "../../models/DocumentFamilyModel";
import { DocumentTypeModel } from "../../models/DocumentTypeModel";

import DocumentItem from "../DocumentItem/DocumentItem";
import Pagination from "../Pagination/Pagination";

import "./DocumentSearchCollection.css";

interface Props {
  documentTypes: DocumentTypeModel[];
  downloadList: DocumentFamilyModel[];
  search: string;
  setDownloadList: (downloadList: DocumentFamilyModel[]) => void;
}

const DocumentSearchCollection = (props: Props) => {
  const defaultItemsPerPage = 1;
  const defaultCurrentPage = 1;

  const [currentPage, setCurrentPage] = useState(defaultCurrentPage);
  const [itemsPerPage, setItemsPerPage] = useState(defaultItemsPerPage);

  const documentTypes = props.documentTypes.map((documentType) => {
    return {
      ...documentType,
      lines: documentType.lines.map((line) => {
        return {
          ...line,
          families: line.families.filter((family) => {
            return family.name
              .toLowerCase()
              .includes(props.search.toLowerCase());
          }),
        };
      }),
    };
  });

  const indexLastItem = currentPage * itemsPerPage;
  const indexFirstItem = indexLastItem - itemsPerPage;
  const totalPages = Math.ceil((documentTypes?.length || 0) / itemsPerPage);
  const paginatedItems = documentTypes?.slice(indexFirstItem, indexLastItem);

  const setPagination = (page: number) => {
    setCurrentPage(page);
  };

  const colClassName =
    "download-area-document-item-col d-flex justify-content-center";

  return (
    <Col className="document-search-collection">
      {paginatedItems.map((documentType, documentTypeIndex) => {
        return (
          <Fragment key={documentTypeIndex}>
            <Row className="justify-content-md-center" key={documentTypeIndex}>
              <Col>
                <ListGroup as="ol">
                  <ListGroup.Item
                    as="li"
                    className="document-search-collection-document-type"
                  >
                    {documentType.type.name}
                  </ListGroup.Item>
                </ListGroup>
              </Col>
            </Row>

            {documentType.lines.map((line, lineIndex) => {
              return (
                <Fragment key={lineIndex}>
                  <Row className="justify-content-md-center" key={lineIndex}>
                    <Col>
                      <ListGroup as="ol">
                        <ListGroup.Item
                          as="li"
                          className="document-search-collection-line"
                        >
                          {line.name}
                        </ListGroup.Item>
                      </ListGroup>
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    {line.families.map((family, familyIndex) => {
                      return (
                        <Col md={6} key={familyIndex} className={colClassName}>
                          <DocumentItem
                            downloadList={props.downloadList}
                            setDownloadList={props.setDownloadList}
                            documentFamily={family}
                          />
                        </Col>
                      );
                    })}
                  </Row>
                </Fragment>
              );
            })}
          </Fragment>
        );
      })}
      <Pagination
        pagination={{
          totalPages,
          defaultCurrentPage,
          currentPage,
          setPagination,
          defaultItemsPerPage,
          itemsPerPage,
          setItemsPerPage,
        }}
      />
    </Col>
  );
};

export default DocumentSearchCollection;
