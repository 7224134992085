import { useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { FaBinoculars } from "react-icons/fa";
import { MdCloudDownload } from "react-icons/md";
import { AiOutlineMinus } from "react-icons/ai";
import { useTranslation } from "react-i18next";

import { DocumentFamilyModel } from "../../models/DocumentFamilyModel";

import Util from "../../utils/Util";

import "./DownloadAreaListItem.css";

interface Props {
  downloadItem: DocumentFamilyModel;
  downloadList: DocumentFamilyModel[];
  currentDownloadListItem: DocumentFamilyModel | null;
  setDownloadList: (family: DocumentFamilyModel[]) => void;
  setCurrentDownloadListItem: (currentItem: DocumentFamilyModel | null) => void;
  index: number;
}

const DownloadAreaListItem = (props: Props) => {
  const { i18n } = useTranslation();
  const language = Util.splitLanguage(i18n.language, 0);

  useEffect(() => {
    var setCurrentDownloadListItem = props.setCurrentDownloadListItem;

    return () => {
      setCurrentDownloadListItem(null);
    };
  }, [props.setCurrentDownloadListItem]);

  const toggleActive = (index: number) => {
    if (props.currentDownloadListItem !== props.downloadList[index])
      props.setCurrentDownloadListItem(props.downloadList[index]);
    else props.setCurrentDownloadListItem(null);
  };

  const toggleActiveStyle = (index: number) => {
    if (props.downloadList[index] === props.currentDownloadListItem)
      return "download-area-list-active";
    return "";
  };

  const downloadDocument = () => {
    if (props.downloadItem) {
      Util.downloadFamilyDocument(
        props.downloadItem?.id,
        props.downloadItem?.documentId,
        props.downloadItem?.path.familyPath,
        language
      );
    }
  };

  const getDocument = () => {
    if (props.downloadItem) {
      Util.getFamilyDocument(
        props.downloadItem?.id,
        props.downloadItem?.documentId
      );
    }
  };

  return (
    <Row
      className={`download-area-list ${toggleActiveStyle(props.index)}`}
      onClick={() => toggleActive(props.index)}
    >
      <Col>
        <span className="download-area-list-name">
          {props.downloadItem.name}
        </span>{" "}
        ({props.downloadItem.type.name})
      </Col>
      <Col md="auto" className="align-self-center">
        <FaBinoculars
          className="download-area-list-icon"
          onClick={() => getDocument()}
        />
      </Col>
      <Col md="auto" className="align-self-center">
        <MdCloudDownload
          className="download-area-list-icon"
          onClick={() => downloadDocument()}
        />
      </Col>
      <Col md="auto" className="align-self-center">
        <AiOutlineMinus
          className="download-area-list-icon download-area-list-icon-minus"
          onClick={() => {
            var list = props.downloadList.filter(
              (f) =>
                !(
                  f.id === props.downloadItem?.id &&
                  f.type.id === props.downloadItem?.type.id
                )
            );
            props.setDownloadList(list);

            sessionStorage.setItem("downloadList", JSON.stringify(list));
          }}
        />
      </Col>
    </Row>
  );
};

export default DownloadAreaListItem;
