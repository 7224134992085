import { match } from "react-router";
import { useState, useEffect } from "react";
import { Container, Row, Col, Image, Carousel, Pagination  } from "react-bootstrap"; 
import { useTranslation } from "react-i18next";
import ImageGallery from 'react-image-gallery';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { NameSpace } from "../../data/AppLanguage";
import "react-bootstrap-carousel/dist/react-bootstrap-carousel.css";
import Util from "../../utils/Util";
import { Carousel as ResponsiveCarousel } from 'react-responsive-carousel';
import CaseStudyService from "../../services/api/CaseStudy";

import MainTitle from "../../components/MainTitle/MainTitle";
import BreadcrumbPath from "../../components/BreadcrumbPath/BreadcrumbPath";

import "./CaseStudy.css";
import { MediaType } from "../../enums/MediaType";
import Video from "../../components/Video/Video";
import { CaseStudyModel } from "../../models/CaseStudyModel";

interface Path { 
  path: string;
}

interface Props {
  match: match<Path>;
}

interface HtmlTagsRemoverProps {
  text: string;
}

const CaseStudy = (props: Props) => {
  const [caseStudy, setCaseStudy] = useState<CaseStudyModel>();
  const [activeIndex, setActiveIndex] = useState(0);

  const handleSelect = (selectedIndex: number) => {
    setActiveIndex(selectedIndex);
  };
  const params = props.match.params;

  const { t } = useTranslation([
    NameSpace.COMMON,
    NameSpace.NAVIGATION,
    NameSpace.PAGES,
    NameSpace.ROUTES,
  ]);

  const solutionsPath = t("solutions", { ns: NameSpace.ROUTES });
  const solutionsTitle = t("solutions", { ns: NameSpace.NAVIGATION });

  const caseStudyTitle = t("caseStudy", { ns: NameSpace.ROUTES });
  const caseStudyPath = t("caseStudy", { ns: NameSpace.NAVIGATION });

  function removeHtmlTags({ text }: HtmlTagsRemoverProps) {
    let temp = document.createElement("div");
    temp.innerHTML = text;
    return temp.textContent || temp.innerText || "";
  }
  
  useEffect(() => {
    let mounted = true;

    if (mounted) {
      CaseStudyService.GetCaseStudy(params.path).then((res) => {
        document.title = Util.setDocumentTitle(
          `${solutionsTitle} | ${res.data.subtitle}`
        );
        setCaseStudy(res.data);
      }).catch((error) => {
        // console.log(error);
      });
    }

    return () => {
      mounted = false;
    };
  }, [params.path, solutionsTitle]); 
  

  return (
    <>      
      <BreadcrumbPath
        ancestors={[        
          {
            name: solutionsTitle,
            path: solutionsPath,
          },
          {
            name: caseStudyTitle,
            path: caseStudyPath,
          }
        ]}
        current={caseStudy?.subtitle}
        className="pt-8 bread"
      />
      <article className="artigo">
        <div className="container">
          <div className="event ">
              <div className="pb-4">
                <Col className="event-title titulo">{caseStudy?.subtitle}</Col>
              </div> 
              <div className="pb-5 titulo titulo01">
                <Col>
                  <div>{caseStudy?.section1 && removeHtmlTags({ text: caseStudy?.section1 })}</div>
                </Col>
              </div>   
{/* --------------------------------- REACT CAROUSEL ORGININAL ----------------------------------------------------------- */}
              <Carousel activeIndex={activeIndex} onSelect={handleSelect}>
                {caseStudy?.caseStudyMediaCollection?.map((media, index) => (
                  <Carousel.Item key={index} className="image-carousel">
                    <Container className="container-media">
                      <Image
                        className="image-media"
                        src={media.path}
                        alt={caseStudy?.subtitle}
                      />
                    </Container>
                    
                  </Carousel.Item>
                ))}
              </Carousel>    
              <Pagination className="mt-4 pb-3 pagination">
              {caseStudy?.caseStudyMediaCollection?.map((_, index) => (
                <Pagination.Item
                  key={index}
                  active={index === activeIndex}
                  onClick={() => handleSelect(index)}
                >
                  <Image
                    src={_.path}
                    alt={caseStudy?.subtitle}
                    style={{ width: "6.25rem", height: "6.25rem" }}
                  />
                </Pagination.Item>
              ))}
            </Pagination>
{/*--------------------------------- REACT CAROUSEL ORGININAL ----------------------------------------------------------- */}
            <Row className="event-disclaimer"></Row>
          </div>
        </div>        
      </article>
    </>
  );
};

export default CaseStudy;
