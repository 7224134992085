import { RouteModel } from "../models/RouteModel";

import { languages } from "./AppLanguage";

import Home from "../pages/Home/Home";
import Error from "../pages/Error/Error";
import Group from "../pages/Group/Group";
import Recruitment from "../pages/Recruitment/Recruitment";
import SpontaneousApplication from "../pages/SpontaneousApplication/SpontaneousApplication";
import JobOfferCollection from "../pages/JobOfferCollection/JobOfferCollection";
import JobOffer from "../pages/JobOffer/JobOffer";
import Gallery from "../pages/Gallery/Gallery";
import GalleryItem from "../pages/GalleryItem/GalleryItem";
import Category from "../pages/Category/Category";
import Family from "../pages/Family/Family";
import DownloadArea from "../pages/DownloadArea/DownloadArea";
import Contact from "../pages/Contact/Contact";
import PrivacyPolicy from "../pages/PrivacyPolicy/PrivacyPolicy";
import NewsComponent from "../pages/EventComponent/NewsComponent";
import EventItem from "../components/EventItem/EventItem";
import Events from "../pages/Event/Events";
import SolutionItem from "../components/SolutionItem/SolutionItem";
import Solution from "../components/Solution/Solution";
import SolutionsComponent from "../pages/SolutionsComponent/SolutionsComponent";
import Solutions from "../pages/Solutions/Solutions";
import ImageGalleries from "../components/ImageGalleries/ImageGalleries";
import CaseStudies from "../components/CaseStudies/CaseStudies";
import CaseStudy from "../pages/CaseStudy/CaseStudy";
import Complaint from "../pages/Complaint/Complaint";
import PDFGenerator from "../components/PDFGenerator/PDFGeneratorDataSheet";
import PDFGeneratorFirstPage from "../components/PDFGenerator/PDFGeneratorDataSheet";
import OpportunitiesByGamma from "../pages/Opportunity/OpportunitiesBygamma";
import Opportunities from "../pages/Opportunity/Opportunities";
import Opportunity from "../pages/Opportunity/Opportunity";
import Subfamily from "../pages/Subfamily/Subfamily";



export const localePath = `/:locale(${languages.join("|")})`;
export const defaultPath = "/:path";

export const routes: RouteModel[] = [
  {
    exact: true,
    component: Home,
  },
  {
    exact: true,
    routes: ["error"],
    component: Error,
  },
  {
    exact: true,
    routes: ["aboutUs"],
    component: Group,
  },
  {
    exact: true,
    routes: ["qualityCertification"],
    component: Group,
  },
  {
    exact: true,
    routes: ["recruitment"],
    component: Recruitment,
  },
  {
    exact: true,
    routes: ["recruitment", "jobOffers"],
    component: JobOfferCollection,
  },
  {
    exact: true,
    routes: ["recruitment", "jobOffers"],
    path: defaultPath,
    component: JobOffer,
  },
  {
    exact: true,
    routes: ["recruitment", "spontaneousApplication"],
    component: SpontaneousApplication,
  },
  {
    exact: true,
    routes: ["newsEvents"],
    component: NewsComponent,
  },
  {
    exact: true,
    routes: ["newsEvents"],
    path: defaultPath,
    component: Events,
  },
  {
    exact: true,
    routes: ["solutions"],
    component: SolutionsComponent,
  },
  {
    exact: true,
    routes: ["caseStudy"],
    component: CaseStudies,
  },
  {
    exact: true,
    routes: ["solutions"],
    path: defaultPath,
    component: Solutions,
  },  
  {
    exact: true,
    routes: ["opportunity"],
    component: Opportunities,
  },  
  {
    exact: true,
    routes: ["opportunity"],
    path: defaultPath,
    component: OpportunitiesByGamma,
  }, 
  {
    exact: true,
    routes: ["opportunity", "opportunityitem"],
    path: defaultPath,
    component: Opportunity,
  },
  {
    exact: true,
    routes: ["downloadArea"],
    component: DownloadArea,
  },
  {
    exact: true,
    routes: ["imageGallery"],
    component: ImageGalleries,
  },  
  {
    exact: true,
    routes: ["solutions","imageGallery"],
    component: ImageGalleries,
  },
  {
    exact: true,
    routes: ["caseStudy"],
    path: defaultPath,
    component: CaseStudy,
  },
  {
    exact: true,
    routes: ["contact"],
    component: Contact,
  },  
  {
    exact: true,
    routes: ["complaint"],
    component: Complaint,
  },
  {
    exact: true,
    routes: ["privacyPolicy"],
    component: PrivacyPolicy,
  },
  {
    exact: true,
    routes: ["pdfgenerator"],
    component: PDFGeneratorFirstPage,
  },
  {
    exact: true,
    path: ":gamma/:line?",
    component: Category,
  },
  {
    exact: true,
    path: ":gamma/:line/:family",
    component: Family,
  },
  {
    exact: true,
    path: ":gamma/:line/:family/:subfamily",
    component: Subfamily,
  },
];
